import { createGlobalStyle, keyframes } from 'styled-components';
import { defaultTheme } from '../config';
import themeDefault from './ThemeComponents';


const rotate = keyframes`
from {
  transform: rotate(0deg) ;
}
to{
  transform: rotate(360deg) ;
}
`

const fadeIn = keyframes`
from {
  transform: translateY(20px) ;
  opacity: 0 ;
}
to{
  transform: translateY(0px) ;
  opacity: 1 ;
}
`

export default createGlobalStyle`

@font-face {
    font-family: Montserrat;
    src: url("/assets/fonts/Montserrat-Regular.ttf") format("truetype");
}


@font-face {
    font-family: MontserratBold;
    src: url("/assets/fonts/Montserrat-Bold.ttf") format("truetype");
}



*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: 0
}

html{
  width: 100%;
  min-height: 100vh !important;


}

.whiteModal { 
  p,span,h3, strong, h2, h1, h4, nav, aside {
    color: #333 !important;
  }
}

body {
//background: url('/assets/Fundo.jpg') no-repeat center;
background-size: cover ;
background-attachment: fixed;
background : ${themeDefault.globalBackground};

>iframe {
  position: relative !important;
  display: none ;
}

.fadeIn{
  animation: ${fadeIn} 1s forwards;
}

.p50.socialFooter .iconSet svg{
  
    min-width: 45px !important;
    font-size: 45px !important;
 
}
 
.typeButtonInitial2 {
      display: flex ;
      align-items: center;
      justify-content: center;
      padding: 10px;
      color: ${themeDefault.alternativeButton.color};
      background: ${themeDefault.alternativeButton.background};
      min-width: 100px;
      font-weight: bold;
      border : 0px;
      border-radius: 10px;
      margin: 5px;
      font-size: 12px;

    }


    .selectButtonMini{
      background: #f15a23 ;
      color: #fff;
      transition: 0.5s all;
      border: 0px;
      padding: 5px;
      font-size: 16px;
      border-radius: 5px;

      &:hover{
      background: rgb(0,14,64);
    }
    }

.searchButton{
    background: rgba(0,100,0);
    color: #fff;
    width: 50px;
    height: 50px;
    padding: 10px;
    margin: 5px;
    border: 0px;

    box-shadow: 0px 5px 15px rgba(0,0,0,0.3);
    transition: 0.5s all;
    border-radius: 50%;

    >svg{
      width: 30px;
      font-size: 30px;
      color: #fff;
    }

    &:hover{
      background: rgba(0,150,0);
    }
  }

  .searchButtonMini{
    background: rgba(0,100,0);
    color: #fff;
    width: 30px;
    height: 30px;
    padding: 5px;
    margin: 5px;
    border: 0px;

    box-shadow: 0px 5px 15px rgba(0,0,0,0.3);
    transition: 0.5s all;
    border-radius: 50%;

    >svg{
      width: 20px;
      font-size: 20px;
      color: #fff;
    }

    &:hover{
      background: rgba(0,150,0);
    }
  }
 


  background-position:  top;
  
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;



  color: #fff;
  -webkit-font-smoothing: antialiased;
  padding: 0;
  margin: 0 !important;
  min-height: 100vh !important;
  min-height: 100% !important;
  position: relative;



}

.tooltip{
  background: #777 ;
  color: #333;
}

.spin {
  animation: ${rotate} 2s infinite ;
}

.table{
  width: 100%;
  border-collapse: collapse;
  td{
    border: 1px solid #ddd;
    padding: 10px;
    color: #333;

  }
}


.searchDivStart2{
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items:center;
        max-width: 1600px;
        padding: 0px 15px;
        margin: 0px auto;

        >select, input {
          min-width: 300px;
          padding: 10px;
          border-radius: 15px;
          background: #fff ;
          border: 0px;
          margin:  10px;
        }

        button{
          padding: 10px;
          border-radius: 15px;
          background: #fff ;
          border: 0px;

          >svg{
            font-size: 18px;
          }
        }

        @media (max-width: 750px) {
    justify-content: center;
    flex-direction: column;
    select, input {
      width: 100%;

    }

    div{
      width: 100%;
    }
    
  }
      }

.slick-slider{
  width: 100%;

 
}

.slick-prev{
  background: none !important ;
  width: auto !important ;
  height: auto !important ;
  border-radius: 50%;
  z-index: 1;
  &:before{
    color: #f15a23;
    font-size: 40px;
    z-index:10;
    opacity: 1 ;
  }
}

.slick-next{
  background:  none !important ;
  width: auto !important ;
  height: auto !important ;
  border-radius: 50%;
  z-index: 1;
  &:before{
    color:  #f15a23;
    font-size: 40px;
    opacity: 1 ;
  }
}

.fixedWhatsapp{
  position: fixed;
    bottom: 15px;
    right: 0px;
    border-radius: 10px 0px 0px 10px;
    background: rgb(50,150,26);
    color: #fff !important;
    padding: 15px;
    border: 0px;
}

.buttonSmallActive, .buttonSmallInactive{

align-self: center;
  background-color: #1c9284;
  border-bottom-style: none;
  border-color: #1c9284;
  border-left-style: none;
  border-radius: 5px;
  border-right-style: none;
  border-top-style: none;
  border-width: 0px;
  color: #FFFFFF;
  cursor: pointer;
 
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
 
  justify-content: center;
  line-height: 18px;
  margin-bottom: 0px;
  margin-top: 0px;
  max-width: 388px;
  text-align: center;
  text-decoration: none;
  width: auto;
  padding :  10px;
 
  margin-top: 0px;

}

.row {
  flex-direction: row;
  display: flex ;
  align-items: center;
  justify-content: center;

}

.flex-wrap{
  flex-wrap: wrap;
}

.gap-sm{
  gap: 7.5px;
}

.full-width{
  width: 100%;
}

.buttonSmallInactive{
  background: #ededed ;
  color: #1c9284;
}

.buttonBigGreen{

align-self: center;
  background-color: #1c9284;
  border-bottom-style: none;
  border-color: #1c9284;
  border-left-style: none;
  border-radius: 20px;
  border-right-style: none;
  border-top-style: none;
  border-width: 0px;
  color: #FFFFFF;
  cursor: pointer;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: bold;
  height: 50px;
  justify-content: center;
  line-height: 1.42857;
  margin-bottom: 20px;
  margin-top: 0px;
  max-width: 388px;
  text-align: center;
  text-decoration: none;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  margin-top: 20px;

}

.buttonBigGreenIcon{

align-self: center;
background-color: #1c9284;
border-bottom-style: none;
border-color: #1c9284;
border-left-style: none;
border-radius: 10px;
border-right-style: none;
border-top-style: none;
border-width: 0px;
color: #FFFFFF;
cursor: pointer;
font-family: "Open Sans", sans-serif;
font-size: 18px;
font-style: normal;
font-weight: bold;
height: 50px;
justify-content: center;
line-height: 1.42857;

margin-top: 0px;
max-width: 388px;
text-align: center;
text-decoration: none;
width: 40px;
height: 40px;
padding-right: 10px;
padding-left: 10px;

gap: 10px;
display: flex ;
align-items: center;
justify-content: center;

>svg{
  font-size: 24px;
  min-width: 20px;
  min-height: 20px;
  color: #fff;
}

}


.CaptureLeads{
  min-width: 250px;
  background: rgb(241, 90, 35)  ;
  color: ${themeDefault.textComponent.title.color} ;
  padding: 10px 15px;
  border-radius: 5px;
  border: 0px;

  @media (max-width: 1000px) {
    display: none ;
  }

}

.divSecoundaryBackground {
  background: ${themeDefault?.textComponent?.title.color} !important;
}

.shadow{
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.padding{
  padding: 15px;
}

.margin{
  margin: 15px;
}

.shadowBox{
  display: flex;
    background: rgb(255, 255, 255) !important;
    box-shadow: rgb(7 13 28 / 25%) 0px 10px 100px;
    margin: 20px 0px;
    border-radius: 10px;
    padding: 20px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#root{
  min-height: 100vh;
 


}

body, input, button{
  font-family: Helvetica,  sans-serif;
  font-size: 16px;
}

h1,h2,h3,h4,h5,h6, strong{
font-weight: bold;
font-family:  'MontserratBold',Helvetica,  sans-serif;
color : ${themeDefault.textComponent.title.color};
}

h2{

      font-size: 16px;
}

p, span{
  font-family:'Montserrat',Helvetica,  sans-serif;
  color : ${themeDefault.textComponent.paragraph.color};
}

button{
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  >svg {
    margin: 0 5px;
  }
}

.wave {

  width: 200%;
  margin-left: -20%;
  z-index:0;

  @media (max-width: 1250px) {
    width: 400%;
    margin-left: -50%;
  }

  @media (max-width: 750px) {
    width: 800%;
    margin-left: -50%;
  }



}


.computerOnly{
display: flex !important;
@media (max-width: 1200px) {
    display: none !important;
  }
}

.mobileOnly{
display: none !important;
@media (max-width: 1200px) {
    display: flex !important;
  }
}

.fixedBottom{
    z-index: 0;
    position: fixed;
    bottom: -30px;
    right: 0px;
    width: 45%;
}

div.defaultButton {
         margin-top: 10px;
      width: 200px;
      min-width: 200px;
      max-width: 250px;
      padding: 20px;
      text-align: center;
      background: #fff !important;
      border: 2px solid rgb(0, 15, 64);
      color: rgb(0, 15, 64)  !important;
font-weight: bold;
      display: flex;
      justify-content: center;
      text-align: center;

      transition:  0.5s all;

      border-radius: 10px;
      box-shadow: 0px 5px 15px rgba(255,255,255,0.3);

      svg{
        cursor: pointer;
        transition: 0.4s all ;
        &:hover{
          transform: scale(1.1) ;
        }
      }
   
    }

button.defaultButton {
         margin-top: 10px;
      width: 200px;
      min-width: 200px;
      max-width: 250px;
      padding: 20px;
      text-align: center;
      background: #fff ;
      border: 2px solid rgb(0, 15, 64);
      color: rgb(0, 15, 64) ;
font-weight: bold;
      display: flex;
      justify-content: center;
      text-align: center;

      transition:  0.5s all;

      border-radius: 10px;
      box-shadow: 0px 5px 15px rgba(255,255,255,0.3);
      &:hover {
        transform: translateY(-5px);
        box-shadow: 0px 5px 5px rgba(171, 230, 255,0.7);
       
      }
    }


button.defaultButtonRed {
         margin-top: 10px;
      width: 200px;
      min-width: 200px;
      padding: 20px;
      text-align: center;
      background: red !important;
      color: #fff  !important;

      display: flex;
      justify-content: center;
      text-align: center;

      transition:  0.5s all;
      border: 0;
      border-radius: 10px;

      &:hover {
        transform: translateY(-10px);
        box-shadow: 0px 5px 5px rgba(255,255,255,0.3);
      }
    }

    .defaultButtonReverse {
         margin-top: 10px;
      width: 200px;
      min-width: 200px;
      padding: 20px;
      text-align: center;
      flex-direction: column;
      display: flex ;
      justify-content: center;
      align-items: center;
 
      color: rgb(0, 15, 64)  !important;
      font-weight: bold;

      background: rgb(171, 230, 255) !important;

      display: flex;
      justify-content: center;
      text-align: center;

      transition:  0.5s all;
      border: 0;
      border-radius: 10px;
    
      &:hover {
        transform: translateY(-10px);
        box-shadow: 0px 5px 5px rgba(255,255,255,0.3);
      }
    }

    .tip{
      padding: 5px;
     font-size: 14px;
      width:100%;
      text-align: left;
      font-weight: bold;
   
      color: #916f00 !important;
      display: flex ;
      justify-content: flex-start;
      align-items: center;

      >svg {
        animation: ${rotate} 5s infinite ;
      }
     
  
    }

    .rotateAnimation{
      animation: ${rotate} 5s infinite ;
    }

    .simpleButton {
         margin-top: 0px;
 
  
      padding: 5px;
      text-align: center;
      background: #fff !important;
      color: #333  !important;
      font-weight: bold;

      display: flex;
      justify-content: center;
      text-align: center;

      >a{
        color: #333  !important;
        font-weight: bold;

      }

      transition:  0.5s all;
      border: 0;
      border-radius: 10px;

      &:hover {
        transform: scale(1.1);
        box-shadow: 0px 5px 5px rgba(255,255,255,0.3);
      }
    }


    .defaultButtonReverseMini {
         margin-top: 0px;
      width: 100%;
      max-width: 200px;
      padding: 5px 10px;
      text-align: center;
      background: #f16621 !important;
      color: #fff  !important;
      font-weight: bold;

      display: flex;
      justify-content: center;
      text-align: center;

      >a{
        color: #fff  !important;
        font-weight: bold;

      }

      transition:  0.5s all;
      border: 0;
      border-radius: 10px;

      &:hover {
        transform: translateY(-10px);
        box-shadow: 0px 5px 5px rgba(255,255,255,0.3);
      }
    }

    button.defaultButtonMini,     button.defaultButtonMini2, button.buttonBlue {
         margin-top: 0px !important;
         padding: 5px !important;
      width:auto !important;
      min-width:auto !important;

      text-align: center !important;
      justify-content: center !important;
      background: rgb(0,15,64) !important;
      color: ${defaultTheme.defaultColor}  !important;

      display: flex  !important;
      justify-content: center !important;


      transition: background 0.5s;
      border: 0;
      border-radius: 10px;

      &:hover {
        background: ${defaultTheme.defaultBackground}  !important;
      }
    }


    button.defaultButtonMini2{
      padding: 10px 20px  !important ;
      margin-top: 25px ;
    }

    button.buttonBlue{
      background: rgb(0,100,150) !important;

      &:hover {
        background: rgb(0,120,180) !important;
      }
    }


    .closeButton{
      padding: 10px;
      border: 0;
      opacity: 0.8;
      cursor: pointer;
      background: #f15a23 !important;
      color: #fff  !important;
      border-radius: 10px  !important;
      transition: 0.5s all;

      margin: 5px;
      &:hover{
        opacity: 1;
      }
    }


    a {
      text-decoration: none !important;
    }


    .lineIcon {
      margin:5px;
      background: #fff;
       border: 1px solid #333;
       border-radius: 50%;
       height: 35px;
       width: 35px;
       padding: 5px;
       
       >svg{
         width: 25px !important;
         font-size: 25px;

       }
       display: flex;
       align-items: center;
       justify-content: center;
    }

    .videoContainer{
      width: 100%;
      position: relative;
      height: auto;
   
    }

form {
    >div{
  
  background: #fff !important;
  color: #333  !important;
  border: 1px solid #ddd  !important;
  box-shadow: 0px 0px 5px rgba(0,0,0,0.2)  !important;

  span{
    color: #333  !important;
  }

  input {
    color: #333  !important;

    &::placeholder {
    color: #333  !important;
  }
  }
}
}
`;
