import React, { useEffect, useState } from 'react';

import { IconType } from 'react-icons/lib';

import { Container, ContainerWhite } from './styles';

import Vimeo from '../../components/Vimeo';
import VideoGallery from '../../components/VideoGallery';
import ImageGallery from '../../components/ImageGallery';
import Speakers from '../Speakers';
import api from '../../services/api';
import { useParams } from 'react-router-dom';
import { hash, urlWebsite } from '../../config';
import Axios from 'axios';

interface SignInData {
  email: string;
  password: string;
}

interface IPublicData {
  icon: IconType;
  title: string;
  text: string;
}

const EventsPages: React.FC = () => {

  const { projectHash, url } = useParams<Record<string, any>>();
  const [ready, setReady] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pageContent, setPageContent] = useState<Record<string, any>>({});

  const load = async () => {
    if (url) {
      setLoading(true);

      const response = await Axios.get(`https://api-global-v3.encontrodigital.com.br/${hash}/show-page/${projectHash}/${url}`);

      if (response) {
        setPageContent(response.data);
        setReady(true);
      }
      else {
        setReady(true);
      }
      setLoading(false);
    }
  }

  useEffect(() => {

    load();

  }, [url, projectHash])

  return (
    <>

      <Container >
        {pageContent?.image ? <img style={{ width: '100%', maxWidth: '400px' }} src={`${urlWebsite}/${pageContent?.image}`} /> : <> </>}
        <div className='content'>
          <h2>{pageContent?.title}</h2>
          <p dangerouslySetInnerHTML={{ __html: pageContent?.description || '' }} />

          {pageContent?.files && pageContent?.files.map((item) => {
            return <a href={`${urlWebsite}/${item.url}`} target="_blank"><button className='defaultButton'>{item.title}</button></a>
          })}

          {pageContent?.buttons && pageContent?.buttons.map((itemButton) => {
            return <a href={`${itemButton.link}`} ><button className={itemButton.class_name || 'defaultButton'}>{itemButton.title}</button></a>
          })}
        </div>
      </Container>
    </>
  );
};
export default EventsPages;
