import React from 'react';

import {
  SearchComponent,
  ISearchComponent,
} from '../../core/components/SearchComponent';

import lineModules from '../../core/components/lineModules'
import { Container } from './styles';
import CopyToClipboard from '../../../../components/CopyToClipboard';
import { urlWebsite } from '../../../../config';
import { FaLink } from 'react-icons/fa';
import { FiCopy } from 'react-icons/fi';
import { useParams } from 'react-router-dom';

interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}

interface ILine {
  [key: string]: string;
}

const Dashboard: React.FC = () => {
  const endpoint = '/speakers_term';
  const title = 'Gerenciador de termos';
  const { projectHash, eventHash } = useParams<Record<string, any>>();
  const schema: Record<string, any> = {
    _id: { ref: '_id', column: '_id', label: 'ID', show: false },
    project_id: { ref: 'project_id', column: 'project_id', label: 'Projeto', show: false },
    hash: { ref: 'hash', column: 'hash', label: 'Hash' },
    title: { ref: 'title', column: 'title', label: 'Título' },
    header_text: { ref: 'header_text', column: 'header_text', label: 'Cabeçalho', show: false },
    language: { ref: 'language', column: 'language', label: 'Idioma' },
    term: { ref: 'term', column: 'term', label: 'Termo', show: false },

    createdAt: {
      ref: 'createdAt',
      column: 'createdAt',
      label: 'Data de Criação',
      mask: 'date',
    },
  };

  const formSchema = {
    title: { model: 'input', type: 'text', name: 'title', label: 'Título' },
    header_text: { model: 'input', type: 'text', name: 'header_text', label: 'Cabeçalho' },
    term: { model: 'richText', type: 'text', name: 'term', label: 'Termo' },
    language: {
      model: 'selectSimple', defaultValue: 'ptBr', type: 'text', name: 'language', label: 'Língua', options: [
        { label: 'Português', value: 'ptBr' },
        { label: 'Inglês', value: 'en' },
        { label: 'Espanhol', value: 'es' },
      ]
    },
  };

  const formSchemaUpdate = {

    ...formSchema
  };

  const formValidation: Record<string, ValidationSchema> = {
    title: {
      name: 'title',
      type: 'string',
      label: 'Título',
      rules: [{ type: 'required' }],
    },
  };



  const config: ISearchComponent = {
    idKey: `${projectHash}-${eventHash}-endpoint`,
    title,
    endpoint,
    schema,
    autoStart: true,
    headModules: [

      () =>
        lineModules.create({
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
    bodyModules: [
      (lineData: Record<string, any>) =>
        lineModules.updateUnique({
          lineData,
          title,
          formSchema: formSchemaUpdate,
          validationSchema: {},
          endpoint,
        }),
      (lineData: Record<string, any>) =>
        lineModules.delete({
          lineData,
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
      (lineData: Record<string, any>) => {
        return <CopyToClipboard Icon={FiCopy} text={`${urlWebsite}/app/${lineData?.project_id}/accept_terms/${lineData?.hash}`} />


      },
    ],
  };

  return (
    <Container>
      <SearchComponent {...config} />
    </Container>
  );
};

export default Dashboard;
