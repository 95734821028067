import React, { useCallback, useEffect, useRef, useState } from 'react';

import api from '../../services/api';

import ImageSlider from '../../components/ImageSlider';
import SliderComponent from '../../components/SliderContainer';
import { hash, urlWebsite } from '../../config';
import { loadFromJson } from '../../utils/loadFromJson';


interface ISlide {
    url: string;
    link_url: string;
}


const HomeSlider: React.FC = () => {


    const [sliders, setSliders] = useState<Array<JSX.Element>>([]);

    const load = async () => {


        let data: Array<ISlide> = [];

        const jsonData = await loadFromJson({ hash: hash, project_id: '4-simposio-obesidade', table: 'LessonPoster' });

        if (jsonData) {
            data = jsonData?.[0]?.posters || [];
        }
        else {
            const response = await api.post(`/lesson-slides/list/4-simposio-obesidade`, { reference: 'global' });
            data = response.data;
        }

        if (data) {

            const elements: Array<JSX.Element> = [];


            data.map(slide => { elements.push(<a href={slide.link_url} target={'_BLANK'}><img style={{ width: '100%' }} src={`${urlWebsite}/${slide?.url}`} /></a>); });

            setSliders(elements)

        }

    }

    useEffect(() => {
        load()
    }, [])

    return (
        <SliderComponent slidesToShow={1} slidesToScroll={1} arrows={false} autoplay={true}>{sliders}</SliderComponent>

    );
};

export default HomeSlider;
