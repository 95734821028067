import React, { useEffect, useState } from 'react';
import { useLanguage } from '../../hooks/Language';

import { Container } from './styles';

const Logo: React.FC = () => {

  const {language} = useLanguage();
  const [logoValue,setLogoValue] = useState("/assets/logodasa.png");
  

  useEffect(() => {

    const validLogo = {
      ptBr : "/assets/logodasa.png",
      en : "/assets/logodasa.png",
      es : "/assets/logodasa.png",
    }

   
    setLogoValue( validLogo?.[language] ? validLogo[language] :logoValue );

  },[language])

  return (
    <Container>
      <img src={logoValue} />
    </Container>
  );
};
export default Logo;
