import React from 'react';
import { FaChalkboardTeacher, FaChartBar, FaFileExcel } from 'react-icons/fa';
import CopyToClipboard from '../../../core/components/CopyToClipboard';
import { apiUrl, getToken, urlWebsite } from '../../../../../config';
import userEvent from '@testing-library/user-event';
import { useAuth } from '../../../../../hooks/Auth';
import downloadit from '../../../../../utils/download';
import { useLoading } from '../../../../../hooks/LoadingHook';

interface ICreateModule {
  lineData: Record<string, any>;
}


export default function ListParticipants({
  lineData,
}: ICreateModule): JSX.Element {

  const { user } = useAuth();
  const { addLoading, removeLoading } = useLoading();

  const download = async (data) => {
    addLoading({ title: 'Gerando relatório', key: 'loading' });
    await downloadit({ url: `${apiUrl}/report-lesson/${data?.project_id}/${data._id}?type=xlsx` });
    removeLoading('loading');
  }

  const moduleList = ({ data }): JSX.Element => {

    return <> {user && user.profile === 1 ? <button className='lineIcon'><FaChartBar onClick={() => download(data)} color="#333" size={30} title="Exportar Lista de participantes" /></button> : <></>}
    </>;
  }



  return moduleList({ data: lineData });
}
