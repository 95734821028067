import React, { useCallback, useRef, useEffect, useState } from 'react';

import { IconType } from 'react-icons/lib';
import { Link } from 'react-router-dom';
import { FiChevronRight, FiChevronLeft } from 'react-icons/fi';
import api from '../../../../services/api';
import { date, hour, dateLanguage } from '../../../../utils/date';
import { Container, Sidebar, Body, Header } from './styles';
import { addHours, getDate, getMonth, getYear } from 'date-fns';
import { useToast } from '../../../../hooks/Toast';
import SelectApi from '../../core/components/Forms/SelectApi';
import { Form } from '@unform/web';
import DatePicker from '../../core/components/Forms/DatePicker';
import Button from '../../core/components/Forms/Button';
import SelectSimple from '../../core/components/Forms/SelectSimple';
import ChartBar from '../../core/components/Charts/Bar';
import { uuid } from 'uuidv4';
import { FaFileExcel, FaSpinner } from 'react-icons/fa';
import { downloaditPost } from '../../../../utils/download';
import { apiUrl } from '../../../../config';
import CientificLibraryListCategories from './modules/ListCategories';

interface IOptions {
  text?: string;
  label?: string;
  value: string;
}

interface IChartData {
  hora: string;
  usuarios: number;
}

const ReporterPodcastLibrary: React.FC = () => {

  const [projectsList, setProjectsList] = useState<Array<Record<string, any>>>([]);
  const [eventsList, setEventsList] = useState<Array<Record<string, any>>>([]);
  const [reportType, setReportType] = useState('report');
  const [listItems, setListItems] = useState<Array<Record<string, any>>>([]);

  const [records, setRecords] = useState<Array<Record<string, any>>>([]);
  const [lessons, setLessons] = useState<Array<IOptions>>([]);
  const [projects, setProjects] = useState<Array<IOptions>>([]);
  const [currentProject, setCurrentProject] = useState('');

  const [type, setType] = useState('global');
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState<Array<IOptions>>([]);
  const [graphData, setGraphData] = useState<Array<IChartData>>([]);
  const [responsive, setResponsive] = useState('Sim');
  const [typeValue, setTypeValue] = useState('month');

  const [currentMonth, setCurrentMonth] = useState(getMonth(new Date()) + 1);
  const [currentYear, setCurrentYear] = useState(getYear(new Date()));


  const [status, setStatus] = useState({
    project_id: '',
    date: new Date().toISOString(),
    selection: 'global',
    visualization: 'list',
    type: 'month',
    day: getDate(new Date()),
    month: getMonth(new Date()) + 1,
    year: getYear(new Date()),
    responsive: 'Sim'
  });

  const callReport = async (data) => {


    setLoading(true)

    const response = await api.post('/reporter-list-podcast-library', { ...data });

    if (response) {
      //  setGraphData(response.data);
      setRecords(response.data);

    }
    setLoading(false)

  }





  const loadProjects = async () => {

    const response = await api.get('/projects?limitItems=1000');
    if (response) {

      const data: Array<IOptions> = [{ label: 'Todos', text: 'Todos', value: 'a' }];
      response.data.rows.map(m => {
        data.push({ label: m.title, text: m.title, value: m.url });
      })

      setProjects(data);
      setProjectsList(response.data.rows)

    }


  }

  const loadEventsList = async () => {

    const response = await api.get('/events', {
      params: {

        limitItems: 1000
      }

    });
    if (response) {



      setEventsList(response.data.rows);

    }


  }

  const loadEvents = async () => {

    const response = await api.get('/events', {
      params: {
        where: { project_id: currentProject },
        limitItems: 100
      }

    });
    if (response) {

      const data: Array<IOptions> = [{ label: 'Todos', text: 'Todos', value: 'a' }];
      response.data.rows.map(m => {
        data.push({ label: m.title, text: m.title, value: m.url });
      })

      setEvents(data);

    }
  }


  useEffect(() => {
    callReport({
      month: currentMonth,
      year: currentYear,
      type
    });
  }, [currentMonth, currentYear, type])

  useEffect(() => {


    loadProjects();
    loadEventsList();
    setTimeout(() => {
      callReport({
        month: currentMonth,
        year: currentYear,
        type
      });
    }, 3000)


  }, [])


  const months = [{ label: '', value: '' },
  { label: 'Todos', value: 13 },
  { label: 'Janeiro', value: 1 },
  { label: 'Fevereiro', value: 2 },
  { label: 'Março', value: 3 },
  { label: 'Abril', value: 4 },
  { label: 'Maio', value: 5 },
  { label: 'Junho', value: 6 },
  { label: 'Julho', value: 7 },
  { label: 'Agosto', value: 8 },
  { label: 'Setembro', value: 9 },
  { label: 'Outubro', value: 10 },
  { label: 'Novembro', value: 11 },
  { label: 'Dezembro', value: 12 }
  ];

  const downloadData = (render) => {

    const elements: Array<Array<string>> = []

    if (type !== 'global' && type !== 'users') {
      if (currentMonth === 13) {
        elements.push(['ARTIGO', '1º Semestre', '2º Semestre', 'Ano'])
      }
      else {
        elements.push(['ARTIGO', 'QTD'])
      }

    }

    if (type === 'users') {
      if (currentMonth === 13) {
        elements.push(['Nome', 'E-mail', 'Telefone', 'Empresa', 'Categoria', 'Especialidade', 'CRM', 'Estado do CRM', '1º Semestre', '2º Semestre', 'ano']);
      }
      else {
        elements.push(['Nome', 'E-mail', 'Telefone', 'Empresa', 'Categoria', 'Especialidade', 'CRM', 'Estado do CRM', 'QTD']);
      }
    }

    render.map(elementData => {


      if (type === 'users') {
        if (currentMonth === 13) {
          elements.push([elementData?.name, elementData?.email, elementData?.phone, elementData?.company, elementData?.position, elementData?.category_id, elementData?.crm, elementData?.crm_uf, elementData?.visits_1st_semester, elementData?.visits_2nd_semester, elementData?.quantity]);


        }
        else {
          if (elementData.name) {
            elements.push([elementData?.name, elementData?.email, elementData?.phone, elementData?.company, elementData?.position, elementData?.category_id, elementData?.crm, elementData?.crm_uf, elementData?.quantity]);
          }
        }
      }
      else {
        if (currentMonth === 13) {
          elements.push([`${elementData?.title}${elementData?.nested_keywords ? ` - Categoria(s): ${elementData?.nested_keywords}` : ''}`, elementData?.visits_1st_semester, elementData?.visits_2nd_semester, elementData?.quantity]);

        }
        else {
          elements.push([`${elementData?.title}${elementData?.nested_keywords ? ` - Categoria(s): ${elementData?.nested_keywords}` : ''}`, elementData?.quantity]);
        }

      }
    })

    return downloaditPost({ url: `${apiUrl}/converter/xlsx`, data: elements, type: 'xlsx' });

  }

  const renderList = (recordsValue) => {

    const render = recordsValue ? [...recordsValue] : [];



    const elements: Array<JSX.Element> = [];

    if (type !== 'global' && type !== 'users') {
      if (currentMonth === 13) {
        elements.push(<tr><td><strong style={{ color: '#333' }}>Artigo</strong></td>
          <td><strong style={{ color: '#333' }}>1º Semestre</strong> </td>
          <td><strong style={{ color: '#333' }}>2º Semestre</strong> </td>
          <td><strong style={{ color: '#333' }}>Ano</strong> </td>  </tr>)

      }
      else {
        elements.push(<tr><td><strong style={{ color: '#333' }}>Artigo</strong></td><td><strong style={{ color: '#333' }}>QTD</strong> </td>    </tr>)
      }
    }

    if (type === 'users') {
      if (currentMonth === 13) {
        elements.push(<tr><td><strong style={{ color: '#333' }}>Nome</strong></td>
          <td><strong style={{ color: '#333' }}>E-mail</strong> </td>
          <td><strong style={{ color: '#333' }}>Telefone</strong> </td>
          <td><strong style={{ color: '#333' }}>Empresa</strong> </td>
          <td><strong style={{ color: '#333' }}>Categoria</strong> </td>
          <td><strong style={{ color: '#333' }}>Especialidade</strong> </td>
          <td><strong style={{ color: '#333' }}>CRM</strong> </td>
          <td><strong style={{ color: '#333' }}>Estado do CRM</strong> </td>
          <td><strong style={{ color: '#333' }}>1º Semestre</strong> </td>
          <td><strong style={{ color: '#333' }}>2º Semestre</strong> </td>
          <td><strong style={{ color: '#333' }}>Ano</strong> </td>
        </tr>)
      }
      else {
        elements.push(<tr><td><strong style={{ color: '#333' }}>Nome</strong></td>
          <td><strong style={{ color: '#333' }}>E-mail</strong> </td>
          <td><strong style={{ color: '#333' }}>Telefone</strong> </td>
          <td><strong style={{ color: '#333' }}>Empresa</strong> </td>
          <td><strong style={{ color: '#333' }}>Categoria</strong> </td>
          <td><strong style={{ color: '#333' }}>Especialidade</strong> </td>
          <td><strong style={{ color: '#333' }}> CRM</strong> </td>
          <td><strong style={{ color: '#333' }}>Estado do CRM</strong> </td>
          <td><strong style={{ color: '#333' }}>QTD</strong> </td>
        </tr>)
      }


    }

    const companyData: Record<string, any> = {};
    const crmData: Record<string, any> = {};
    const categoryData: Record<string, any> = {};
    const medicalPositionData: Record<string, any> = {};
    const otherPositionData: Record<string, any> = {};

    render.map(elementData => {


      if (type === 'users') {
        if (elementData.name) {


          if (!elementData?.company) { elementData.company = 'Não informado' }
          if (!elementData?.category_id) { elementData.category_id = 'Não informado' }

          companyData[elementData?.company] = companyData?.[elementData?.company] ? companyData?.[elementData?.company] + 1 : 1;
          medicalPositionData[elementData?.position] = medicalPositionData?.[elementData?.position] ? medicalPositionData?.[elementData?.position] + 1 : 1;

          if (elementData?.position === 'Médico') {
            categoryData[elementData?.category_id] = categoryData?.[elementData?.category_id] ? categoryData?.[elementData?.category_id] + 1 : 1;

            crmData[elementData?.crm_uf] = crmData?.[elementData?.crm_uf] ? crmData?.[elementData?.crm_uf] + 1 : 1;

          }

          if (currentMonth === 13) {
            elements.push(<tr><td>{elementData?.name}</td><td>{elementData?.email}</td><td>{elementData?.phone}</td><td>{elementData?.company}</td><td>{elementData?.position}</td><td>{elementData?.category_id}</td><td>{elementData?.crm}</td><td>{elementData?.crm_uf}</td><td>{elementData?.visits_1st_semester}</td><td>{elementData?.visits_2nd_semester}</td><td>{elementData?.quantity}</td> </tr>)

          }
          else {
            elements.push(<tr><td>{elementData?.name}</td><td>{elementData?.email}</td><td>{elementData?.phone}</td><td>{elementData?.company}</td><td>{elementData?.position}</td><td>{elementData?.category_id}</td><td>{elementData?.crm}</td><td>{elementData?.crm_uf}</td><td>{elementData?.quantity}</td> </tr>)

          }
        }
      }
      else {
        if (currentMonth === 13) {
          elements.push(<tr><td>{elementData?.title}{elementData?.nested_keywords ? ` - Categoria(s): ${elementData?.nested_keywords}` : ''}</td>
            <td>{elementData?.visits_1st_semester}</td>
            <td>{elementData?.visits_2nd_semester}</td>
            <td>{elementData?.quantity}</td>

          </tr>)
        }
        else {
          elements.push(<tr><td>{elementData?.title}{elementData?.nested_keywords ? ` - Categoria(s): ${elementData?.nested_keywords}` : ''}</td><td>{elementData?.quantity}</td> </tr>)
        }

      }
    })


    if (type === 'users') {


      elements.push(<tr><td>Empresa</td><td>QTD</td></tr>)

      Object?.keys(companyData).map(key => {
        elements.push(<tr><td>{key}</td><td>{companyData[key]}</td></tr>)


      })

      elements.push(<tr><td>Categoria</td><td>QTD</td></tr>)

      Object?.keys(medicalPositionData).map(key => {
        elements.push(<tr><td>{key}</td><td>{medicalPositionData[key]}</td></tr>)


      })

      elements.push(<tr><td>Especialidade Médica</td><td>QTD</td></tr>)

      Object?.keys(categoryData).map(key => {
        elements.push(<tr><td>{key}</td><td>{categoryData[key]}</td></tr>)


      })

      elements.push(<tr><td>CRM médico</td><td>QTD</td></tr>)

      Object?.keys(crmData).map(key => {
        elements.push(<tr><td>{key}</td><td>{crmData[key]}</td></tr>)
      })
    }


    return elements;




  }


  return (<>
    <Header><button onClick={() => setReportType('report')} className={reportType === 'report' ? 'defaultButton' : 'defaultButtonReverse'}>Relatórios</button>
      <button onClick={() => setReportType('category')} className={reportType === 'category' ? 'defaultButton' : 'defaultButtonReverse'}>Categorias</button>
    </Header>
    <Container>

      {reportType === 'report' ? <><Sidebar>


        <Form key={uuid()} onSubmit={() => { }} initialData={{ type: type, month: currentMonth, year: currentYear }}>
          <h2 style={{ color: "#333" }}>Relatórios</h2>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap', padding: '15px' }}>


            <div style={{ width: '100%', maxWidth: '300px' }}><label>Mês</label><SelectSimple setCallback={(value) => setCurrentMonth(value)} label="" options={[
              { label: 'Todos', value: 13 },
              { label: 'Janeiro', value: 1 },
              { label: 'Fevereiro', value: 2 },
              { label: 'Março', value: 3 },
              { label: 'Abril', value: 4 },
              { label: 'Maio', value: 5 },
              { label: 'Junho', value: 6 },
              { label: 'Julho', value: 7 },
              { label: 'Agosto', value: 8 },
              { label: 'Setembro', value: 9 },
              { label: 'Outubro', value: 10 },
              { label: 'Novembro', value: 11 },
              { label: 'Dezembro', value: 12 },

            ]} name="month" /></div>


            <div style={{ width: '100%', maxWidth: '300px' }}><label>Ano</label><SelectSimple setCallback={(value) => setCurrentYear(value)} label="" options={[
              { label: '2022', value: '2022' },
              { label: '2023', value: '2023' },
              { label: '2024', value: '2024' },
              { label: '2025', value: '2025' },


            ]} name="year" /></div>

            <div style={{ width: '100%', maxWidth: '300px' }}><label>Tipo</label><SelectSimple setCallback={(value) => setType(value)} label="" options={[

              { label: 'Global', value: 'global' },


              { label: 'Visitantes em artigos', value: 'access' },
              { label: 'Downloads', value: 'downloads' },
              { label: 'Compartilhamentos', value: 'share' },
              { label: 'Links', value: 'links' },
              { label: 'Rank de usuários', value: 'users' },
            ]} name="type" /></div>





          </div>
        </Form>
      </Sidebar>

        {/* 
        Quando abrir, eu quero ver uma lista dos CSATs no mês, Global
    
          */}

        <Body size={graphData.length} responsive={responsive}>
          {loading ? <div style={{ width: '100%', minHeight: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><h2 style={{ color: '#333', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><FaSpinner style={{ marginRight: '10px' }} className='spin' size={30} />{` `} Carregando</h2></div> :
            <>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '10px' }} ><h2 style={{ color: '#333', padding: '15px 10px' }}>{`${currentYear} - ${currentMonth === 13 ? 'Todos os meses' : months?.[currentMonth + 1]?.label}`}</h2> <button className='searchButtonMini' onClick={() => downloadData(records)}><FaFileExcel /></button></div>
              <table className='table'>
                <tbody>

                  {renderList(records)}
                </tbody>
              </table>
            </>
          }


          {
            //graphData && <div style={{ width: '100%', height: '50vh' }}><ChartBar indexBy="hora" headers={['usuarios']} data={graphData} /></div>
          }

        </Body> </> : <></>}
      {reportType === 'category' ? <CientificLibraryListCategories /> : <></>}

    </Container></>
  );
};
export default ReporterPodcastLibrary;
