import React from 'react';
import FileGallery from '../DashboardPages/features/FileGallery';



const FileGallerys: React.FC = () => {


  return (
    <>

      <FileGallery />

    </>
  );
};
export default FileGallerys;
