import React from 'react';

import uuid from '../../core/components/UniqueHash';
import {
  SearchComponent,
  ISearchComponent,
} from '../../core/components/SearchComponent';

import lineModules from '../../core/components/lineModules'
import { Container } from './styles';
import { useParams } from 'react-router-dom';
import { FaDownload } from 'react-icons/fa';
import downloadit from '../../../../utils/download';
import upgradeWithAI from './modules/BetterTranscription';

import transcriptionToAudioAI from './modules/TranscriptionToAudio';

interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}

interface ILine {
  [key: string]: string;
}
interface IParams {
  projectHash: string;
  eventHash: string;
}



const CientificLibrary: React.FC = () => {
  const endpoint = '/cientific-library';
  const title = 'Trabalhos da Biblioteca';
  const { projectHash, eventHash } = useParams<IParams>(); const schema: Record<string, any> = {
    _id: { ref: '_id', column: '_id', label: 'ID', show: false },

    category: { ref: 'category', column: 'category', label: 'Categoria' },
    status: { ref: 'status', column: 'status', label: 'Status' },
    title: { ref: 'title', column: 'title', label: 'Título' },

    url: { ref: 'url', column: 'url', label: 'URL' },
    time_to_read: { ref: 'time_to_read', column: 'time_to_read', label: 'Tempo de leitura' },
    characters: { ref: 'characters', column: 'characters', label: 'Caracteres' },
    star: { ref: 'star', column: 'star', label: 'Estrelas' },
    smile: { ref: 'smile', column: 'smile', label: 'Sorrisos' },
    like: { ref: 'like', column: 'like', label: 'Likes' },
    love: { ref: 'love', column: 'love', label: 'Corações' },
    applause: { ref: 'applause', column: 'applause', label: 'Aplausos' },
    spark: { ref: 'spark', column: 'spark', label: 'Olhos com estrelas' },
    nested_authors: { ref: 'nested_authors', column: 'nested_authors', label: 'Autores' },
    nested_keywords: { ref: 'nested_keywords', column: 'nested_keywords', label: 'Palavras-chave' },
    createdAt: {
      ref: 'createdAt',
      column: 'createdAt',
      label: 'Data de Criação',
      mask: 'date',
    },
  };

  const formSchema = {
    url: { model: 'input', type: 'text', name: 'url', label: 'Url' },
    title: { model: 'input', type: 'text', name: 'title', label: 'Título' },
    keywords: {
      model: 'ArrayInputIncluder',
      name: 'keywords',
      label: 'Categorias',
      defaultValue: '',
    },
    under_keywords: {
      model: 'ArrayInputIncluder',
      name: 'under_keywords',
      label: 'Palavras-chave:',
      defaultValue: '',
    },

    status: {
      model: 'selectSimple',
      name: 'status',
      label: 'Ativo?',

      options: [
        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },


      ]

    },

    extra_links: {
      model: 'jsonListMultiple',
      type: 'text',
      name: 'extra_links',
      label: 'Links Avulsos',
      list: {
        url: { label: 'Link', name: 'url' },
        title: { label: 'Título', name: 'title' },
        page: { label: 'page', name: 'page' },
        position_x: { label: 'X', name: 'position_x' },
        position_y: { label: 'Y', name: 'position_y' },
        width: { label: 'Larg.', name: 'width' },
        height: { label: 'Alt.', name: 'height' },
      },
    },

    transcription: { model: 'textareaInput', type: 'text', name: 'transcription', label: 'Transcrição' },

  };






  const formSchemaUpdate = {
    ...formSchema,
    image: {
      model: 'uploadImage',
      type: 'upload',
      name: 'image',
      label: 'Capa',
    },
    pdf: {
      model: 'uploadImage',
      type: 'upload',
      name: 'pdf',
      label: 'PDF',
    },
    pdf_images: {
      model: 'imageGalleryInput',
      type: 'input',
      name: 'pdf_images',
      label: 'PDF em JPG',
      list: [
        { label: 'Imagem', name: 'url' },
        { label: 'Título', name: 'title' },
        { label: 'Descritivo', name: 'description' },

      ],
    },
    download_files: {
      model: 'imageGalleryInput',
      type: 'input',
      name: 'download_files',
      label: 'Materiais de apoio',
      list: [
        { label: 'Arquivo', name: 'url' },
        { label: 'Título', name: 'title' },
        { label: 'Descritivo', name: 'description' },

      ],
    },

    sponsors: {
      model: 'imageGalleryInput',
      type: 'input',
      name: 'sponsors',
      label: 'Patrocinadores',
      list: [
        { label: 'Imagem', name: 'url' },
        { label: 'Título', name: 'title' },
        { label: 'Categoria', name: 'category' },
        { label: 'Posição', name: 'position' },
        { label: 'Tamanho Computador', name: 'width' },
        { label: 'Tamanho Mobile', name: 'width_mobile' },
      ],
    },
    authors: {
      model: 'JsonInput',
      bodyRender: (data: Record<string, any>) => (
        <nav>
          <strong>
            (
            {data._id})
            {' '}
            {data.title}
          </strong>
        </nav>
      ),
      searchRender: (data: Record<string, any>) => (
        <>
          <strong>
            (
            {data._id})
            {' '}
            {data.title}
          </strong>
        </>
      ),
      storageType: 'data',
      endpoint: `/cientific-library-author`,
      name: 'authors',
      label: 'Authores',
    },

  };


  const formValidation: Record<string, ValidationSchema> = {

  };

  const getReport = () => {


    const takeIt = () => {
      downloadit({ url: '/get-cientific-report' })
    }

    return <button className='searchButton' onClick={() => takeIt()}><FaDownload size={30} /></button>

  }



  const config: ISearchComponent = {
    idKey: `${endpoint}-${projectHash}-${eventHash}`,
    title,
    endpoint,
    schema,
    autoStart: true,
    headModules: [
      () =>
        lineModules.create({
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
      () => getReport()
    ],
    bodyModules: [
      (lineData: Record<string, any>) =>
        lineModules.updateUnique({
          lineData,
          title,
          formSchema: formSchemaUpdate,
          validationSchema: {},
          endpoint,
        }),
      (lineData: Record<string, any>) =>
        lineModules.delete({
          lineData,
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
      (lineData: Record<string, any>) => upgradeWithAI({ lineData }),
      (lineData: Record<string, any>) => transcriptionToAudioAI({ lineData })
    ],
  };

  return (
    <Container>
      <SearchComponent {...config} />
    </Container>
  );
};

export default CientificLibrary;
