import React, { useEffect, useRef, InputHTMLAttributes, useState } from 'react';
import { useField } from '@unform/core';
import { Container } from './styles';
import theme from '../../../styles/ThemeComponents';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  style?: Record<string, any>;
  name: string;
  unique?: boolean;
  preText?: JSX.Element;
  posText?: JSX.Element;
  background_line?: string;
  setCallBack?: Function;
  options: {
    id: string;
    value: string;
    label: string;
    sub_title?: string;
  }[];
}
const CheckboxInputSingle: React.FC<Props> = ({
  name,
  options,
  preText = '',
  posText = '',
  unique = false,
  style,
  background_line = '',
  setCallBack = (value) => { },
  ...rest
}) => {
  const selectRef = useRef(null);
  const { fieldName, registerField, defaultValue = [] } = useField(name);
  const [values, setValues] = useState<string>('');
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      path: undefined,
      getValue: (refs: any) => {
        return unique ? values?.[0] || '' : values;
      },
      clearValue: (refs: HTMLInputElement[]) => {
        setValues('')
      },
      setValue: (refs: HTMLInputElement[], valuesItems: string) => {
        setValues(valuesItems)
      },
    });
  }, [defaultValue, fieldName, values, registerField, unique]);
  return (
    <Container style={style} {...rest}>
      {preText && <label style={{ border: '0', textAlign: 'justify', color: theme.color }}>{preText}</label>}
      {options.map((option, index) => (
        <label style={{ cursor: 'pointer', color: theme.color, width: '100%', background: background_line ? background_line : 'none', padding: "10px" }} htmlFor={`${name}_${option.id}`} key={`${name}_${option.id}`}>
          <input style={{ color: theme.color, border: '1px solid#333' }}
            onClick={(e) => {
              if (unique) {

                setValues(e.currentTarget.value)
                setCallBack(e.currentTarget.value);


              }
            }}
            defaultChecked={values === option.id}

            value={option.value}
            type="checkbox"
            id={`${name}_${option.id}`}
            {...rest}
          />
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', marginLeft: '10px' }}>
            <strong style={{ color: theme.color, fontSize: '14px' }}>{option.label}</strong>
            {option.sub_title && <p style={{ color: theme.color, fontSize: '12px' }}>{option.sub_title}</p>}
          </div>
        </label>
      ))}
      {posText && <label style={{ border: '0', textAlign: 'justify', color: theme.color }}>{posText}</label>}
    </Container>
  );
};
export default CheckboxInputSingle;
