import React from 'react';

import {
  SearchComponent,
  ISearchComponent,
} from '../../core/components/SearchComponent';

import lineModules from '../../core/components/lineModules'
import { Container } from './styles';
import { useParams } from 'react-router-dom';
import CopyToClipboard from '../../../../components/CopyToClipboard';
import { urlWebsite } from '../../../../config';

interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}

interface ILine {
  [key: string]: string;
}
interface IParams {
  projectHash: string;
  eventHash: string;
}

interface IParams {
  projectHash: string;
  eventHash: string;
}

const CourseProducts: React.FC = () => {
  const endpoint = '/course-products';
  const title = 'Atividades selecionáveis';
  const { projectHash, eventHash } = useParams<IParams>();

  const schema: Record<string, any> = {
    _id: { ref: '_id', column: '_id', label: 'ID' },
    project_id: { ref: 'project_id', column: 'project_id', label: 'Projeto' },
    title: { ref: 'title', column: 'title', label: 'Título' },
    description: { ref: 'description', column: 'description', label: 'Descritivo' },
    quantity: { ref: 'quantity', column: 'quantity', label: 'Qtd total' },
    quantity_used: { ref: 'quantity_used', column: 'quantity_used', label: 'Qtd utilizada' },
    visible: { ref: 'visible', column: 'visible', label: 'Visível?' },
    status: { ref: 'status', column: 'status', label: 'Inscrições abertas?' },
    createdAt: {
      ref: 'created_at',
      column: 'createdAt',
      label: 'Data de Criação',
      mask: 'date',
    },
  };
  const formSchema = {
    title: { model: 'input', type: 'text', name: 'title', label: 'Título' },
    description: { model: 'richText', type: 'text', name: 'description', label: 'Descritivo' },
    group: { model: 'ArrayInputIncluder', type: 'text', name: 'group', label: 'Grupo' },
    date: { model: 'datePickerHour', type: 'text', name: 'date', label: 'Data de início' },
    date_end: { model: 'datePickerHour', type: 'text', name: 'date_end', label: 'Data de encerramento' },
    quantity: { model: 'input', type: 'text', name: 'quantity', label: 'Quantidade total de vagas' },
    status: {
      model: 'selectSimple',
      name: 'status',
      label: 'Inscrições abertas?',
      defaultValue: 'yes',
      options: [
        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },
      ]
    },
    visible: {
      model: 'selectSimple',
      name: 'visible',
      label: 'Visível?',
      defaultValue: 'yes',
      options: [
        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },
      ]
    },
  };

  const formSchemaUpdate = {

    ...formSchema
  };



  const formValidation: Record<string, ValidationSchema> = {

  };



  const config: ISearchComponent = {
    idKey: `${endpoint}-${projectHash}-${eventHash}`,
    title,
    endpoint,
    schema,
    autoStart: true,
    headModules: [
      () =>
        lineModules.create({
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
    bodyModules: [
      (lineData: Record<string, any>) => <button className="lineIcon" title="Editar" type="button">  <CopyToClipboard text={`${urlWebsite}/app/${lineData?.project_id}/credenciar-curso/${lineData?._id?.toString()}`} /> </button>,

      (lineData: Record<string, any>) =>
        lineModules.updateUnique({
          lineData,
          title,
          formSchema: formSchemaUpdate,
          validationSchema: {},
          endpoint,
        }),
      (lineData: Record<string, any>) =>
        lineModules.delete({
          lineData,
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
  };

  return (
    <Container>
      <SearchComponent {...config} />
    </Container>
  );
};

export default CourseProducts;
