import React, { useCallback, useRef, useEffect, useState } from 'react';

import { IconType } from 'react-icons/lib';
import { Link } from 'react-router-dom';
import { FiChevronRight, FiChevronLeft } from 'react-icons/fi';
import api from '../../../../services/api';
import { date, hour, dateLanguage } from '../../../../utils/date';
import { Container, Sidebar, Body } from './styles';
import { addHours, getDate, getMonth, getYear } from 'date-fns';
import { useToast } from '../../../../hooks/Toast';
import SelectApi from '../../core/components/Forms/SelectApi';
import { Form } from '@unform/web';
import DatePicker from '../../core/components/Forms/DatePicker';
import Button from '../../core/components/Forms/Button';
import SelectSimple from '../../core/components/Forms/SelectSimple';
import ChartBar from '../../core/components/Charts/Bar';
import { uuid } from 'uuidv4';

interface IOptions {
  text?: string;
  label?: string;
  value: string;
}

interface IChartData {
  hora: string;
  usuarios: number;
}

const ReporterRecorrence: React.FC = () => {

  const [projectsList, setProjectsList] = useState<Array<Record<string, any>>>([]);
  const [eventsList, setEventsList] = useState<Array<Record<string, any>>>([]);

  const [listItems, setListItems] = useState<Array<Record<string, any>>>([]);

  const [records, setRecords] = useState<Array<Record<string, any>>>([]);
  const [lessons, setLessons] = useState<Array<IOptions>>([]);
  const [projects, setProjects] = useState<Array<IOptions>>([]);
  const [currentProject, setCurrentProject] = useState('');
  const [events, setEvents] = useState<Array<IOptions>>([]);
  const [graphData, setGraphData] = useState<Array<IChartData>>([]);
  const [responsive, setResponsive] = useState('Sim');
  const [typeValue, setTypeValue] = useState('month');

  const [currentMonth, setCurrentMonth] = useState(getMonth(new Date()) + 1);
  const [currentYear, setCurrentYear] = useState(getYear(new Date()));

  const currentProjectItem = projectsList?.find(p => p.url === currentProject);

  const [status, setStatus] = useState({
    project_id: '',
    date: new Date().toISOString(),
    selection: 'global',
    visualization: 'list',
    type: 'month',
    day: getDate(new Date()),
    month: getMonth(new Date()) + 1,
    year: getYear(new Date()),
    responsive: 'Sim'
  });

  const callReport = async (data) => {


    if (currentProject) {

      const response = await api.get(`/attendance-report-check/${currentProject}`);

      if (response) {
        //  setGraphData(response.data);
        setRecords(response.data)
      }

    }


  }




  const loadProjects = async () => {

    const response = await api.get('/projects?limitItems=100');
    if (response) {

      const data: Array<IOptions> = [];
      response.data.rows.map((m, index) => {
        if (index === 0) {
          setCurrentProject(m.url)
        }

        data.push({ label: m.title, text: m.title, value: m.url });
      })

      setProjects(data);
      setProjectsList(response.data.rows)

    }


  }

  const loadEventsList = async () => {

    const response = await api.get('/events', {
      params: {

        limitItems: 1000
      }

    });
    if (response) {



      setEventsList(response.data.rows);

    }


  }

  const loadEvents = async () => {

    const response = await api.get('/events', {
      params: {
        where: { project_id: currentProject },
        limitItems: 100
      }

    });
    if (response) {

      const data: Array<IOptions> = [{ label: 'Todos', text: 'Todos', value: 'a' }];
      response.data.rows.map(m => {
        data.push({ label: m.title, text: m.title, value: m.url });
      })

      setEvents(data);

    }
  }


  useEffect(() => {
    callReport({
      month: currentMonth,
      year: currentYear,
    });
  }, [currentProject])

  useEffect(() => {


    loadProjects();
    loadEventsList();



  }, [])






  const getList = () => {

    const items: Array<Record<string, any>> = [];

    records.map(recordsList => {







      const itemEvent = {
        title: recordsList.title,
        counter: recordsList.count,
        records: recordsList.count,
        total: recordsList.total,
        csat: recordsList.total
      }

      items.push(itemEvent);


    })

    setListItems(items);

  }

  const renderList = () => {

    const render = [...records];


    const elements: Array<JSX.Element> = [];

    let total = 0;

    elements.push(<tr><td><strong style={{ color: '#333' }}> Evento</strong></td> <td><strong style={{ color: '#333' }}>Usuários que visitaram os 2 projetos</strong></td></tr>)
    render.map(elementData => {


      elements.push(<tr><td>{elementData?.title}</td><td>{elementData?.counter}</td> </tr>)
      total = total + parseInt(elementData?.counter, 10);

    })
    elements.push(<tr><td><strong style={{ color: '#333' }}>  Total:</strong></td><td><strong style={{ color: '#333' }}>{total || 0}</strong></td></tr>)

    return elements;



  }



  return (
    <Container>

      <Sidebar>


        <Form key={uuid()} onSubmit={() => { }} initialData={{ project_id: currentProject }}>
          <h2 style={{ color: "#333" }}>Relatórios</h2>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap', padding: '15px' }}>



            <div style={{ width: '100%', maxWidth: '300px' }}><label>Projeto</label><SelectSimple setCallback={(value) => setCurrentProject(value)} label="" options={projects} name="project_id" /></div>




          </div>
        </Form>
      </Sidebar>

      {/* 
        Quando abrir, eu quero ver uma lista dos CSATs no mês, Global
    
          */}

      <Body size={graphData.length} responsive={responsive}>
        {currentProjectItem?.title ? <h2>Projeto: {currentProjectItem?.title}</h2> : <></>}
        <table className='table'>
          <tbody>
            <tr><th>Evento</th><th>Participantes</th></tr>
            {renderList()}
          </tbody>
        </table>


        {
          //graphData && <div style={{ width: '100%', height: '50vh' }}><ChartBar indexBy="hora" headers={['usuarios']} data={graphData} /></div>
        }

      </Body>


    </Container>
  );
};
export default ReporterRecorrence;
