import React, { useCallback, useRef, useEffect, useState } from 'react';

import api from '../../../../../../services/api';

import { Body } from '../../styles';

import { FaFileExcel, FaSpinner } from 'react-icons/fa';
import downloadit, { downloaditPost } from '../../../../../../utils/download';
import { useAuth } from '../../../../../../hooks/Auth';
import { apiUrl } from '../../../../../../config';


interface IOptions {
    text?: string;
    label?: string;
    value: string;
}

interface IReport {
    rows: Array<Record<string, any>>;
    count: number;
}



const CientificLibraryListCategories: React.FC = () => {

    const { token } = useAuth();
    const [records, setRecords] = useState<IReport>({ rows: [], count: 0 });

    const [loading, setLoading] = useState(false);



    const callReport = async () => {


        setLoading(true)

        const response = await api.get('/reporter-list-podcast-library-categories');

        if (response) {
            //  setGraphData(response.data);
            setRecords(response.data);

        }
        setLoading(false)

    }


    useEffect(() => {
        callReport();
    }, [])


    const loadExcel = async (key) => {



        setLoading(true);
        const params = {
            where: { keywords: { "$in": [key] } },
            limit: 100000,
            type: 'xlsx',
            hash: token,
            export_data: {
                _id: { ref: '_id', label: 'ID', search: true },
                title: { ref: 'title', label: 'Título', search: true },
                category: { ref: 'category', label: 'Categorias', search: true },
                nested_authors: { ref: 'nested_authors', label: 'Autores', search: true },
                characters: { ref: "characters", label: "Caracteres", search: true },
                time_to_read: { ref: "time_to_read", label: "Tempo de leitura", search: true },
                url: { ref: "url", label: "URL", search: true, pre_text: 'https://dasaeducaeventos.com.br/biblioteca-cientifica/artigos/' },
            }
        }

        const url = `/cientific-library`;
        await downloadit({ url: url, type: 'xlsx', params: params })

        setLoading(false);
    }


    const downloadData = (render) => {

        const elements: Array<Array<string>> = []
        elements.push(['Categoria', 'QTD de artigos', 'Acessos 1º Semestre', "Acessos 2º Semestre", "Acessos no ano"]);
        render.map(elementData => {


            if (elementData?.title?.trim()?.length > 0) {
                elements.push([elementData?.title, elementData?.count, elementData?.visits_1st_semester, elementData?.visits_2nd_semester, elementData?.visits]);
            }

        })

        return downloaditPost({ url: `${apiUrl}/converter/xlsx`, data: elements, type: 'xlsx' });

    }

    const renderList = (recordsValue) => {

        const render = recordsValue ? { ...recordsValue } : { rows: [], count: 0 };



        const elements: Array<JSX.Element> = [];


        elements.push(<tr><td style={{ width: '30px' }}><button className='searchButtonMini' onClick={() => downloadData(records?.rows || [])}><FaFileExcel /></button></td><td><strong style={{ color: '#333' }}>Categoria</strong></td>
            <td><strong style={{ color: '#333' }}>QTD de artigos</strong> </td>
            <td><strong style={{ color: '#333' }}>Acessos 1ª Semestre</strong> </td>
            <td><strong style={{ color: '#333' }}>Acessos 2º Semestre</strong> </td>
            <td><strong style={{ color: '#333' }}>Acessos no ano</strong> </td>
        </tr>)




        render?.rows?.map(elementData => {



            if (elementData?.title?.trim()?.length > 0) {
                elements.push(<tr><td style={{ width: '30px' }}><FaFileExcel size={25} style={{ cursor: 'pointer' }} onClick={() => loadExcel(elementData?.title)} /> </td><td>{elementData?.title}</td>
                    <td>{elementData?.count}</td>
                    <td>{elementData?.visits_1st_semester} </td>
                    <td>{elementData?.visits_2nd_semester} </td>
                    <td>{elementData?.visits} </td>

                </tr>)
            }

        })
        return elements;




    }


    return (

        <Body  >
            {loading ? <div style={{ width: '100%', minHeight: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><h2 style={{ color: '#333', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><FaSpinner style={{ marginRight: '10px' }} className='spin' size={30} />{` `} Carregando</h2></div> :
                <>

                    <table className='table' style={{ maxWidth: '600px', margin: '0px auto' }}>
                        <tbody>

                            {renderList(records)}
                        </tbody>
                    </table>
                </>
            }


            {
                //graphData && <div style={{ width: '100%', height: '50vh' }}><ChartBar indexBy="hora" headers={['usuarios']} data={graphData} /></div>
            }

        </Body>
    );
};
export default CientificLibraryListCategories;
