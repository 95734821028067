import React, { useCallback, useRef, useEffect, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';

import { IconType } from 'react-icons/lib';

import {
  Container,
  SecondaryContainer,
  ContainerWhite,
  GreyContainer,
  Capa,
  ProgramContainer,
  Sponsors,
  Photo,
} from './styles';

import getValidationErrors from '../../utils/getValidationErrors';

import { useAuth } from '../../hooks/Auth';
import { useToast } from '../../hooks/Toast';
import { useModal } from '../../hooks/Modal';

import { usePages } from '../../hooks/Pages';
import { useLanguage } from '../../hooks/Language';
import SlideList from '../DashboardPages/features/SliderComponent';
import ProjectList from '../DashboardPages/features/ProjectList';
import EventList from '../DashboardPages/features/EventsList';
import api from '../../services/api';
import { hash, urlWebsite } from '../../config';
import { Button } from '../../components/Button/styles';
import SliderContainer from '../../components/SliderContainer';
import { FaBrain, FaSearch, FaStar } from 'react-icons/fa';
import Calendar from './modules/Calendar';
import SelectSimple from '../../components/Forms/SelectSimple';
import EventsListDisplay from '../DashboardPages/features/EventsListDisplay';
import PosterListDisplay from '../DashboardPages/features/PosterListDisplay';
import PodcastListDisplay from '../DashboardPages/features/PodcastListDisplay';
import TestimonialListDisplay from '../DashboardPages/features/TestimonialDisplay';
import { ContactIcons, ContactIconsOrange } from '../../components/Menu';
import themeDefault from '../../styles/ThemeComponents';
import HomeSlider from '../HomeSlider';
import { loadFromJson } from '../../utils/loadFromJson';

interface IMenu {
  title: string;
  target: string;
  targetID: string;
  fluid: number;
  external: number;
  status: number;
}

interface SignInData {
  email: string;
  password: string;
}

interface IPublicData {
  icon: IconType;
  title: string;
  text: string;
}

const Home2: React.FC = () => {

  const { setMenu } = usePages();
  const { setAvailableLanguages } = useLanguage();
  const { translate } = useLanguage();
  const lorem = 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.';
  const { addModal, removeModal } = useModal();
  const [slides, setSlides] = useState<Array<JSX.Element>>([]);
  const { user } = useAuth();

  const [search, setSearch] = useState('');
  const [currentCategory, setCurrentCategory] = useState('');
  const sendToEvents = () => {

    let link = currentCategory ? `/${currentCategory}` : `/eventos`;

    if (search) {
      link = `${link}?search=${encodeURIComponent(search)}`;
    }

    window.location.href = link;

  }

  useEffect(() => {

    if (currentCategory) {
      window.location.href = currentCategory ? `/${currentCategory}` : `/eventos`;
    }

  }, [currentCategory])

  const loadConfig = async () => {
    let data: Record<string, any> = {};

    const item = await loadFromJson({ hash: hash, project_id: 'global', table: 'Global' })


    if (!item) {
      const config = await api.get('/get_platform');

      data = config.data;
    }
    else {
      data = item?.[0] || {}
    }


    if (data.slides) {
      const slideJSX: Array<JSX.Element> = [];

      data.slides.map(s => {
        slideJSX.push(s.link_url ? <a href={s.link_url}><img src={`${urlWebsite}/${s.url}`} style={{ width: '100%' }} /></a> : <img src={`${urlWebsite}/${s.url}`} style={{ width: '100%' }} />)
      })

      setSlides(slideJSX);

      if (data?.popup_status === 'yes' && data?.popup_images && data?.popup_images?.length > 0) {

        if (user && user.updated_information !== 'yes' && user.profile === 2) {

        }
        else {
          addModal({
            key: 'popup',
            theme: 'whiteModalCapa',
            title: ' ',
            content: <><div style={{ width: '100%', maxWidth: '700px', margin: '0px auto' }}>
              <h2 style={{ padding: '10px', width: '100%', textAlign: 'center', color: 'rgb(0,14,64)' }}>Clique na imagem para acessar</h2>
              {data?.popup_images.map(popup => {
                return <a onClick={() => removeModal('popup')} key={`popup-url-${popup.url}`} style={{ width: '100%', maxWidth: '700px', margin: '15px auto' }} href={`${popup?.link_url}`}>
                  <img src={`${urlWebsite}/${popup.url}`} style={{ width: '100%', maxWidth: '700px', margin: '0px auto' }} />
                </a>

              })}

            </div></>
          })

        }


      }

    }

    const menu: Array<IMenu> = [];

    try {
      data?.menu.map((m: IMenu) => {
        m.fluid = parseInt(m.fluid.toString(), 10);
        m.status = parseInt(m.status.toString(), 10);
        m.external = parseInt(m.external.toString(), 10);

        menu.push(m);
      })

      setMenu(menu);

    }
    catch (err) {
      console.log('Error on define Menu');
    }


    setAvailableLanguages(data?.languages || []);




  }

  useEffect(() => {
    loadConfig();
    /*
        addModal({
       key:'popup',
       theme: 'whiteModal2',
       title:' ',
       content: <><div style={{width:'100%', maxWidth:'500px', margin: '0px auto'}}>
         <Link onClick={() => removeModal('popup')} style={{width:'100%', maxWidth:'500px', margin: '0px auto'}} to="/app/sessao-clinica-hospitais-dasa-brasil/login">
           <img src="/apoio/sessao-clinica.png" style={{width:'100%', maxWidth:'500px', margin: '0px auto'}}/>
         </Link>
         </div></>
     }  ) */
  }, [])


  const handle = () => { }

  return (<>
    <Container style={{ minHeight: '80vh' }}>
      <Capa style={{ minHeight: 'auto', marginBottom: '25px' }}>
        <div className='p50 homeTitle' style={{ alignItems: 'flex-start' }}>

          <p>Seja bem-vindo ao
            <strong style={{ color: '#f15a23' }}> espaço educacional</strong> Dasa Educa.</p>

          <p>Sua jornada pelo <strong style={{ color: '#f15a23' }}>conhecimento</strong> começa aqui.</p>

        </div>
        <div className='p50  '>
          <div className='searchDiv'>
            <select onChange={(e) => {
              setCurrentCategory(e.target.value)

            }} className='homeSelect'>
              <option value={""}>Pesquisar por</option>
              <option value={"eventos"}>Eventos</option>
              <option value={"biblioteca-cientifica"}>Biblioteca científica</option>
            </select>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <input value={search} onChange={(e) => setSearch(e.target.value)} onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  sendToEvents();
                }
              }} className='homeSelect' name="search" placeholder='Pesquisar' />

              <button onClick={() => sendToEvents()} type="button" className='homeSelectReverse'><FaSearch /></button>
            </div>
          </div>
          {/*   <button className='defaultButtonReverse alignSelfRight' style={{ width: '100%', margin: '4px 3px', padding: '10px 15px', display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'center', marginBottom: '25px' }}><FaStar style={{ minWidth: '25px' }} /> Atualize seu cadastro</button> */}

        </div>
      </Capa>

      <Capa  >

        <div style={{ width: '100%', margin: '0px', maxWidth: '650px' }}>

          <EventsListDisplay showLinks allowed={['highlight', 'next', 'prev', 'nextLives']} />
          <PosterListDisplay showLinks allowed={['next']} />
          <PodcastListDisplay showLinks allowed={['next']} />

        </div>
        <div className='calendarDiv' >
          <h2 style={{ marginBottom: '15px' }}>Confira nossa agenda</h2>
          <Calendar />

        </div>
        <div className='ilustrationDiv' >
          <HomeSlider />
        </div>
      </Capa>

      <div className='ilustrationDiv2' >
        <HomeSlider />

      </div>

    </Container>

    <Container style={{ background: themeDefault.alternativeButton.background, minHeight: 'auto', position: 'relative', padding: '10px' }}>

      <Capa className='columnReverse' style={{ minHeight: 'auto' }}>

        <div className='p50 homeTitle'>

          {/* <p  ><strong>Perdeu alguma transmissão?
            <br />

            Aproveite nossos conteúdos </strong><strong style={{ color: '#fff' }}>On Demand</strong>
            </p> */}

          <p style={{ fontSize: '16px', color: 'rgb(0,14,64)' }}>
            © Dasa 2023. Todos os direitos reservados<br />
            Responsável Técnico: Dr. Cristovam Scapulatempo Neto CRM 102037
          </p>
          {/* <div className='buttonDiv'>
  
            <Link to="/eventos">EVENTOS</Link>
            <Link to="/biblioteca-cientifica">BIBLIOTECA CIENTÍFICA</Link>
            </div> */}
          {/*
          <strong style={{ color: themeDefault.globalColor, marginBottom: '30px', marginTop: '100px' }}>O que dizem sobre nós?</strong>
          <TestimonialListDisplay />
            */}
        </div>
        <div className='p50 socialFooter  ' style={{ position: 'relative', alignItems: 'flex-end' }}>
          <img src="/apoio/ilustracao1.png" className='ilustracao1' />
          {/* <ContactIconsOrange /> */}
        </div>
      </Capa>


    </Container></>
  );
};
export default Home2;
