import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom"
import { useModal } from "../../../../hooks/Modal";
import { useToast } from "../../../../hooks/Toast";
import api from "../../../../services/api";

const ExtraProducts: React.FC = () => {

    const { projectHash } = useParams<Record<string, any>>();
    const { addToast } = useToast();
    const { addModal, removeModal } = useModal();
    const [products, setProducts] = useState<Array<Record<string, any>>>([]);
    const [tickets, setTickets] = useState<Array<Record<string, any>>>([]);
    const [loading, setLoading] = useState(false);

    const load = async () => {
        setLoading(true);
        const productsList = await api.get(`/list-extra-product/${projectHash}`);
        const myTickets = await api.get(`/my-tickets/${projectHash}`);

        setProducts(productsList?.data?.rows || []);
        setTickets(myTickets?.data?.rows || []);

        setLoading(false);
    }

    const addProduct = async (id: string) => {
        setLoading(true);


        try {
            removeModal('modal-product');
            const response = await api.post(`/add-extra-product/${id}`);

            await load();
            setLoading(false);
            addToast({ title: 'Inscrito com sucesso', type: 'success' })



        }
        catch (err) {
            setLoading(false);
            addToast({ title: err?.response?.data?.message || err?.response?.data?.error || 'Erro ao se inscrever', type: 'error' })
        }

    }

    const modalAdd = (product) => {


        addModal({
            title: '', key: 'modal-product', theme: 'whiteModal2', content: <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '15px' }}>
                <h2>Confirmar inscrição</h2>
                <p>{product?.title}</p>

                <button className="defaultButton" onClick={() => addProduct(product._id?.toString())}>Confirmar</button>
            </div>
        })

    }

    useEffect(() => {

        if (projectHash) {
            load();
        }

    }, [projectHash])




    return <div style={{ width: '100%', minHeight: '200px', position: 'relative' }}>

        {loading && <div style={{ position: 'absolute', background: '#eee', top: '0px', left: '0px', width: '100%', bottom: '0px', right: '0px', display: 'flex', alignContent: 'center' }}>
            <h2 style={{ color: '#333' }}>Carregando atividades adicionais...</h2>
        </div>}
        {products?.length > 0 ? <div>

            <h2>Selecione as atividades extras que deseja participar</h2>
            <table><tbody>



                {products.map(product => {

                    const index = tickets.findIndex(ticket => ticket.product_id === product._id);

                    return <tr key={`extra_product_${product._id}`}><td><strong style={{ color: '#333' }}>{product.title}</strong></td><td>{index >= 0 ? <button className="defaultButton">Inscrição confirmada</button> : product?.available_quantity <= 0 ? <h2>Esgotado</h2> : <button className="defaultButton" onClick={() => modalAdd(product)}>Inscreva-se!</button>}</td></tr>


                })}


            </tbody></table>
        </div> : <></>}

    </div>
}

export default ExtraProducts;