import React, { useState } from 'react';
import { FaChalkboardTeacher, FaChartBar, FaFileExcel, FaSpinner } from 'react-icons/fa';
import CopyToClipboard from '../../../core/components/CopyToClipboard';
import { apiUrl, getToken, urlWebsite } from '../../../../../config';
import userEvent from '@testing-library/user-event';
import { useAuth } from '../../../../../hooks/Auth';
import downloadit from '../../../../../utils/download';
import api from '../../../../../services/api';
import { useModal } from '../../../../../hooks/Modal';
import { useParams } from 'react-router-dom';

interface ICreateModule {
  lineData: Record<string, any>;
}


const ExportSurveyListReport: React.FC<ICreateModule> = ({
  lineData,
}) => {
  const { addModal } = useModal();
  const { user } = useAuth();
  const { projectHash } = useParams<Record<string, any>>();
  const [loading, setLoading] = useState(false);

  const getDownload = async (data) => {

    const response = await api.get(`${apiUrl}/get-research-list-report/${data._id}`);
    const quantity = await api.get(`${apiUrl}/satisfaction-survey-answers`, { params: { where: { satisfaction_survey_id: lineData?._id?.toString() } } });

    const getStatus = (value) => {

      const newValue = parseInt(value, 10);

      if (newValue >= 80) {
        return <img src='/emoji_survey/love.png' style={{ width: '30px', margin: '0px 10px' }} />
      }
      if (newValue >= 60) {
        return <img src='/emoji_survey/good.png' style={{ width: '30px', margin: '0px 10px' }} />
      }
      if (newValue >= 40) {
        return <img src='/emoji_survey/normal.png' style={{ width: '30px', margin: '0px 10px' }} />
      }
      if (newValue >= 20) {
        return <img src='/emoji_survey/bad.png' style={{ width: '30px', margin: '0px 10px' }} />
      }
      if (newValue >= 0) {
        return <img src='/emoji_survey/sad.png' style={{ width: '30px', margin: '0px 10px' }} />
      }
    }


    addModal({
      title: 'Relatório CSAT', theme: 'whiteModal', content: <div>
        <p style={{ maxWidth: '600px', width: '100%', color: '#333', padding: '10px', fontSize: '14px', marginBottom: '15px' }}><strong>Metodologia: </strong><br />
          Para a obtenção individual, são consideradas todas as avaliações com valor 4 ou 5, e divididas pelo total das avaliações.
          <br /><br />
          O CSAT Geral é a média dos valores obtidos nos tópicos avaliados.
          <br /> Pesquisas preenchidas: {quantity?.data?.count}
        </p>
        <table style={{ color: '#333', maxWidth: '600px', width: '100%', borderCollapse: 'collapse' }}>
          <tbody>
            <tr><td style={{ padding: '10px', color: '#333', border: '1px solid #ddd' }}>{response.data.p1.label}</td><td style={{ padding: '10px', color: '#333', border: '1px solid #ddd', display: 'flex', alignItems: 'center' }}>{getStatus(response.data.p1.value)}{response.data.p1.value}%</td></tr>
            <tr><td style={{ padding: '10px', color: '#333', border: '1px solid #ddd' }}>{response.data.p2.label}</td><td style={{ padding: '10px', color: '#333', border: '1px solid #ddd', display: 'flex', alignItems: 'center' }}>{getStatus(response.data.p2.value)}{response.data.p2.value}%</td></tr>
            <tr><td style={{ padding: '10px', color: '#333', border: '1px solid #ddd' }}>{response.data.p3.label}</td><td style={{ padding: '10px', color: '#333', border: '1px solid #ddd', display: 'flex', alignItems: 'center' }}>{getStatus(response.data.p3.value)}{response.data.p3.value}%</td></tr>
            <tr><td style={{ padding: '10px', color: '#333', border: '1px solid #ddd' }}>{response.data.p4.label}</td><td style={{ padding: '10px', color: '#333', border: '1px solid #ddd', display: 'flex', alignItems: 'center' }}>{getStatus(response.data.p4.value)}{response.data.p4.value}%</td></tr>
            <tr><td style={{ padding: '10px', color: '#333', border: '1px solid #ddd' }}>CSAT Geral</td><td style={{ padding: '10px', color: '#333', border: '1px solid #ddd', display: 'flex', alignItems: 'center' }}>{getStatus(response.data.total)}{response.data.total}%</td></tr>
          </tbody>
        </table>


      </div>
    })

  }

  const moduleList = ({ data }): JSX.Element => {

    return <> {user && user.profile === 1 ? loading ? <FaSpinner style={{ margin: '5px', cursor: 'pointer' }} className='rotateAnimation' size={20} /> : <FaChartBar style={{ margin: '5px', cursor: 'pointer' }} color="#333" onClick={() => getDownload(data)} size={30} /> : <></>}
    </>;
  }



  return moduleList({ data: lineData });
}

export default ExportSurveyListReport;