import React from 'react';

import uuid from '../../core/components/UniqueHash';
import {
  SearchComponent,
  ISearchComponent,
} from '../../core/components/SearchComponent';

import lineModules from '../../core/components/lineModules'
import { Container } from './styles';
import { useParams } from 'react-router-dom';


interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}

interface ILine {
  [key: string]: string;
}     
interface IParams {
  projectHash : string;
  eventHash : string;
}



const Exam: React.FC = () => {
  const endpoint = '/testimonial';
  const title = 'Depoimentos';
    const {projectHash, eventHash } = useParams<IParams>(); const schema: Record<string, any> = {
    _id: { ref: '_id', column: '_id', label: 'ID', show: false },
    
    image: { ref: 'image', column: 'image', label: 'Foto' },
    title: { ref: 'title', column: 'title', label: 'Comentário' },
    name: { ref: 'name', column: 'name', label: 'Participante' },
    event: { ref: 'event', column: 'event', label: 'Evento' },
 
    createdAt: {
      ref: 'created_at',
      column: 'createdAt',
      label: 'Data de Criação',
      mask: 'date',
    },
  };

  const formSchema = {
    title: {
      model: 'richText',
      type: 'text',
      name: 'title',
      label: 'Título',
    },

    name: {
      model: 'input',
      type: 'text',
      name: 'name',
      label: 'Participante',
    },
    event: {
      model: 'input',
      type: 'text',
      name: 'event',
      label: 'Participante',
    },
     
  };


  



  const formSchemaUpdate = {
    ...formSchema,
    image: {
      model: 'uploadImage',
      type: 'upload',
      name: 'image',
      label: 'Imagem',
    },
  };


  const formValidation: Record<string, ValidationSchema> = {
  
  };



  const config: ISearchComponent = {
    idKey : `${endpoint}-${projectHash}-${eventHash}`,
    title,
    endpoint,
    schema,
    autoStart: true,
    headModules: [
      () =>
      lineModules.create({
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
    bodyModules: [
      (lineData: Record<string, any>) =>
        lineModules.updateUnique({
          lineData,
          title,
          formSchema: formSchemaUpdate,
          validationSchema: {},
          endpoint,
        }),
      (lineData: Record<string, any>) =>
        lineModules.delete({
          lineData,
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
  };

  return (
    <Container>
      <SearchComponent {...config} />
    </Container>
  );
};

export default Exam;
