import React from 'react';

import {
  SearchComponent,
  ISearchComponent,
} from '../../core/components/SearchComponent';

import lineModules from '../../core/components/lineModules'
import { Container } from './styles';
import { useParams } from 'react-router-dom';

interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}

interface ILine {
  [key: string]: string;
}
interface IParams {
  projectHash: string;
  eventHash: string;
}

const SpeakerPosition: React.FC = () => {
  const endpoint = '/speakers-position';
  const title = 'Posição - Categorias de Palestrantes';
  const { projectHash, eventHash } = useParams<IParams>();
  const schema: Record<string, any> = {
    _id: { ref: '_id', column: '_id', label: 'ID', show: false },

    position: { ref: 'position', column: 'position', label: 'Posição' },
    title: { ref: 'title', column: 'title', label: 'Categoria' },
    status_id: { ref: 'status_id', column: 'status_id', label: 'Status' },
    group: { ref: 'group', column: 'group', label: 'Grupo' },
    createdAt: {
      ref: 'created_at',
      column: 'createdAt',
      label: 'Data de Criação',
      mask: 'date',
    },
  };

  const formSchema = {
    position: {
      model: 'selectSimple',
      name: 'position',
      label: 'Posição',
      defaultValue: '',
      options: [
        { label: '1', value: '1' },
        { label: '2', value: '2' },
        { label: '3', value: '3' },
        { label: '4', value: '4' },
        { label: '5', value: '5' },
        { label: '6', value: '6' },
        { label: '7', value: '7' },
        { label: '8', value: '8' },
        { label: '9', value: '9' },
        { label: '10', value: '10' },
        { label: '11', value: '11' },
        { label: '12', value: '12' },
        { label: '13', value: '13' },
        { label: '14', value: '14' },
        { label: '15', value: '15' },
        { label: '16', value: '16' },
        { label: '17', value: '17' },
        { label: '18', value: '18' },
        { label: '19', value: '19' },
        { label: '20', value: '20' },
      ],
    },
    title: {
      model: 'input',
      name: 'title',
      label: 'Categoria',
      type: 'text'
    },
    title_en: {
      model: 'input',
      name: 'title_en',
      label: '(ING) Categoria',
      type: 'text'
    },
    title_es: {
      model: 'input',
      name: 'title_es',
      label: '(ESP) Categoria',
      type: 'text'
    },
    order_type: {
      model: 'selectSimple',
      name: 'order_type',
      label: 'Ordenar por',
      defaultValue: 'Sim',
      options: [
        { label: 'Alfabético', value: 'asc' },
        { label: 'Alfabético Invertido', value: 'desc' },
        { label: 'Ordem das inserções', value: 'none' },
      ]
    },
    status_id: {
      model: 'selectSimple',
      name: 'status_id',
      label: 'Ativo?',
      defaultValue: 'Sim',
      options: [
        { label: 'Sim', value: 'Sim' },
        { label: 'Não', value: 'Não' },
      ]
    },
    group: {
      model: 'input',
      name: 'group',
      label: 'Grupo',
      type: 'text'
    },
    speakers: {
      model: 'JsonInput',
      bodyRender: (data: Record<string, any>) => (
        <nav>
          <strong style={{ color: '#333' }}>


            {data.name}
            <br />
            {data.position ? <>{data.position}<br /></> : <></>}
            <p style={{ color: '#333', fontSize: '10px' }}>{data._id}</p>
          </strong>

        </nav>
      ),
      searchRender: (data: Record<string, any>) => (
        <>
          <strong style={{ color: '#333' }}>

            {data.name}<br />
            {data.position ? <>{data.position}<br /></> : <></>}
            <p style={{ color: '#333', fontSize: '10px' }}>{data._id}</p>
          </strong>
          <br />

        </>
      ),
      storageType: 'json',
      endpoint: `/speakers`,
      name: 'speakers',
      label: 'Palestrantes',
    },



  };

  const formSchemaUpdate = {

    ...formSchema
  };

  const formValidation: Record<string, ValidationSchema> = {};



  const config: ISearchComponent = {
    idKey: `${endpoint}-${projectHash}-${eventHash}`,
    title,
    endpoint,
    schema,
    autoStart: true,
    headModules: [
      () =>
        lineModules.create({
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
    bodyModules: [
      (lineData: Record<string, any>) =>
        lineModules.updateUnique({
          lineData,
          title,
          formSchema: formSchemaUpdate,
          validationSchema: {},
          endpoint,
        }),
      (lineData: Record<string, any>) =>
        lineModules.delete({
          lineData,
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
  };

  return (
    <Container>
      <SearchComponent {...config} />
    </Container>
  );
};

export default SpeakerPosition;
