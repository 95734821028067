import React, { useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FiMail, FiUser, FiLock, FiMap, FiArrowRight } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import {
  FaWhatsapp,
  FaFileMedical,
  FaNotesMedical,
  FaHospital,
  FaSimplybuilt,
  FaBuilding,
  FaIdBadge,
  FaClinicMedical,
} from 'react-icons/fa';
import { ISigla, readSigla, siglas as siglasRef } from './siglas';
import { MdError, MdDateRange, MdChat } from 'react-icons/md';

import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormHandles } from '@unform/core';
import Input from '../../../components/Forms/Input';
import Button from '../../../components/Forms/Button';
import Loader from '../../../components/Loader';
import MaskedInput from '../../../components/Forms/MaskedInput';
import { ResponseMessage } from '../../../components/ResponseMessage';
import getValidationErrors from '../../../utils/getValidationErrors';
import { Error } from './styles';
import SelectSimple from '../../../components/Forms/SelectSimple';
import Whatsapp from '../../../components/WhatsAppCompartilhar';

import api from '../../../services/api';
import { authTitle, recaptchaKey, urlWebsite } from '../../../config';
import { countries } from './countries';
import TextareaInput from '../../../components/Forms/TextareaInput';
import { useToast } from '../../../hooks/Toast';
import CheckboxInput from '../../../components/Forms/CheckboxInput';
import getCep from '../../../services/cep';
import Axios from 'axios';
import states from './states';
import { useLanguage } from '../../../hooks/Language';
interface IRecoverFormData {
  [key : string] : any;
}


interface IRead {
  [key: string]: any;
}

interface IOption{
  value: string;
  label: string;
}


interface ICaptcha {
  getValue(): string;
}

const FormContainer = ({ context = {} }) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState<Array<string>>([]);
  const [withPassword, setWithPassword] = useState(true);
  const formRef = useRef<FormHandles>(null);
  const recaptchaRef = useRef(null);
  const getCaptchaValue = (elem: ICaptcha): string => {
    return elem.getValue();
  };
const siglas = readSigla();
  const {addToast} = useToast();
  const {translate,language} = useLanguage();

  const [countryISO, setCountryISO] = useState('BR');
  const [position, setPosition] = useState('');
  const [otherCompany, setOtherCompany] = useState('');

  const [cities,setCities] = useState<Array<IOption>>([]);
  const [stateValue,setStateValue] = useState('');
  const callCityApi = async ( value) => {

    const response = await Axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${value}/municipios`);

    if(response){

      const values : Array<IOption> = [];

      response.data.map( city => {
        values.push( { value: city.nome, label : city.nome});
      })

      setCities(values);
    }


  }

  useEffect(() => {
    callCityApi(stateValue);
  },[stateValue])



  const getCepFromApi = async (value: string): Promise<void> => {
    const cepResponse = await getCep(value);

    if (!cepResponse.error) {
      formRef?.current?.setFieldValue('address', cepResponse.logradouro);
      formRef?.current?.setFieldValue('neighborhood', cepResponse.bairro);
      formRef?.current?.setFieldValue('city', cepResponse.localidade);
      formRef?.current?.setFieldValue('state', cepResponse.uf);
    }
  };


  const handleNewLogin = async (data: IRecoverFormData) => {
    setLoading(true);
    try {
      formRef.current?.setErrors({});
      const schema = Yup.object().shape({
        name: Yup.string().required('Nome obrigatório'),
       
        email: Yup.string()
          .required('Email obrigatório')
          .email('Preencha um email válido'),
          
        phone: Yup.string()
          .required('Telefone com DDD é obrigatório'),
  
      
      });
      data.phone = data.phone.toString().replace(/[^0-9]/g, '');

    
    


      data.email = data.email ? data.email.trim().toLowerCase() : '';

    
      await schema.validate(data, { abortEarly: false });

      


      const captchaValue =
      getCaptchaValue(recaptchaRef?.current || { getValue: () => '' }) ||
      false;




      const newUser = await api
        .post('/subscribe-lead', {
          ...data,
          project_id : 'dasa-educa-leads',
          url: '/',
          captcha : captchaValue,
 
        })
        .catch(error => {
          if (error instanceof Yup.ValidationError) {
            const errors = getValidationErrors(error);
            formRef.current?.setErrors(errors);
            setLoading(false);
          }

          addToast({
            type: 'error',
            title: error?.response?.data?.message || error?.response?.data?.error || 'Erro ao cadastrar',
          });
          setLoading(false);
          return false;
        });

      if (!newUser) {
        setLoading(false);
        return false;
      }

      setSuccess(true);
      setLoading(false);
     

      return false;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);

        const errorInfo = err.errors.reduce((error, prev) => {
          prev = `${prev}, ${error}`;
          return prev;
        });
        setLoading(false);
        addToast({
          type: 'error',
          title: 'Erro de validação',
          description: errorInfo,
        });
      }
      setLoading(false);
    }
  }

  return (
    <>
      {loading === true && (
        <Loader message={translate('Preparando...')} position="fixed" />
      )}

      <aside>
        {success ? (<>
           
          <div className="titleCapa2" style={{position: 'relative',textAlign:'center', margin: '10px', marginTop:'20px', justifyContent:'center', alignItems: 'center' }}  >

<p style={{color: 'rgb(0,14,64)', fontSize:'24px', fontWeight:'bold', marginBottom: '15px'}}>Obrigado!</p>
<p style={{fontSize:'14px',color: 'rgb(0,14,64)'}}>Em breve você receberá mais informações!</p>
 
<br />
<p style={{fontSize:'14px',color: 'rgb(0,14,64)'}}>Aproveite e divulgue para seus amigos!</p>

<aside style={{display:'flex', alignItems:'center', justifyContent:'center', width:'100%'}}>
<Whatsapp
                  text={`${translate('Olá! Quer saber mais sobre a')} ${authTitle}. ${translate('Acesse o link:')} ${urlWebsite}`}
                  number=""
                  message={translate('Compartilhe!')}
                /></aside>
</div>

           
             
               
               
            
       </>
       
        ) : (<>
           
           <div className="titleCapa2 " style={{position: 'relative', color: 'rgb(0,14,64)',textAlign:'center', margin: '10px', marginTop:'20px', justifyContent:'center', alignItems: 'center' }}  >

<p>Receba nossas novidades!</p>
 



</div>
                  <Form ref={formRef} onSubmit={handleNewLogin} initialData={{country: 'BR'}}>
                   

                    <Input name="name" placeholder="Nome completo" />
                    <Input name="email" placeholder="E-mail" />
 <MaskedInput
                      mask="(99) 999999999"
                      name="phone"
                      placeholder="Telefone com DDD"
                    />  
                 


<div style={{marginBottom: '10px', padding: '15px', borderRadius: '10px'}}>


<p
                style={{
                  padding: '10px',
                  background: 'none',
                  margin: '5px auto',
                  color: '#333',
                  fontSize: '12px',
                  textAlign: 'left',
                }}
              >
                {translate('Ao me cadastrar, afirmo que li a')}
{' '}
                <strong>
                  <Link
                    style={{ display: 'inline', color: '#333' }}
                    to="/termos"
                    target="_BLANK"
                  >
                    {translate('Política de Privacidade')}
{' '}
                  </Link>
{' '}
                </strong>
                {translate('desta plataforma.')}
              </p>
              </div>
                    <aside style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <ReCAPTCHA ref={recaptchaRef} sitekey={recaptchaKey} />

                    </aside>  <aside style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <button className="defaultButton" type="submit">
                      {translate('Enviar')}

                  
                    </button>
                    </aside>
                  </Form>
        </>
        )}
      </aside>
    </>
  );
};

export default FormContainer;
