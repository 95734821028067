export const traductionList = [
  {
    ptBr: '',
    en: '',
    es: '',
  },


  { ptBr: 'Muito insatisfeito', en: 'Very unsatisfied', es: 'Muy insatisfecho' },
  { ptBr: 'Insatisfeito', en: 'Dissatisfied', es: 'Insatisfecho' },
  { ptBr: 'Indiferente', en: 'Regular', es: 'Indiferente' },
  { ptBr: 'Satisfeito', en: 'Satisfied', es: 'Satisfecho' },
  { ptBr: 'Muito satisfeito', en: 'Very satisfied', es: 'Muy satisfecho' },

  { ptBr: 'Iniciar', en: 'Start', es: 'Comenzar' },
  { ptBr: 'Pesquisa de satisfação enviada', en: 'Satisfaction survey sent', es: 'Encuesta de satisfacción enviada' },
  { ptBr: 'Erro ao cadastrar sua pesquisa', en: 'Error registering your survey', es: 'Error al registrar su encuesta' },
  { ptBr: 'Obrigado por participar!', en: 'Thank you for participating!', es: '¡Gracias por participar!' },
  { ptBr: 'Carregando...', en: 'Loading...', es: 'Cargando...' },
  { ptBr: 'Obrigado!', en: 'Thanks!', es: '¡Gracias!' },
  { ptBr: 'Você já participou desta pesquisa.', en: 'You have already participated in this survey.', es: 'Ya has participado en esta encuesta.' },
  { ptBr: 'Você parece estar desconectado', en: 'You seem to be offline', es: 'Pareces estar desconectado' },
  { ptBr: 'Realize seu login novamente', en: 'Log in again', es: 'Ingresar de nuevo' },
  { ptBr: 'Obrigado', en: 'Thanks', es: 'Gracias' },
  { ptBr: 'Você já preencheu esta pesquisa', en: 'You have already completed this survey', es: 'Ya has completado esta encuesta' },
  { ptBr: 'Por favor, avalie a pergunta', en: 'Please rate the question', es: 'Por favor califique la pregunta' },
  { ptBr: 'Adicionado', en: 'Added', es: 'Agregado' },
  { ptBr: 'PULAR', en: 'SKIP', es: 'SALTAR' },
  { ptBr: 'Próximo', en: 'Next', es: 'Próximo' },






  { "ptBr": "Identificamos o seu cadastro em nossa plataforma. A sua inscrição no próximo evento está confirmada.", "en": "We have identified your registration on our platform. Your registration for the upcoming event is confirmed.", "es": "Hemos identificado su registro en nuestra plataforma. Su inscripción para el próximo evento está confirmada." },
  { "ptBr": "Preencha a sua data de nascimento", "en": "Please enter your date of birth", "es": "Por favor, ingrese su fecha de nacimiento" },
  { "ptBr": "Preencha uma data de nascimento válida", "en": "Please enter a valid date of birth", "es": "Por favor, ingrese una fecha de nacimiento válida" },
  { "ptBr": "Informe se você autoriza o compartilhamento de seus dados.", "en": "Please inform whether you authorize the sharing of your data.", "es": "Por favor, informe si autoriza el compartir de sus datos." },
  { "ptBr": "Preencha um CPF válido", "en": "Please enter a valid CPF", "es": "Por favor, ingrese un CPF válido" },
  { "ptBr": "Nome obrigatório", "en": "Name is required", "es": "Se requiere el nombre" },
  { "ptBr": "Email obrigatório", "en": "Email is required", "es": "Se requiere el correo electrónico" },
  { "ptBr": "Telefone com DDD é obrigatório", "en": "Phone with area code is required", "es": "Se requiere el teléfono con código de área" },
  { "ptBr": "O Profissão é obrigatório", "en": "Profession is required", "es": "Se requiere la profesión" },
  { "ptBr": "Informe de qual operação Dasa você faz parte", "en": "Please inform which Dasa operation you are part of", "es": "Por favor, informe a qué operación de Dasa pertenece" },
  { "ptBr": "Informe a sua Data de nascimento", "en": "Please inform your date of birth", "es": "Por favor, informe su fecha de nacimiento" },
  { "ptBr": "Informe o seu tipo de documento", "en": "Please inform your document type", "es": "Por favor, informe su tipo de documento" },
  { "ptBr": "País é obrigatório", "en": "Country is required", "es": "Se requiere el país" },
  { "ptBr": "Estado é obrigatório", "en": "State is required", "es": "Se requiere el estado" },
  { "ptBr": "Pedimos que informe: como ficou sabendo do curso?", "en": "We ask that you inform: how did you hear about the course?", "es": "Le pedimos que informe: ¿cómo se enteró del curso?" },
  { "ptBr": "Pedimos que informe: Gostaria de receber futuras comunicações sobre eventos?", "en": "We ask that you inform: Would you like to receive future event communications?", "es": "Le pedimos que informe: ¿Le gustaría recibir comunicaciones futuras sobre eventos?" },
  { "ptBr": "Informe se você autoriza o compartilhamento de seus dados.", "en": "Please inform whether you authorize the sharing of your data.", "es": "Por favor, informe si autoriza el compartir de sus datos." },
  { "ptBr": "Por favor, informe sua área / especialidade", "en": "Please inform your area / specialty", "es": "Por favor, informe su área / especialidad" },
  { "ptBr": "O campo CRM é obrigatório para médicos brasileiros", "en": "The CRM field is required for Brazilian doctors", "es": "El campo CRM es obligatorio para médicos brasileños" },
  { "ptBr": "O estado do CRM é obrigatório para médicos brasileiros", "en": "The CRM state is required for Brazilian doctors", "es": "Se requiere el estado del CRM para médicos brasileños" },
  { "ptBr": "Por favor, informe o nome da sua organização", "en": "Please inform the name of your organization", "es": "Por favor, informe el nombre de su organización" },
  { "ptBr": "Por favor, informe sua área / especialidade", "en": "Please inform your area / specialty", "es": "Por favor, informe su área / especialidad" },
  { "ptBr": "Este email já está sendo utilizado", "en": "This email is already in use", "es": "Este correo electrónico ya está en uso" },
  { "ptBr": "Olá! Acabei de me inscrever no", "en": "Hello! I just registered for", "es": "¡Hola! Acabo de registrarme en" },
  { "ptBr": "Acredito que você irá gostar de participar desse evento! Inscreva-se pelo link", "en": "I believe you will enjoy participating in this event! Register via the link", "es": "¡Creo que disfrutarás participar en este evento! Regístrate a través del enlace" },
  { "ptBr": "QUERO ME INSCREVER", "en": "REGISTER NOW", "es": "INSCRIBIRME" },
  { "ptBr": "Olá, estou no DASA EDUCA", "en": "Hello, I'm on DASA EDUCA", "es": "Hola, estoy en DASA EDUCA" },
  { "ptBr": "Olá! Não localizamos o e-mail informado em nossa base de dados. Por favor certifique-se de que está correto ou inscreva-se!", "en": "Hello! We couldn't find the email provided in our database. Please make sure it is correct or sign up!", "es": "¡Hola! No encontramos el correo electrónico proporcionado en nuestra base de datos. Por favor, asegúrese de que sea correcto o regístrese." },
  { "ptBr": "Sua inscrição neste evento não foi identificada", "en": "Your registration for this event was not found", "es": "No se encontró su inscripción para este evento" },
  { "ptBr": "Clique no botão abaixo para se inscrever", "en": "Click the button below to register", "es": "Haga clic en el botón de abajo para registrarse" },
  { "ptBr": "TODAS AS DATAS", "en": "ALL DATES", "es": "TODAS LAS FECHAS" },
  { "ptBr": "Eventos anteriores", "en": "Previous Events", "es": "Eventos anteriores" },
  { "ptBr": "Próximo", "en": "Next", "es": "Siguiente" },
  { "ptBr": "Selecionar Idioma", "en": "Select Language", "es": "Seleccionar Idioma" },

  { "ptBr": "Palestrantes", "en": "Speakers", "es": "Oradores" },
  { "ptBr": "Certificados", "en": "Certificates", "es": "Certificados" },








  { "ptBr": "Olá, estou no", "en": "Hello, I'm at", "es": "Hola, estoy en" },













  { "ptBr": "Insira seu email", "en": "Enter your email", "es": "Ingrese su correo electrónico" },


  { "ptBr": "Visualizar meus certificados", "en": "View my certificates", "es": "Ver mis certificados" },
  { "ptBr": "Verificar", "en": "Check", "es": "Verificar" },




  { "ptBr": "Meus Certificados", "en": "My Certificates", "es": "Mis Certificados" },
  { "ptBr": "Nenhum certificado encontrado", "en": "No certificates found", "es": "No se encontraron certificados" },
  { "ptBr": "Meus Certificados", "en": "My Certificates", "es": "Mis Certificados" },
  { "ptBr": "Visualizar", "en": "View", "es": "Visualizar" },
  { "ptBr": "Todos os dias", "en": "Every day", "es": "Todos los días" },
  { "ptBr": "Clique para visualizar o currículo", "en": "Click to view resume", "es": "Haz clic para ver el currículum" },



  { "ptBr": "Patrocínio", "en": "Sponsorship", "es": "Patrocinio" },
  { "ptBr": "Erro ao checar usuário, por favor, tente novamente", "en": "Error checking user, please try again", "es": "Error al verificar el usuario, por favor, inténtelo de nuevo" },
  { "ptBr": "Olá participante!", "en": "Hello participant!", "es": "¡Hola participante!" },

  {
    "ptBr": "Especialidade",
    "en": "Specialty",
    "es": "Especialidad"
  },
  {
    "ptBr": "Administração",
    "en": "Administration",
    "es": "Administración"
  },
  {
    "ptBr": "Agronomia",
    "en": "Agronomy",
    "es": "Agronomía"
  },
  {
    "ptBr": "Arquitetura e Urbanismo",
    "en": "Architecture and Urbanism",
    "es": "Arquitectura y Urbanismo"
  },
  {
    "ptBr": "Arquivologia",
    "en": "Archival Science",
    "es": "Archivología"
  },
  {
    "ptBr": "Artes Visuais",
    "en": "Visual Arts",
    "es": "Artes Visuales"
  },
  {
    "ptBr": "Biblioteconomia",
    "en": "Library Science",
    "es": "Bibliotecología"
  },
  {
    "ptBr": "Biologia",
    "en": "Biology",
    "es": "Biología"
  },
  {
    "ptBr": "Biomedicina",
    "en": "Biomedicine",
    "es": "Biomedicina"
  },
  {
    "ptBr": "Biotecnologia",
    "en": "Biotechnology",
    "es": "Biotecnología"
  },
  {
    "ptBr": "Ciência da Computação",
    "en": "Computer Science",
    "es": "Ciencia de la Computación"
  },
  {
    "ptBr": "Ciências Sociais",
    "en": "Social Sciences",
    "es": "Ciencias Sociales"
  },
  {
    "ptBr": "Comunicação Social",
    "en": "Social Communication",
    "es": "Comunicación Social"
  },
  {
    "ptBr": "Contabilidade",
    "en": "Accounting",
    "es": "Contabilidad"
  },
  {
    "ptBr": "Dança",
    "en": "Dance",
    "es": "Danza"
  },
  {
    "ptBr": "Design",
    "en": "Design",
    "es": "Diseño"
  },
  {
    "ptBr": "Direito",
    "en": "Law",
    "es": "Derecho"
  },
  {
    "ptBr": "Economia",
    "en": "Economics",
    "es": "Economía"
  },
  {
    "ptBr": "Educação Física",
    "en": "Physical Education",
    "es": "Educación Física"
  },
  {
    "ptBr": "Engenharia Ambiental",
    "en": "Environmental Engineering",
    "es": "Ingeniería Ambiental"
  },
  {
    "ptBr": "Engenharia Civil",
    "en": "Civil Engineering",
    "es": "Ingeniería Civil"
  },
  {
    "ptBr": "Engenharia Elétrica",
    "en": "Electrical Engineering",
    "es": "Ingeniería Eléctrica"
  },
  {
    "ptBr": "Engenharia Física",
    "en": "Physical Engineering",
    "es": "Ingeniería Física"
  },
  {
    "ptBr": "Engenharia Hídrica",
    "en": "Water Engineering",
    "es": "Ingeniería Hidráulica"
  },
  {
    "ptBr": "Engenharia Mecânica",
    "en": "Mechanical Engineering",
    "es": "Ingeniería Mecánica"
  },
  {
    "ptBr": "Engenharia Metalúrgica",
    "en": "Metallurgical Engineering",
    "es": "Ingeniería Metalúrgica"
  },
  {
    "ptBr": "Engenharia Química",
    "en": "Chemical Engineering",
    "es": "Ingeniería Química"
  },
  {
    "ptBr": "Engenharia de Alimentos",
    "en": "Food Engineering",
    "es": "Ingeniería de Alimentos"
  },
  {
    "ptBr": "Engenharia de Computação",
    "en": "Computer Engineering",
    "es": "Ingeniería Informática"
  },
  {
    "ptBr": "Engenharia de Controle e Automação",
    "en": "Control and Automation Engineering",
    "es": "Ingeniería de Control y Automatización"
  },
  {
    "ptBr": "Engenharia de Energia",
    "en": "Energy Engineering",
    "es": "Ingeniería de Energía"
  },
  {
    "ptBr": "Engenharia de Materiais",
    "en": "Materials Engineering",
    "es": "Ingeniería de Materiales"
  },
  {
    "ptBr": "Engenharia de Minas",
    "en": "Mining Engineering",
    "es": "Ingeniería de Minas"
  },
  {
    "ptBr": "Engenharia de Produção",
    "en": "Production Engineering",
    "es": "Ingeniería de Producción"
  },
  {
    "ptBr": "Estatística",
    "en": "Statistics",
    "es": "Estadística"
  },
  {
    "ptBr": "Filosofia",
    "en": "Philosophy",
    "es": "Filosofía"
  },
  {
    "ptBr": "Física",
    "en": "Physics",
    "es": "Física"
  },
  {
    "ptBr": "Geografia",
    "en": "Geography",
    "es": "Geografía"
  },
  {
    "ptBr": "Geologia",
    "en": "Geology",
    "es": "Geología"
  },
  {
    "ptBr": "História",
    "en": "History",
    "es": "Historia"
  },
  {
    "ptBr": "História da Arte",
    "en": "Art History",
    "es": "Historia del Arte"
  },
  {
    "ptBr": "Letras",
    "en": "Literature",
    "es": "Letras"
  },
  {
    "ptBr": "Matemática",
    "en": "Mathematics",
    "es": "Matemáticas"
  },
  {
    "ptBr": "Medicina",
    "en": "Medicine",
    "es": "Medicina"
  },
  {
    "ptBr": "Veterinária",
    "en": "Veterinary",
    "es": "Veterinaria"
  },
  {
    "ptBr": "Museologia",
    "en": "Museology",
    "es": "Museología"
  },
  {
    "ptBr": "Música",
    "en": "Music",
    "es": "Música"
  },
  {
    "ptBr": "Nutrição",
    "en": "Nutrition",
    "es": "Nutrición"
  },
  {
    "ptBr": "Odontologia",
    "en": "Dentistry",
    "es": "Odontología"
  },
  {
    "ptBr": "Pedagogia",
    "en": "Pedagogy",
    "es": "Pedagogía"
  },
  {
    "ptBr": "Políticas Públicas",
    "en": "Public Policies",
    "es": "Políticas Públicas"
  },
  {
    "ptBr": "Química",
    "en": "Chemistry",
    "es": "Química"
  },
  {
    "ptBr": "Relações Internacionais",
    "en": "International Relations",
    "es": "Relaciones Internacionales"
  },
  {
    "ptBr": "Saúde Coletiva",
    "en": "Public Health",
    "es": "Salud Colectiva"
  },
  {
    "ptBr": "Serviço Social",
    "en": "Social Work",
    "es": "Trabajo Social"
  },
  {
    "ptBr": "Teatro",
    "en": "Theater",
    "es": "Teatro"
  },
  {
    "ptBr": "Zootecnia",
    "en": "Animal Science",
    "es": "Zootecnia"
  },
  {
    "ptBr": "Gerenciador",
    "en": "Manager",
    "es": "Gerente"
  },
  {
    "ptBr": "Profissional da área da saúde",
    "en": "Healthcare professional",
    "es": "Profesional de salud"
  },
  {
    "ptBr": "Outra",
    "en": "Other",
    "es": "Otro"
  },
  {
    "ptBr": "Estado do CRM",
    "en": "CRM state",
    "es": "Estado del CRM"
  },
  {
    "ptBr": "Acupuntura",
    "en": "Acupuncture",
    "es": "Acupuntura"
  },
  {
    "ptBr": "Alergia e imunologia",
    "en": "Allergy and immunology",
    "es": "Alergia e inmunología"
  },
  {
    "ptBr": "Anestesiologia",
    "en": "Anesthesiology",
    "es": "Anestesiología"
  },
  {
    "ptBr": "Angiologia",
    "en": "Angiology",
    "es": "Angiología"
  },
  {
    "ptBr": "Cardiologia",
    "en": "Cardiology",
    "es": "Cardiología"
  },
  {
    "ptBr": "Cirurgia cardiovascular",
    "en": "Cardiovascular surgery",
    "es": "Cirugía cardiovascular"
  },
  {
    "ptBr": "Cirurgia da mão",
    "en": "Hand surgery",
    "es": "Cirugía de mano"
  },
  {
    "ptBr": "Cirurgia de cabeça e pescoço",
    "en": "Head and neck surgery",
    "es": "Cirugía de cabeza y cuello"
  },
  {
    "ptBr": "Cirurgia do aparelho digestivo",
    "en": "Digestive system surgery",
    "es": "Cirugía del aparato digestivo"
  },
  {
    "ptBr": "Cirurgia geral",
    "en": "General surgery",
    "es": "Cirugía general"
  },
  {
    "ptBr": "Cirurgia oncológica",
    "en": "Oncological surgery",
    "es": "Cirugía oncológica"
  },
  {
    "ptBr": "Cirurgia pediátrica",
    "en": "Pediatric surgery",
    "es": "Cirugía pediátrica"
  },
  {
    "ptBr": "Cirurgia plástica",
    "en": "Plastic surgery",
    "es": "Cirugía plástica"
  },
  {
    "ptBr": "Cirurgia torácica",
    "en": "Thoracic surgery",
    "es": "Cirugía torácica"
  },
  {
    "ptBr": "Cirurgia vascular",
    "en": "Vascular surgery",
    "es": "Cirugía vascular"
  },
  {
    "ptBr": "Clínica médica",
    "en": "Internal medicine",
    "es": "Medicina interna"
  },
  {
    "ptBr": "Coloproctologia",
    "en": "Coloproctology",
    "es": "Coloproctología"
  },
  {
    "ptBr": "Dermatologia",
    "en": "Dermatology",
    "es": "Dermatología"
  },
  {
    "ptBr": "Endocrinologia e metabologia",
    "en": "Endocrinology and Metabolism",
    "es": "Endocrinología y Metabolismo"
  },
  {
    "ptBr": "Endoscopia",
    "en": "Endoscopy",
    "es": "Endoscopia"
  },
  {
    "ptBr": "Gastroenterologia",
    "en": "Gastroenterology",
    "es": "Gastroenterología"
  },
  {
    "ptBr": "Genética médica",
    "en": "Medical genetics",
    "es": "Genética médica"
  },
  {
    "ptBr": "Geriatria",
    "en": "Geriatrics",
    "es": "Geriatría"
  },
  {
    "ptBr": "Ginecologia e obstetrícia",
    "en": "Gynecology and Obstetrics",
    "es": "Ginecología y Obstetricia"
  },
  {
    "ptBr": "Hematologia e hemoterapia",
    "en": "Hematology and Hemotherapy",
    "es": "Hematología y Hemoterapia"
  },
  {
    "ptBr": "Homeopatia",
    "en": "Homeopathy",
    "es": "Homeopatía"
  },
  {
    "ptBr": "Infectologia",
    "en": "Infectology",
    "es": "Infectología"
  },
  {
    "ptBr": "Mastologia",
    "en": "Mastology",
    "es": "Mastología"
  },
  {
    "ptBr": "Medicina de emergência",
    "en": "Emergency medicine",
    "es": "Medicina de emergencia"
  },
  {
    "ptBr": "Medicina de família e comunidade",
    "en": "Family and Community Medicine",
    "es": "Medicina familiar y comunitaria"
  },
  {
    "ptBr": "Medicina do trabalho",
    "en": "Occupational medicine",
    "es": "Medicina laboral"
  },
  {
    "ptBr": "Medicina de tráfego",
    "en": "Traffic medicine",
    "es": "Medicina de tráfico"
  },
  {
    "ptBr": "Medicina esportiva",
    "en": "Sports medicine",
    "es": "Medicina deportiva"
  },
  {
    "ptBr": "Medicina física e reabilitação",
    "en": "Physical medicine and rehabilitation",
    "es": "Medicina física y rehabilitación"
  },
  {
    "ptBr": "Medicina intensiva",
    "en": "Intensive care medicine",
    "es": "Medicina intensiva"
  },
  {
    "ptBr": "Medicina legal e perícia médica",
    "en": "Legal medicine and medical expertise",
    "es": "Medicina legal y pericia médica"
  },
  {
    "ptBr": "Medicina nuclear",
    "en": "Nuclear medicine",
    "es": "Medicina nuclear"
  },
  {
    "ptBr": "Medicina preventiva e social",
    "en": "Preventive and social medicine",
    "es": "Medicina preventiva y social"
  },
  {
    "ptBr": "Nefrologia",
    "en": "Nephrology",
    "es": "Nefrología"
  },
  {
    "ptBr": "Neurocirurgia",
    "en": "Neurosurgery",
    "es": "Neurocirugía"
  },
  {
    "ptBr": "Neurologia",
    "en": "Neurology",
    "es": "Neurología"
  },
  {
    "ptBr": "Nutrologia",
    "en": "Nutrology",
    "es": "Nutrología"
  },
  {
    "ptBr": "Oftalmologia",
    "en": "Ophthalmology",
    "es": "Oftalmología"
  },
  {
    "ptBr": "Oncologia clínica",
    "en": "Clinical oncology",
    "es": "Oncología clínica"
  },
  {
    "ptBr": "Ortopedia e traumatologia",
    "en": "Orthopedics and traumatology",
    "es": "Ortopedia y traumatología"
  },
  {
    "ptBr": "Otorrinolaringologia",
    "en": "Otorhinolaryngology",
    "es": "Otorrinolaringología"
  },
  {
    "ptBr": "Patologia",
    "en": "Pathology",
    "es": "Patología"
  },
  {
    "ptBr": "Patologia clínica/medicina laboratorial",
    "en": "Clinical pathology/laboratory medicine",
    "es": "Patología clínica/medicina de laboratorio"
  },
  {
    "ptBr": "Pediatria",
    "en": "Pediatrics",
    "es": "Pediatría"
  },
  {
    "ptBr": "Pneumologia",
    "en": "Pneumology",
    "es": "Neumología"
  },
  {
    "ptBr": "Psiquiatria",
    "en": "Psychiatry",
    "es": "Psiquiatría"
  },
  {
    "ptBr": "Radiologia e diagnóstico por imagem",
    "en": "Radiology and diagnostic imaging",
    "es": "Radiología y diagnóstico por imagen"
  },
  {
    "ptBr": "Radioterapia",
    "en": "Radiation therapy",
    "es": "Radioterapia"
  },
  {
    "ptBr": "Reumatologia",
    "en": "Rheumatology",
    "es": "Reumatología"
  },
  {
    "ptBr": "Urologia",
    "en": "Urology",
    "es": "Urología"
  },
  {
    "ptBr": "Informe o nome da organização",
    "en": "Enter the organization name",
    "es": "Ingrese el nombre de la organización"
  },
  {
    "ptBr": "Visitação Médica",
    "en": "Medical visitation",
    "es": "Visita médica"
  },
  {
    "ptBr": "NAM - Núcleo de Assessoria Médica",
    "en": "NAM - Medical Advisory Unit",
    "es": "NAM - Unidad de Asesoría Médica"
  },
  {
    "ptBr": "WhatsApp",
    "en": "WhatsApp",
    "es": "WhatsApp"
  },
  {
    "ptBr": "Indicação",
    "en": "Referral",
    "es": "Indicación"
  },
  {
    "ptBr": "Instagram",
    "en": "Instagram",
    "es": "Instagram"
  },
  {
    "ptBr": "Facebook",
    "en": "Facebook",
    "es": "Facebook"
  },
  {
    "ptBr": "Linkedin",
    "en": "LinkedIn",
    "es": "LinkedIn"
  },
  {
    "ptBr": "E-mail marketing",
    "en": "Email marketing",
    "es": "Marketing por correo electrónico"
  },
  {
    "ptBr": "SMS",
    "en": "SMS",
    "es": "SMS"
  },
  {
    "ptBr": "Workplace",
    "en": "Workplace",
    "es": "Lugar de trabajo"
  },
  {
    "ptBr": "Google",
    "en": "Google",
    "es": "Google"
  },
  {
    "ptBr": "Outros",
    "en": "Others",
    "es": "Otros"
  },
  {
    "ptBr": "Como ficou sabendo do evento?",
    "en": "How did you hear about the event?",
    "es": "¿Cómo se enteró del evento?"
  },
  {
    "ptBr": "Gostaria de receber futuras comunicações sobre eventos?",
    "en": "Would you like to receive future communications about events?",
    "es": "¿Le gustaría recibir futuras comunicaciones sobre eventos?"
  },
  {
    "ptBr": " e seus parceiros meus contatos gerais, os quais s…rme Política de Privacidade disponível neste site",
    "en": " and its partners my general contacts, which wi…acy Policy available on this website",
    "es": " y sus socios mis contactos generales, que se r…Política de Privacidad disponible en este sitio web"
  },
  {
    "ptBr": "Finalizar inscrição",
    "en": "Complete registration",
    "es": "Completar registro"
  },
  {
    "ptBr": "Informe o nome da organização",
    "en": "Enter the organization name",
    "es": "Ingrese el nombre de la organización"
  },
  {
    "ptBr": "Outra?",
    "en": "Other?",
    "es": "¿Otro?"
  },
  {
    "ptBr": "Cadastre sua senha de acesso",
    "en": "Register your access password",
    "es": "Registre su contraseña de acceso"
  },
  {
    "ptBr": "Visitação Médica",
    "en": "Medical visitation",
    "es": "Visita médica"
  },
  {
    "ptBr": "NAM - Núcleo de Assessoria Médica",
    "en": "NAM - Medical Advisory Unit",
    "es": "NAM - Unidad de Asesoría Médica"
  },
  {
    "ptBr": "WhatsApp",
    "en": "WhatsApp",
    "es": "WhatsApp"
  },
  {
    "ptBr": "Indicação",
    "en": "Referral",
    "es": "Indicación"
  },
  { "ptBr": "Gerenciador", "en": "Manager", "es": "Gerente" },
  { "ptBr": "Para iniciar, informe seu email", "en": "To start, enter your email", "es": "Para comenzar, ingresa tu correo electrónico" },
  { "ptBr": "Outra", "en": "Another", "es": "Otro" },
  { "ptBr": "Outra?", "en": "Another?", "es": "¿Otro?" },
  { "ptBr": "Cadastre sua senha de acesso", "en": "Register your access password", "es": "Registra tu contraseña de acceso" },
  { "ptBr": "Visitação Médica", "en": "Medical Visitation", "es": "Visita Médica" },
  { "ptBr": "NAM - Núcleo de Assessoria Médica", "en": "NAM - Medical Advisory Unit", "es": "NAM - Unidad de Asesoría Médica" },
  { "ptBr": "WhatsApp", "en": "WhatsApp", "es": "WhatsApp" },
  { "ptBr": "Indicação", "en": "Referral", "es": "Indicación" },
  { "ptBr": "Instagram", "en": "Instagram", "es": "Instagram" },
  { "ptBr": "Facebook", "en": "Facebook", "es": "Facebook" },
  { "ptBr": "Linkedin", "en": "LinkedIn", "es": "LinkedIn" },
  { "ptBr": "E-mail marketing", "en": "Email marketing", "es": "Marketing por correo electrónico" },
  { "ptBr": "SMS", "en": "SMS", "es": "SMS" },
  { "ptBr": "Workplace", "en": "Workplace", "es": "Lugar de trabajo" },
  { "ptBr": "Google", "en": "Google", "es": "Google" },
  { "ptBr": "Outros", "en": "Others", "es": "Otros" },
  { "ptBr": "Como ficou sabendo do evento?", "en": "How did you hear about the event?", "es": "¿Cómo se enteró del evento?" },
  { "ptBr": "Gostaria de receber futuras comunicações sobre eventos?", "en": "Would you like to receive future communications about events?", "es": "¿Le gustaría recibir comunicaciones futuras sobre eventos?" },
  { "ptBr": " e seus parceiros meus contatos gerais, os quais s...rme Política de Privacidade disponível neste site", "en": "and its partners my general contacts, which...rme Privacy Policy available on this site", "es": "y sus socios mis contactos generales, los cuales...rme Política de Privacidad disponible en este sitio" },
  { "ptBr": "Finalizar inscrição", "en": "Finish registration", "es": "Finalizar registro" },
  { "ptBr": "Profissional da área da saúde", "en": "Healthcare professional", "es": "Profesional de la salud" },
  { "ptBr": "Enfermagem", "en": "Nursing", "es": "Enfermería" },
  { "ptBr": "Fonoaudiologia", "en": "Speech Therapy", "es": "Fonoaudiología" },
  { "ptBr": "Psicologia", "en": "Psychology", "es": "Psicología" },
  { "ptBr": "Fisioterapia", "en": "Physiotherapy", "es": "Fisioterapia" },
  { "ptBr": "Farmácia", "en": "Pharmacy", "es": "Farmacia" },
  { "ptBr": "Terapia Ocupacional", "en": "Occupational Therapy", "es": "Terapia Ocupacional" },
  { "ptBr": "Área", "en": "Area", "es": "Área" },
  { "ptBr": "Acupuntura", "en": "Acupuncture", "es": "Acupuntura" },
  { "ptBr": "Alergia e imunologia", "en": "Allergy and Immunology", "es": "Alergia e inmunología" },
  { "ptBr": "Anestesiologia", "en": "Anesthesiology", "es": "Anestesiología" },
  { "ptBr": "Angiologia", "en": "Angiology", "es": "Angiología" },
  { "ptBr": "Cardiologia", "en": "Cardiology", "es": "Cardiología" },
  { "ptBr": "Cirurgia cardiovascular", "en": "Cardiovascular Surgery", "es": "Cirugía cardiovascular" },
  {
    ptBr: 'Acadêmico de Medicina',
    en: 'Medical Student',
    es: 'Estudiante de medicina',
  },
  {
    ptBr: 'Médico',
    en: 'Doctor',
    es: 'Doctor',
  },
  {
    ptBr: 'Médico residente',
    en: 'Resident doctor',
    es: 'Médico residente',
  },
  {
    ptBr: 'Acadêmicos de outras áreas da saúde',
    en: 'Academics from other areas of health',
    es: 'Académicos de otras áreas de la salud',
  },
  {
    ptBr: 'Demais profissionais da área da saúde',
    en: 'Other health professionals',
    es: 'Otros profesionales de la salud',
  },
  {
    ptBr: 'Não',
    en: 'No',
    es: 'No',
  },
  {
    ptBr: 'Sim',
    en: 'Yes',
    es: 'Si',
  },
  {
    ptBr: 'VOLTAR PARA O WEBSITE',
    en: 'BACK TO WEBSITE',
    es: 'VOLVER AL SITIO WEB',
  },
  {
    ptBr: 'Informe o email de quem lhe indicou.',
    en: 'Enter the email of the person who referred you.',
    es: 'Ingrese el correo electrónico de la persona que lo refirió.',
  },

  {
    ptBr: 'Informe o email de quem lhe indicou.',
    en: 'Enter the email of the person who referred you.',
    es: 'Ingrese el correo electrónico de la persona que lo refirió.',
  },
  {
    ptBr: 'Alguém lhe indicou o evento?',
    en: 'Did someone tell you about the event?',
    es: '¿Alguien te contó sobre el evento?',
  },
  {
    ptBr: 'Voltar para o website',
    en: 'Back to website',
    es: 'Volver al sitio web',
  },
  {
    ptBr: 'Acredito que você irá gostar de participar desse evento! Inscreva-se pelo link: ',
    en: 'I believe you will enjoy participating in this event! Sign up via the link:',
    es: '¡Creo que disfrutarás participando en este evento! Regístrese a través del enlace:',
  },
  {
    ptBr: 'Preparando seu cadastro...',
    en: 'Preparing your registration...',
    es: 'Preparando su registro ...',
  },
  {
    ptBr: 'Como ficou sabendo?',
    en: 'How did you find out about the event?',
    es: '¿Cómo se enteró del evento?',
  },

  {
    ptBr: 'Ao me cadastrar, afirmo que li a',
    en: 'When registering, I declare that I read the',
    es: 'Al registrarme, afirmo que leí el',
  },


  {
    ptBr: 'Categoria',
    en: 'Category',
    es: 'Categoría',
  },
  {
    ptBr: 'Categoria',
    en: 'Category',
    es: 'Categoría',
  },

  {
    ptBr: 'CPF (Somente brasileiros)',
    en: 'CPF (Brazilians only)',
    es: 'CPF (solo brasileños)',
  },

  {
    ptBr: 'Profissão',
    en: 'Profession',
    es: 'Profesión',
  },
  {
    ptBr: 'Não autorizo o compartilhamento dos meus dados',
    en: 'I do not authorize the sharing of my data',
    es: 'Soy consciente de que estoy proporcionando',
  },

  {
    ptBr: 'Estou ciente de que estou fornecendo à ',
    en: `I am aware that I am providing the `,
    es: 'Soy consciente de que estoy proporcionando',
  },
  {
    ptBr: ' e seus parceiros meus contatos gerais, os quais são dados pessoais protegidos pela Lei Geral de Proteção de Dados brasileira, para gestão de relacionamento e marketing da empresa, conforme Política de Privacidade disponível neste site.',
    en: ` and their partners, my general contacts, which are personal data protected by the Brazilian General Data Protection Law, for the company's relationship management and marketing, according to the Privacy Policy available on this website.`,
    es: ' y sus socios, mis contactos generales, que son datos personales protegidos por la Ley General de Protección de Datos de Brasil, para la gestión de relaciones y marketing de la empresa, de acuerdo con la Política de Privacidad disponible en este sitio web.',
  },
  {
    ptBr: 'Não autorizo o compartilhamento dos meus dados',
    en: 'I do not authorize the sharing of my data',
    es: 'No autorizo ​​el intercambio de mis datos.',
  },


  {
    ptBr: 'Sim, eu autorizo o compartilhamento dos meus dados',
    en: 'Yes, I authorize the sharing of my data',
    es: 'Sí, autorizo ​​el intercambio de mis datos.',
  },
  {
    ptBr: 'O limite de participantes desta sala foi definido pelo coordenador da atividade, em função da metodologia. Caso esta sala não tenha mais capacidade, temos atividades simultânea sem restrição de público.',
    en: 'The limit of participants in this room was defined by the activity coordinator, depending on the methodology. If this room no longer has capacity, we have simultaneous activities with no audience restriction.',
    es: 'El límite de participantes en esta sala fue definido por el coordinador de la actividad, en función de la metodología. Si esta sala ya no tiene capacidad, tenemos actividades simultáneas sin restricción de audiencia.',
  },
  {
    ptBr: 'Vagas esgotadas',
    en: 'The room is full',
    es: 'Vacantes agotadas',
  },
  {
    ptBr: 'Caso enfrente algum problema, clique no botão de suporte técnico',
    en: 'If you experience any problems, click on the technical support button',
    es: 'Si tiene algún problema, haga clic en el botón de soporte técnico',
  },
  {
    ptBr: 'Atividade encerrada',
    en: 'Activity closed',
    es: 'Actividad cerrada',
  },
  {
    ptBr: 'A atividade será aberta até 15 minutos antes do início',
    en: 'The activity will open up to 15 minutes before the start.',
    es: 'La actividad se abrirá hasta 15 minutos antes del inicio.',
  },
  {
    ptBr: 'Acessar sala',
    en: 'Access room',
    es: 'Sala abierta',
  },
  {
    ptBr: 'Programação encerrada',
    en: 'Schedule closed',
    es: 'Horario cerrado',
  },
  {
    ptBr: 'Atualizar página',
    en: 'Refresh page',
    es: 'Actualizar página',
  },
  {
    ptBr: 'Aguardando iniciar',
    en: 'Awaiting start',
    es: 'Esperando el comienzo',
  },
  {
    ptBr: 'Sala de Audio e Vídeo',
    en: 'Audio and Video Room',
    es: 'Sala de audio y video',
  },
  {
    ptBr: 'Se faltam menos de 15 minutos para a atividade e a sala não abriu, clique no botão atualizar página.',
    en: 'If the activity is less than 15 minutes away and the room has not opened, click the refresh page button.',
    es: 'Si faltan menos de 15 minutos para la actividad y la sala no se ha abierto, haga clic en el botón Actualizar página.',
  },
  {
    ptBr: 'A sala tem um limite de 500 pessoas.',
    en: 'The room is limited to 500 people.',
    es: 'La habitación está limitada a 500 personas.',
  },
  {
    ptBr: 'Esta sala será aberta 15 minutos antes da atividade.',
    en: 'This room will open 15 minutes before the activity.',
    es: 'Esta sala se abrirá 15 minutos antes de la actividad.',
  },
  {
    ptBr: 'Mostra Cultural',
    en: 'Cultural exhibition',
    es: 'Exposición cultural',
  },
  {
    ptBr: 'Secretaria do evento',
    en: 'Event secretary',
    es: 'Secretaria de eventos',
  },
  {
    ptBr: 'Comentários',
    en: 'Comments',
    es: 'Comentarios',
  },
  {
    ptBr: 'Enviar mensagem',
    en: 'Send Message',
    es: 'Enviar mensaje',
  },
  {
    ptBr: 'Título',
    en: 'Title',
    es: 'Título',
  },
  {
    ptBr: 'Autores',
    en: 'Authors',
    es: 'Autores',
  },
  {
    ptBr: '19 de agosto - quinta-feira',
    en: "August 19th - Thursday",
    es: '19 de agosto - jueves',
  },
  {
    ptBr: '20 de agosto - sexta-feira',
    en: "August 20th - Friday",
    es: '20 de agosto - viernes',
  },
  {
    ptBr: '21 de agosto - sábado',
    en: "August 21st - Saturday",
    es: '21 de agosto - sábado',
  },
  {
    ptBr: '22 de agosto - domingo',
    en: "august 22 - sunday",
    es: '22 de agosto - domingo',
  },
  {
    ptBr: 'Sala',
    en: "Conference room",
    es: 'Sala de conferencias',
  },
  {
    ptBr: 'Horário de Brasília',
    en: "Brasilia time",
    es: 'Hora de Brasilia',
  },
  {
    ptBr: 'Pesquisar',
    en: "Search",
    es: 'Buscar',
  },
  {
    ptBr: 'Todos',
    en: "All",
    es: 'Todos',
  },
  {
    ptBr: 'Eixo Temático',
    en: "Category",
    es: 'Tema',
  },
  {
    ptBr: 'Escreva para pesquisar',
    en: "Write to search",
    es: 'Escribir para buscar',
  },
  {
    ptBr: 'Escreva aqui a sua mensagem',
    en: "Write here your message",
    es: 'Escribe aquí tu mensaje',
  },
  {
    ptBr: 'Responder',
    en: "Reply",
    es: 'Respuesta',
  },
  {
    ptBr: 'Enviar',
    en: "Submit",
    es: 'Entregar',
  },
  {
    ptBr: 'Acessar sala',
    en: "Access room",
    es: 'Acceso',
  },
  {
    ptBr: '2. Caso enfrente algum problema, clique no botão de suporte técnico',
    en: "2. If you experience any problems, click on the technical support button",
    es: '2. Si tiene algún problema, haga clic en el botón de soporte técnico',
  },
  {
    ptBr: '1. Clique no botão "Acessar sala"',
    en: "1. Click on the Join Room button",
    es: '1. Haga clic en el botón "Unirse a la sala".',
  },
  {
    ptBr: 'Instruções',
    en: "Instructions",
    es: 'Instrucciones',
  },
  {
    ptBr: '19.08',
    en: "08/19",
    es: '19.08',
  },
  {
    ptBr: '20.08',
    en: "08/20",
    es: '20.08',
  },
  {
    ptBr: '21.08',
    en: "08/21",
    es: '21.08',
  },
  {
    ptBr: '22.08',
    en: "08/22",
    es: '22.08',
  },

  {
    ptBr: '19/08',
    en: "08/19",
    es: '19/08',
  },
  {
    ptBr: '20/08',
    en: "08/20",
    es: '20/08',
  },
  {
    ptBr: '21/08',
    en: "08/21",
    es: '21/08',
  },
  {
    ptBr: '22/08',
    en: "08/22",
    es: '22/08',
  },
  {
    ptBr: 'Finalizado',
    en: "Finished",
    es: 'Terminado',
  },
  {
    ptBr: 'Finalizado',
    en: "Finished",
    es: 'Terminado',
  },
  {
    ptBr: 'Ao vivo',
    en: "Live",
    es: 'En vivo',
  },
  {
    ptBr: 'Em breve',
    en: "Soon",
    es: 'Pronto',
  },

  {
    ptBr: 'Todos',
    en: "Todos",
    es: 'TODAS LAS CONFERENCIAS',
  },
  {
    ptBr: 'TODAS AS SALAS',
    en: "ALL ROOMS",
    es: 'TODAS LAS CONFERENCIAS',
  },
  {
    ptBr: 'FAVORITOS',
    en: "FAVORITES",
    es: 'FAVORITOS',
  },

  {
    ptBr: 'SAIR',
    en: "LOG OUT",
    es: 'SALIR',
  },

  {
    ptBr: 'PALESTRAS',
    en: "CONFERENCE ROOM",
    es: 'AREA DE CONFERENCIAS',
  },

  {
    ptBr: 'TRABALHOS',
    en: "POSTERS",
    es: 'CARTELES',
  },


  {
    ptBr: 'PROGRAMAÇÃO',
    en: "SCHEDULE",
    es: 'CALENDARIO',
  },

  {
    ptBr: 'E-mail, CPF (somente números) ou Passaporte',
    en: "E-mail, CPF (numbers only) or Passport",
    es: 'Correo electrónico, CPF (solo números) o pasaporte',
  },
  {
    ptBr: 'Voltar para login',
    en: "Back to login",
    es: 'Volver',
  },
  {
    ptBr: 'JÁ SOU INSCRITO',
    en: "I'm already subscribed",
    es: 'Ya estoy suscrito',
  },

  {
    ptBr: 'Organização',
    en: "Organization",
    es: 'Organización',
  },
  {
    ptBr: 'Apoio',
    en: "Collaborators",
    es: 'Colaboradores',
  },
  {
    ptBr: 'Patrocinadores',
    en: "Sponsors",
    es: 'Patrocinadores',
  },

  {
    ptBr: 'Vamos caminhar juntos para a excelência',
    en: "Let's walk together towards excellence",
    es: 'Caminemos juntos hacia la excelencia',
  },
  {
    ptBr: 'Inscreva seu centro!',
    en: "Register your center!",
    es: 'Postula tu centro',
  },

  {
    ptBr: 'Como inscrever o seu AVC Center - passo a passo',
    en: "How to register your AVC Center - step by step",
    es: 'Como postular su Centro de AVC - paso a paso',
  },

  {
    ptBr: 'Abertura da plataforma para aplicativos',
    en: "Opening the platform for applications",
    es: 'Apertura de la plataforma para solicitudes',
  },
  {
    ptBr: 'Compra realizada com sucesso!',
    en: "Purchase successful!",
    es: 'Compra exitosa!',
  },
  {
    ptBr: 'Finalizar Compra',
    en: "Checkout",
    es: 'Revisa',
  },
  {
    ptBr: 'Parcelas',
    en: "installments",
    es: 'Cuotas',
  },
  {
    ptBr: 'Validade',
    en: "Validity (DD/MM)",
    es: 'Validad',
  },
  {
    ptBr: 'Nome no Cartão',
    en: "Credit Card Name",
    es: 'Nombre en la tarjeta',
  },
  {
    ptBr: 'Número do Cartão',
    en: "Credit Card Number",
    es: 'Numero de tarjeta',
  },
  {
    ptBr: 'Emitir como',
    en: "Issue as",
    es: 'Emitir como',
  },
  {
    ptBr: 'Entidade / Pessoa Jurídica',
    en: "Company",
    es: 'Entidad / Entidad legal',
  },
  {
    ptBr: 'Pessoa Física',
    en: "Person",
    es: 'Persona física',
  },
  {
    ptBr: 'Revisar meu Cadastro',
    en: "Update my profile",
    es: 'Hola, estoy finalizando la compra el',
  },
  {
    ptBr: 'Olá, estou finalizando a compra no',
    en: "Hello, I'm finalizing the purchase on",
    es: '',
  },


  {
    ptBr: 'Ou no link abaixo',
    en: 'Or the link below',
    es: 'O el enlace de abajo',
  },
  {
    ptBr: 'Clique no botão abaixo para acessar o boleto.',
    en: 'Click the button below to access the boleto.',
    es: 'Haga clic en el botón de abajo para acceder al boleto.',
  },
  {
    ptBr: 'Erro',
    en: 'Error',
    es: 'Error',
  },

  {
    ptBr: 'Erro ao efetuar pagamento',
    en: 'Error making payment',
    es: 'Error al realizar el pago',
  },
  {
    ptBr: 'Para acessar a plataforma, clique no botão abaixo',
    en: 'To access the platform, click the button below',
    es: 'Para acceder a la plataforma, haga clic en el botón de abajo',
  },
  {
    ptBr: 'Sua compra foi confirmada!',
    en: 'Your purchase has been confirmed!',
    es: '¡Tu compra ha sido confirmada!',
  },
  {
    ptBr: 'Verifique o número do seu cartão',
    en: 'Check your credit card number',
    es: 'Verifique su número de tarjeta',
  },
  {
    ptBr: 'Cartão inválido',
    en: 'Invalid Credit card',
    es: 'Tarjeta no valida',
  },
  {
    ptBr: 'Cartão de Crédito',
    en: 'Credit card',
    es: 'Tarjeta de crédito',
  },
  {
    ptBr: 'Carregando',
    en: 'Loading',
    es: 'Cargando',
  },
  {
    ptBr: 'Boleto',
    en: 'Bank slip',
    es: 'Billete',
  },

  {
    ptBr: 'Suporte Técnico',
    en: 'Technical support',
    es: 'Soporte técnico',
  },

  {
    ptBr: 'Forma de Pagamento',
    en: 'Payment Method',
    es: 'Forma de pago',
  },


  {
    ptBr: 'Login ou senha inválidos',
    en: 'Invalid email or password',
    es: 'Error de autenticación',
  },

  {
    ptBr: 'Erro na autenticação',
    en: 'Authentication error',
    es: '',
  },


  {
    ptBr: 'Obrigatório',
    en: 'Mandatory',
    es: 'Pasaporte',
  },

  {
    ptBr: 'Passaporte',
    en: 'Passport Number',
    es: '',
  },

  {
    ptBr: 'Outro',
    en: 'Other',
    es: 'Continuar',
  },

  {
    ptBr: 'Continuar',
    en: 'Next',
    es: '',
  },


  {
    ptBr: 'Senha',
    en: 'Password',
    es: 'Contraseña',
  },


  {
    ptBr: 'Bairro',
    en: 'Neighborhood',
    es: 'Barrio',
  },

  {
    ptBr: 'Número / Apartamento',
    en: 'Number',
    es: 'Dirección',
  },

  {
    ptBr: 'Endereço',
    en: 'Address',
    es: '',
  },
  {
    ptBr: 'CEP',
    en: 'Zipcode',
    es: 'Código postal',
  },
  {
    ptBr: 'CNPJ da Empresa / Entidade',
    en: 'Company Identification Number',
    es: 'Número de identificación de la empresa',
  },
  {
    ptBr: 'Empresa / Entidade',
    en: 'Company / Entity',
    es: 'Empresa / Entidad',
  },


  {
    ptBr: 'Data de Nascimento (DD/MM/AAAA)',
    en: 'Date of birth (DD/MM/YYYY)',
    es: 'Fecha de nacimiento (dd / mm / aaaa)',
  },

  {
    ptBr: 'Estado',
    en: 'State / Province / Region',
    es: 'Estado / Provincia / Región',
  },

  {
    ptBr: 'Você selecionou o idioma inglês e o país Brasil.',
    en: 'You have selected the English language and the country Brazil.',
    es: 'Ha seleccionado el idioma inglés y el país Brasil.',
  },

  {
    ptBr: 'Para alterar os campos para estrangeiros, selecione outro país.',
    en: 'To change the fields to foreigners, select another country.',
    es: 'Para cambiar los campos a extranjeros, seleccione otro país.',
  },


  {
    ptBr: 'Voltar',
    en: 'Back',
    es: 'Regresar',
  },

  {
    ptBr: 'Novo cadastro',
    en: 'New register',
    es: 'Nuevo registro',
  },

  {
    ptBr: 'Já tenho cadastro',
    en: 'I already have registration',
    es: 'Ya tengo registro',
  },

  {
    ptBr: 'Selecione a Opção',
    en: 'Select Option',
    es: 'Seleccionar opción',
  },
  {
    ptBr: 'Sou prescritor',
    en: 'I\'m a prescriber',
    es: '',
  },
  {
    ptBr: 'Tipo de Documento (Passaporte / Outro)',
    en: 'Document Type (Passport / Other)',
    es: 'Tipo de documento (pasaporte / otro)',
  },

  {
    ptBr: 'Tipo de documento',
    en: 'Document type',
    es: 'Tipo de documento',
  },
  {
    ptBr: 'Nº do documento',
    en: 'Document number',
    es: 'Número del Documento',
  },
  {
    ptBr: 'Pais',
    en: 'Country',
    es: 'País',
  },

  {
    ptBr: 'Inscrição',
    en: 'Registration',
    es: 'Inscripción',
  },
  {
    ptBr: 'CPF',
    en: 'CPF (Brazilian Document Number)',
    es: 'CPF (Número de documento brasileño)',
  },

  {
    ptBr: 'Público Geral',
    en: 'General Audiences',
    es: 'Audiencia general',
  },

  {
    ptBr: 'Certificado do Evento',
    en: 'Event certificate',
    es: 'certificado de evento',
  },
  {
    ptBr: 'Acesso online as Palestras do Evento',
    en: 'Online access to the Event',
    es: 'Acceso en línea a las conferencias del evento',
  },
  {
    ptBr: 'Benefícios',
    en: 'Benefits',
    es: 'Beneficios',
  },
  {
    ptBr: 'Valor',
    en: 'Price',
    es: 'Valor',
  },
  {
    ptBr: 'Acesso Digital',
    en: 'Digital Access',
    es: 'Acceso digital',
  },
  {
    ptBr: 'Inscrição',
    en: 'Subscription',
    es: 'Inscripción',
  },
  {
    ptBr: 'Selecionar idioma do Vídeo',
    en: 'Select Video language',
    es: 'Seleccione el idioma del video',
  },
  {
    ptBr: 'Suas perguntas serão visualizadas por nossos moderadores e encaminhadas aos palestrantes.',
    en: 'Your questions will be viewed by our moderators and forwarded to the speakers.',
    es: 'Nuestros moderadores verán sus preguntas y las remitirán a los ponentes.',
  },

  {
    ptBr: 'Perguntas',
    en: 'Questions',
    es: 'Preguntas',
  },


  {
    ptBr: 'Ao me cadastrar, afirmo que li e aceito a',
    en: 'When I register, I affirm that I have read and accept the',
    es: 'Cuando me registro, afirmo que he leído y acepto el',
  },
  {
    ptBr: 'Política de Privacidade',
    en: 'Privacy Policy',
    es: 'Política de privacidad',
  },
  {
    ptBr: 'desta plataforma.',
    en: 'of this platform.',
    es: 'de esta plataforma.',
  },

  {
    ptBr: 'NOVA INSCRIÇÃO',
    en: 'NEW REGISTRATION',
    es: 'NUEVO REGISTRO',
  },





  {
    ptBr: 'INSCRIÇÃO | ACESSAR',
    en: 'REGISTER | SIGNIN IN',
    es: '',
  },

  {
    ptBr: 'INSCREVA-SE!',
    en: 'REGISTER',
    es: '¡SUSCRIBIRSE!',
  },
  {
    ptBr: 'ACESSE!',
    en: 'SIGNIN IN',
    es: '¡ACCESO!',
  },

  {
    ptBr: 'Todos os direitos reservados - 2020',
    en: 'All rights reserved - 2020',
    es: '',
  },

  {
    ptBr: 'ACESSE!',
    en: 'SIGIN IN',
    es: '',
  },
  {
    ptBr: 'Dias',
    en: 'Days',
    es: 'Dias',
  },
  {
    ptBr: 'Horas',
    en: 'Hours',
    es: 'Horas',
  },
  {
    ptBr: 'Minutos',
    en: 'Minutes',
    es: 'Minutos',
  },
  {
    ptBr: 'Segundos',
    en: 'Seconds',
    es: 'Segundos',
  },
  {
    ptBr: 'Inscrição Confirmada',
    en: 'Confirmed Registration',
    es: 'Registro confirmado',
  },
  {
    ptBr: 'Retorne a este website no dia do evento para participar do evento.',
    en: 'Return to this website on the day of the event to attend the event.',
    es: 'Regrese a este sitio web el día del evento para participar en el evento.',
  },
  {
    ptBr: 'Você foi desconectado',
    en: 'You have been disconnected',
    es: 'Has sido desconectado',
  },
  {
    ptBr: 'Seu token expirou ou outra pessoa logou com o seu acesso.',
    en:
      'Your token has expired or someone else has logged in with your access.',
    es: 'Su token ha caducado o alguien más ha iniciado sesión con su acceso.',
  },
  {
    ptBr: 'Preparando seu cadastro...',
    en: 'Preparing your registration ...',
    es: 'Preparando su registro ...',
  },
  {
    ptBr: 'Sua inscrição na',
    en: 'Your subscription to',
    es: 'Su suscripción a',
  },
  {
    ptBr: 'já está confirmada',
    en: 'is already confirmed',
    es: 'ya esta confirmado',
  },
  {
    ptBr: 'Aproveite e indique para seus amigos!',
    en: 'Enjoy and invite your friends!',
    es: '¡Disfruta y cuéntaselo a tus amigos!',
  },

  {
    ptBr: 'Olá! Acabei de me inscrever no ',
    en: 'Hi! I just signed up for ',
    es: '¡Hola! Me acabo de registrar para ',
  },


  {
    ptBr:
      'Acredito que você irá gostar de participar desse evento! Inscreva-se pelo link:',
    en:
      'I believe you will enjoy participating in this event! Subscribe by link:',
    es: '¡Creo que disfrutarás participando en este evento! Regístrese por el enlace:',
  },

  {
    ptBr: 'Confirmar sua vaga',
    en: 'Confirm your presence',
    es: 'Confirma tu presencia',
  },

  {
    ptBr: 'Agronegócio e Infraestrutura para o desenvolvimento da Amazônia.',
    en: 'Agribusiness and Infrastructure for the development of the Amazon.',
    es: 'Agronegocio e Infraestructura para el desarrollo de la Amazonía',
  },
  {
    ptBr:
      'AGRONEGÓCIOS E GERAÇÃO DE RIQUEZAS, REGULARIZAÇÃO FUNDIÁRIA E PROJETOS PARA A CONSERVAÇÃO DA AMAZÔNIA',
    en:
      'AGRIBUSINESS AND WEALTH GENERATION, LAND REGULARIZATION AND PROJECTS FOR THE CONSERVATION OF THE AMAZON',
    es:
      'AGRONEGOCIOS Y GENERACIÓN DE RIQUEZAS, REGULARIZACIÓN AGRARIA Y PROYECTOS PARA LA CONSERVACIÓN DE LA AMAZONÍA',
  },

  {
    ptBr:
      'INFRAESTRUTURA, ENERGIA LIMPA E A MATRIZ ENERGÉTICA BRASILEIRA: INVESTIMENTOS E O DESENVOLVIMENTO DA AMAZÔNIA',
    en:
      'INFRASTRUCTURE, CLEAN ENERGY AND THE BRAZILIAN ENERGY MATRIX: INVESTMENTS AND THE DEVELOPMENT OD THE AMAZON',

    es:
      'INFRAESTRUCTURA, ENERGIA LIMPIA Y LA MATRIZ ENERGÉTICA BRASILEÑA: INVERSIONES Y EL DESARROLLO DE LA AMAZONIA',
  },

  {
    ptBr: 'PAINEL 1 – 11H GMT - 3',
    en: 'PANEL 1 - 11AM GMT - 3',
    es: 'PANEL 1 – 11’00 GMT - 3',
  },

  {
    ptBr: 'PAINEL 2 – 14H30 GMT – 3',
    en: 'PANEL 2 - 2:30PM GMT - 3',
    es: 'PANEL 2 – 14’30 GMT – 3',
  },
  {
    ptBr: 'PAINEL 3 – 16H30 – GMT – 3',
    en: 'PANEL 3 - 4:30PM - GMT - 3',
    es: 'PANEL 3 – 16’30 – GMT – 3',
  },

  {
    ptBr: 'Amazônia Invisível e Amazônia Real',
    en: 'The Invisible Amazon and the Real Amazon',
    es: 'Amazonia Invisible y Amazonia Real',
  },
  {
    ptBr: 'A Diversidade dos Povos, Comunidades e Cidades',
    en: 'The Diversity of Peoples, Communities and Cities',
    es: 'La Diversidad de los Pueblos, Comunidades y Ciudades',
  },

  {
    ptBr:
      'PRODUÇÃO AGRÍCOLA, PECUÁRIA E ABASTECIMENTO - OS DESAFIOS DA PRODUÇÃO E A CONSERVAÇÃO DA FLORESTA',
    en:
      'AGRICULTURAL PRODUCTION, LIVESTOCK AND FOOD SUPPLY - CHALLENGES FOR THE PRODUCTION AND FOREST CONSERVATION',
    es:
      'PRODUCCIÓN AGRÍCOLA, PECUARIA Y ABASTECIMIENTO – LOS RETOS DE LA PRODUCCIÓN Y LA CONSERVACIÓN DEL BOSQUE',
  },
  {
    ptBr:
      'SEGURANÇA ALIMENTAR: O BRASIL NO CENÁRIO GLOBAL E AS OPORTUNIDADES PARA A REGIÃO AMAZÔNICA',
    en:
      'FOOD SECURITY BRAZIL IN THE GLOBAL SCENARIO AND OPPORTUNITIES FOR THE AMAZON REGION',
    es:
      'SEGURIDAD ALIMENTARIA: BRASIL EN EL ESCENARIO GLOBAL Y LAS OPORTUNIDADES PARA LA REGIÓN AMAZÓNICA',
  },
  {
    ptBr:
      'OS DESAFIOS E CAMINHOS PARA A REGULARIZAÇÃO FUNDIÁRIA E O DESENVOLVIMENTO DA AMAZÔNIA',
    en:
      'CHALLENGES AND PATHWAYS FOR LAND REGULARIZATION AND THE DEVELOPMENT OF THE AMAZON',
    es:
      'LOS RETOS Y LOS CAMINOS PARA LA REGULARIZACIÓN AGRARIA Y EL DESARROLLO DE LA AMAZONÍA',
  },
  {
    ptBr:
      'OS DESAFIOS DE INFRAESTRUTURA BÁSICA E TECNOLÓGICA NA AMAZÔNIA: SANEAMENTO BÁSICO, LOGÍSTICA E CONECTIVIDADE',
    en:
      'CHALLENGES FOR BASIC AND TECHNOLOGICAL INFRASTRUCTURE IN THE AMAZON: BASIC SANITATION, LOGISTICS AND CONECTIVTY',
    es:
      'LOS RETOS DE INFRAESTRUTURA BÁSICA Y TECNOLÓGICA EN LA AMAZONÍA: SANEAMIENTO BÁSICO, LOGÍSTICA Y CONECTIVIDAD',
  },
  {
    ptBr:
      'ENERGIA LIMPA E A MATRIZ ENERGÉTICA BRASILEIRA: INVESTIMENTOS EM INFRAESTRUTURA E O DESENVOLVIMENTO DA AMAZÔNIA',
    en:
      'CLEAN ENERGY AND THE BRAZILIAN ENERGY MATRIX: INVESTMENTS IN INFRASTRUCTURE AND THE DEVELOPMENT OF THE AMAZON',
    es:
      'ENERGÍA LIMPIA Y LA MATRIZ ENERGÉTICA BRASILEÑA: INVERSIONES EN INFRAESTRUCTURA Y EL DESARROLLO DE LA AMAZONÍA',
  },
  {
    ptBr:
      '9h30 - Agronegócios e geração de riquezas, regularização fundiária e projetos para conservação da amazônia',
    en:
      '9h30 - Agronegócios e geração de riquezas, regularização fundiária e projetos para conservação da amazônia',
    es:
      '9h30 - Agronegócios e geração de riquezas, regularização fundiária e projetos para conservação da amazônia',
  },
  {
    ptBr:
      '14h30 - Infraestrutura e desenvolvimento sustentável da Amazônia, investimentos e reflexões sobre o futuro',
    en:
      '14h30 - Infraestrutura e desenvolvimento sustentável da Amazônia, investimentos e reflexões sobre o futuro',
    es:
      '14h30 - Infraestrutura e desenvolvimento sustentável da Amazônia, investimentos e reflexões sobre o futuro',
  },
  {
    ptBr: 'O campo Nome Completo é obrigatório',
    en: 'The Full Name Field is Required',
    es: 'Nombre Completo obligatorio',
  },
  {
    ptBr: 'Preencha sua Data de Nascimento',
    en: 'Fill in your Date of Birth',
    es: 'Introduzca su fecha de nacimiento',
  },
  {
    ptBr: 'Data de Nascimento',
    en: 'Date of Birth',
    es: 'Fecha de nacimiento',
  },
  {
    ptBr: 'Preencha um email válido',
    en: 'Fill in a Valid Email',
    es: 'Introduzca un email válido',
  },
  {
    ptBr: 'O campo Email é obrigatório',
    en: 'The Email Field is Required',
    es: 'el campo email es obligatorio',
  },
  {
    ptBr: 'Telefone',
    en: 'Telephone',
    es: '',
  },
  {
    ptBr: 'O campo Telefone é obrigatório',
    en: 'The Telephone Field is Required',
    es: 'el campo teléfono es obligatorio',
  },
  {
    ptBr: 'O campo Categoria é obrigatório',
    en: 'The Category Field is Required',
    es: 'el campo Categoría es obligatorio',
  },
  {
    ptBr: 'O campo Escolaridade é obrigatório',
    en: 'The Education Field is Required',
    es: 'el campo Educación es obligatorio',
  },
  {
    ptBr: 'O campo Empresa é obrigatório',
    en: 'The Company Field is Required',
    es: 'el campo Empresa es obligatorio',
  },
  {
    ptBr: 'O campo Cargo é obrigatório',
    en: 'The Job Position is Required',
    es: 'el campo Cargo es obligatorio',
  },
  {
    ptBr: 'O campo Cidade é obrigatório',
    en: 'The City Field is Required',
    es: 'el campo ciudad es obligatorio',
  },
  {
    ptBr: 'O campo UF é obrigatório',
    en: 'The UF is Required',
    es: 'el campo UF es obligatorio',
  },
  {
    ptBr: 'Erro ao gerar seu cadastro',
    en: 'Error when generating your registration',
    es: 'Error al generar su registro',
  },
  {
    ptBr: 'Preparando seu cadastro',
    en: 'Preparing your registration',
    es: 'Preparando su registro',
  },
  {
    ptBr: 'Cadastro realizado com sucesso!',
    en: 'Successful registration',
    es: '¡Inscripción realizada con éxito!',
  },
  { ptBr: 'ACESSAR', en: 'sign in', es: 'Acceso' },
  { ptBr: 'ACESSAR', en: 'SIGN IN', es: 'ACCESO' },
  {
    ptBr: 'Aproveite e indique para seus amigos!',
    en: 'Enjoy and tell your friends',
    es: '¡Aproveche y recomiende a sus amigos!',
  },
  {
    ptBr:
      'Olá! Acabei de me inscrever no Amazônia+21. Acredito que você irá gostar de participar desse evento: Amazônia+21 - Conectando Iniciativas para o Desenvolvimento Sustentável. É gratuito, 100% virtual e com muito conteúdo. Inscreva-se pelo link: https://amazonia21.encontrodigital.com.br/',
    en:
      'Hello! I have just signed up for Amazonia +21.I believe you will enjoy taking part in this event: Amazonia+21 - Connecting Initiatives for Sustainable Development. It is free, 100% virtual and with a lot of content. Subscribe by the link: https://amazonia21.encontrodigital.com.br/',
    es:
      '¡Hola! Acabo de inscribirme en Amazônia +21. Pienso que te gustara participar  de este evento: Amazônia+21. Juntando iniciativas para el Desenvolvimiento Sustentable. Es gratuito 100% virtual y con mucho contenido. Inscrívase en el link: https://amazonia21.encontrodigital.com.br/',
  },
  { ptBr: 'Compartilhe!', en: 'Share!', es: '¡Comparta!' },
  { ptBr: 'Nome Completo', en: 'Full Name', es: 'Nombre completo' },
  { ptBr: 'Empresa', en: 'Company', es: 'Empresa' },
  { ptBr: 'Cargo', en: 'Position', es: 'Cargo' },
  { ptBr: 'Celular', en: 'Mobile', es: 'Celular' },
  { ptBr: 'E-mail', en: 'Email', es: 'E-mail' },
  { ptBr: 'UF', en: 'UF', es: 'UF' },
  { ptBr: 'Cidade', en: 'City', es: 'Ciudad' },
  { ptBr: 'País', en: 'Country', es: 'País' },
  {
    ptBr: 'Como você soube do evento?',
    en: 'How did you hear about the event?',
    es: '¿Cómo te enteraste del evento?',
  },
  { ptBr: 'Escolaridade', en: 'Education', es: 'Educación' },
  {
    ptBr: 'Ensino Fundamental',
    en: 'Elementary School',
    es: 'Enseñanza Básica ',
  },
  {
    ptBr: 'Ensino Fundamental Incompleto',
    en: 'Incomplet Elementary School',
    es: 'Eseñanza básica incompleta',
  },
  {
    ptBr: 'Ensino Médio Incompleto',
    en: 'Incomplet High School',
    es: 'Eseñanza media incompleta',
  },
  { ptBr: 'Ensino Médio', en: 'High School', es: 'Eseñanza Media ' },
  {
    ptBr: 'Ensino Superior',
    en: 'University Education',
    es: 'Enseñanza Superior',
  },
  {
    ptBr: 'Ensino Superior Incompleto',
    en: 'Incomplete University Education',
    es: 'Enseñanza Superior Incompleta',
  },
  { ptBr: 'Pós Graduação', en: 'Postgraduate', es: 'Postgraduación' },
  { ptBr: 'Mestrado', en: 'Master degree', es: 'Máster' },
  { ptBr: 'Doutorado', en: 'Doctorate degree', es: 'Doctorado' },
  { ptBr: 'pós doutorado', en: 'Post Doctorate', es: 'Post-doctorado' },
  { ptBr: 'Setor', en: 'Sector', es: 'Sector' },
  { ptBr: 'Governo Federal', en: 'Federal Government', es: 'Gobierno Federal' },
  { ptBr: 'Governo Estadual', en: 'State Government', es: 'Gobierno Estatal' },
  {
    ptBr: 'Governo Municipal',
    en: 'Municipal Government',
    es: 'Gobierno Municipal',
  },
  { ptBr: 'Empresário', en: 'Entrepreneur', es: 'Empresario' },
  { ptBr: 'Executivo', en: 'Manager', es: 'Ejecutivo' },
  {
    ptBr: 'Entidades e Sindicatos',
    en: 'entities and unions',
    es: 'Entidades y Sindicatos',
  },
  { ptBr: 'Investidor', en: 'Investor', es: 'Inversor' },
  { ptBr: 'Pesquisador', en: 'Researcher', es: 'Investigador' },
  { ptBr: 'Professor', en: 'Professor', es: 'Profesor' },
  { ptBr: 'Estudante', en: 'Student', es: 'Estudiante' },
  { ptBr: 'Imprensa', en: 'Press', es: 'Prensa' },
  { ptBr: 'ONG – OSIP', en: 'NGO - OSIP', es: 'ONG-OSIP' },
  { ptBr: 'Cadastrar', en: 'Fill in', es: 'Registrarse' },
  { ptBr: 'CADASTRAR', en: 'FILL IN', es: 'REGISTRARSE' },
  {
    ptBr: 'Sua inscrição está confirmada',
    en: 'Your Subscription is confirmed',
    es: 'Tu inscripción está confirmada',
  },
  {
    ptBr: 'Retorne a esta página no dia do evento.',
    en: 'Return to this page on the day of the event',
    es: 'vuelva a esta página el día del evento',
  },
  { ptBr: 'Sair', en: 'Sign out', es: 'Salir' },
  {
    ptBr: 'Nenhum arquivo disponibilizado',
    en: 'No files available',
    es: 'ningún archivo disponible',
  },
  { ptBr: 'Arquivos de apoio', en: 'Backup files', es: 'Achivos de apoyo' },
  { ptBr: 'Pesquisar', en: 'Search', es: 'Investigar' },
  { ptBr: 'Listar Vídeos', en: 'List Videos', es: 'Enumerar videos' },
  {
    ptBr: 'Ocorreu um erro ao fazer login',
    en: 'There was an error signing in',
    es: 'Sucedió un error al registrarse',
  },
  {
    ptBr: 'Erro na autenticação',
    en: 'authentication error',
    es: 'Error de autentificación',
  },

  {
    ptBr: '4 A 6 DE NOVEMBRO',
    en: 'NOVEMBER 4-6',
    es: '4-6 DE NOVIEMBRE',
  },

  { ptBr: 'ACESSE', en: 'ACCESS', es: 'ACCEDA' },
  { ptBr: 'INSCREVA-SE', en: 'REGISTER', es: 'INSCRÍVASE' },
  {
    ptBr: 'VOLTAR PARA O WEBSITE',
    en: 'BACK TO WEBSITE',
    es: 'VOLVER LA PÁGINA WEB',
  },
  { ptBr: '26 DE AGOSTO', en: 'AUGUST 26TH', es: '26 DE AGOSTO' },
  { ptBr: '19 DE AGOSTO', en: 'AUGUST 19TH', es: '19 DE AGOSTO' },
  { ptBr: 'PRÓXIMO EVENTO', en: 'NEXT EVENTS:', es: 'Próximo Evento' },
  { ptBr: 'Recarregar vídeo', en: 'Reload video', es: 'Recargar video' },
  {
    ptBr: 'Amazonia+21 - Todos os direitos reservados – 2020',
    en: 'Amazonia+21 - All rights reserved - 2020',
    es: 'Amazônia +21 Todos los derechos reservados - 2020',
  },
  { ptBr: 'Inscrição', en: 'REGISTER', es: 'Inscripción' },
  { ptBr: 'Home', en: 'Home', es: 'Inicio' },
  { ptBr: 'Login', en: 'Login', es: 'Ingreso' },
  {
    ptBr: 'Preencha um email válido',
    en: 'Fill in a Valid Email',
    es: 'Introduzca un email válido',
  },
  {
    ptBr: 'Horário de Brasilia',
    en: 'Brasilia time',
    es: 'Hora de Brasilia',
  },
  {
    ptBr: 'Visão de Futuro, Oportunidades e Desafios para a Região Amazônica',
    es: 'Visión de Futuro, Oportunidades y Retos para la Región Amazónica',
    en: 'Future Vision, Opportunities and Challenges to the Amazon Region',
  },
  {
    ptBr:
      '9h - Alternativas de Funding para Negócios e Projetos de Alto Impacto na Amazônia',
    en:
      '9h - Funding Alternatives for High Impact Businesses and Projects in The Amazon',
    es:
      '9h - Alternativas de Financiación para Negocios y Proyectos de Alto Impacto en Amazonía',
  },
  {
    ptBr:
      '11h - Cooperação Internacional, Fomento e Mecanismos de Alavancagem para o Desenvolvimento da Região Amazônica',
    en:
      '11h - International Cooperation, Fostering, and Leverage Mechanisms for the Development of the Amazon Region ',
    es:
      '11h - Cooperación Internacional, Fomento y Mecanismos de Apalancamiento para el Desarrollo de la Región Amazónica',
  },
  {
    ptBr: 'JÁ SOU CADASTRADO',
    en: 'I AM ALREADY REGISTERED',
    es: 'YA ESTOY REGISTRADO',
  },
  {
    ptBr: 'Faça sua Pergunta',
    en: 'Ask your question',
    es: 'Haga su pregunta',
  },
  {
    ptBr: '+ Palestras',
    en: '+ Videos',
    es: '',
  },
  { ptBr: 'Informação opcional, obrigatório apenas para pagamentos por pessoa jurídica', en: 'Optional information, mandatory only for payments by legal entity', es: '' },
  { ptBr: 'Sair', en: 'Log out', es: 'Cerrar sesión' },
  { ptBr: 'Entrar', en: 'Login in', es: 'Entrar' },
  { ptBr: 'ENTRAR', en: 'LOGIN IN', es: 'ENTRAR' },
  { ptBr: 'Criar minha conta', en: 'Create my account', es: 'Crear mi cuenta' },
  { ptBr: 'CRIAR MINHA CONTA', en: 'CREATE MY ACCOUNT', es: 'CREAR MI CUENTA' },
  { ptBr: 'Carregando', en: 'Loading', es: 'Cargando' },
  { ptBr: 'Carregando...', en: 'Loading...', es: 'Cargando' },
  {
    ptBr: 'Inscrição realizada com sucesso!',
    en: 'Registration Successful!',
    es: '¡Inscripción exitosa!',
  },
  {
    ptBr: 'Cadastro realizado com sucesso!',
    en: 'Successful Registration!',
    es: '¡Registro exitoso!',
  },
  {
    ptBr: 'Adicionado com sucesso!',
    en: 'Added Successfully!',
    es: '¡Añadido con éxito!',
  },
  {
    ptBr: 'Removido com sucesso',
    en: 'Successfully removed',
    es: 'Eliminado con éxito',
  },
  {
    ptBr: 'Escreva sua mensagem',
    en: 'Write your message',
    es: 'Escriba su mensaje',
  },
  { ptBr: 'Enviar', en: 'Send', es: 'Enviar' },
];
