import React from 'react';

import {
  SearchComponent,
  ISearchComponent,
} from '../../core/components/SearchComponent';

import lineModules from '../../core/components/lineModules'
import { Container } from './styles';
import { useParams } from 'react-router-dom';

interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}
interface IParams {
  projectHash : string;
  eventHash : string;
}


const EventsPagesAdmin: React.FC = () => {
  const endpoint = '/events-pages';
  const title = 'Páginas Avulsas';

    const {projectHash, eventHash } = useParams<IParams>(); const schema: Record<string, any> = {
    _id: { ref: '_id', column: '_id', label: 'ID', show: false },
 
    title: { ref: 'title', column: 'title', label: 'Título' },
    url: { ref: 'url', column: 'url', label: 'URL' },
    description: { ref: 'description', column: 'description', label: 'Descritivo', show: false },
  

    createdAt: {
      ref: 'created_at',
      column: 'createdAt',
      label: 'Data de Criação',
      mask: 'date',
    },
  };

  const formSchema = {
   
    url: { model: 'input', type: 'text', name: 'url', label: 'URL' },
    title: { model: 'input', type: 'text', name: 'title', label: 'Título' },
    description: { model: 'richText', type: 'text', name: 'description', label: 'Descritivo' },
  
    status: {
      model: 'selectSimple',
      name: 'status',
      label: 'Ativo?',
      defaultValue: 'Sim',
      options: [
        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },
      ]
    },
    files: {
      model: 'imageGalleryInput',
      type: 'input',
      name: 'files',
      label: 'Arquivos',
      list: [
        { label: 'Arquivo', name: 'url' },
        { label: 'Título', name: 'title' },
 
       
      ],
      
    },
    buttons: {
      model: 'jsonListMultiple',
      type: 'text',
      name: 'buttons',
      label: 'Botões',
      list: {
        title: { label: 'Título', name: 'title' },
        link: { label: 'Link', name: 'link' },
        class_name: { label: 'Tipo de botão', name: 'class_name' },
      },
    },
    images: {
      model: 'imageGalleryInput',
      type: 'input',
      name: 'images',
      label: 'Imagens',
      list: [
        { label: 'Imagem', name: 'url' },
        { label: 'Título', name: 'title' },

        { label: 'Link', name: 'link_url' },
       
      ],
    },
  };

  const formSchemaUpdate = {


    image: {
      model: 'uploadImage',
      type: 'upload',
      name: 'image',
      label: 'Foto',
    },
    ...formSchema
   

 
  };

  const formValidation: Record<string, ValidationSchema> = {
 
  };

  const config: ISearchComponent = {
    idKey : `${endpoint}-${projectHash}-${eventHash}`,
    title,
    endpoint,
    schema,
    autoStart: true,
    headModules: [
      () =>
      lineModules.create({
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
    bodyModules: [
      (lineData: Record<string, any>) =>
        lineModules.updateUnique({
          lineData,
          title,
          formSchema: formSchemaUpdate,
          validationSchema: {},
          endpoint,
        }),
      (lineData: Record<string, any>) =>
        lineModules.delete({
          lineData,
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
  };

  return (
    <Container>
      <SearchComponent {...config} />
    </Container>
  );
};

export default EventsPagesAdmin;
