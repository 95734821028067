export const hash = '210aa26d-8806-11ec-920b-16b38c2c4059';

// export const gateKey = '';
export const gateKey = '';
export const recaptchaKey = '6LdoQWIeAAAAAP457cno1Yt_wU6F2dbJxz-M_vCY';
export const apiUrl = `https://api-dasa.encontrodigital.com.br/${hash}`;
export const apiSocket = 'https://socket-2023.encontrodigital.com.br';



//export const apiSocket = 'http://localhost:8080';

//export const apiUrl = `http://localhost:8000/${hash}`;
export const urlWebsite = 'https://dasaeducaeventos.com.br';
export const supportNumber = `555195181533`;
export const home = 'Home';
export const haveTranslate = false;
// export const urlWebsite = 'http://localhost:3000';
export const eventName = 'DASA EDUCA';
export const logo = '/assets/logodasa.png';
export const background = '/assets/evento/Fundo.jpeg';
export const authTitle = 'DASA EDUCA';
export const dadosOrganizacao = '';
export const social = {
  whatsapp: '',
  instagram: 'dasa.educa/',
  facebook: 'dasaeduca/',
  linkedin: 'company/dasa-educa/',
  youtube: '@dasaeduca',
  spotify: 'https://open.spotify.com/show/6J83zgroU27MI3ECbuCmys?si=VFxr5YUiTK-J6Z6jTsiMdA',
  twitter: '',
};


export const defaultTheme = {
  defaultBackground: 'rgb(0,50,150)',
  defaultColor: '#fff',
  logo: {
    minWidth: '450px',
  },
  menu: {
    background: '#fff',
    backgroundSticky: '#fff',
    backgroundMobile: 'rgb(0,50,150)',
    color: 'rgb(0, 14, 64)',
    shadow: '0',
    logoColor: 'none',
  },
};

export const getToken = (): string | null => {
  const token = localStorage.getItem(`@${authTitle}:token`);
  return token;
};
