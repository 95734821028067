import React, { useEffect, useState } from 'react';
import { useConfiguration } from '../../hooks/Configuration';
import api from '../../services/api';
import { Container } from './styles';

const SelecaoDeCategorias: React.FC = () => {
    const { configuration } = useConfiguration();
    const [products, setProducts] = useState<Record<string, any>[]>();

    const loadProducts = async (projectHash) => {

        const productsList = await api.get(`/products-list/${projectHash}`);
        productsList?.data?.rows?.sort((a, b) => a?.position < b?.position ? -1 : a?.position > b?.position ? 1 : 0)
        setProducts(productsList?.data?.rows);

    }

    useEffect(() => {

        if (configuration?.url) {
            loadProducts(configuration?.url)
        }

    }, [configuration?.url])

    return (
        <Container>
            <h2 style={{ color: 'rgb(0,14,64)', fontSize: '18px', marginBottom: '15px', width: '100%', textAlign: 'center' }}>Selecionar categoria de inscrição</h2>

            {products?.map(i => {
                return <a href={`/app/${configuration?.url}/inscricao/${i?.hash_link}`}><button className='defaultButtonReverse'>{i?.title}</button></a>
            })}

        </Container>
    );
};

export default SelecaoDeCategorias;
