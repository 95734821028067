import React, { createContext, useCallback, useState, useContext, useEffect } from 'react';
import api from '../services/api';
import { authTitle } from '../config';
import { useSocket } from './Socket';
import { useAuth } from './Auth';


interface ConfigurationState {
  token: string;
  user: Record<string, any>;
}

interface IOption {
  type: string;
  id: string;
  subtype: string;
  value: string;
}

interface ISignInAdmin {
  email: string;
  password?: string;
}

interface IConfiguration {
  _id?: string;
  color?: string;
  title?: string;
  url?: string;
  program_title?: string;
  program_subtitle?: string;
  logo?: string;
  logo_main?: string;
  logo_adicional?: string;
  logo_adicional2?: string;
  upper_text?: string;
  header_image?: string;
  data_image?: string;
  lower_text?: string;
  next_date?: Date;
  header_video?: string;
  fixed_image_header?: string;
  background?: string;
  background_color?: string;
  certificates?: string;
  home_image_is_full?: string;
  theme_data?: Record<string, any>;
  current_event_id_response?: Record<string, any>;
  active_pages?: Array<Record<string, any>>;
  video_display?: string;
  video_type?: string;
  video_display_hash?: string;
  program_list?: string;
  event_id_response?: Record<string, any>;
  program_theme_data?: Record<string, any>;
  room_list_template?: string;
  exclusive?: string;
  have_language?: string;
  languages?: Record<string, any>;
  only_allowed_products?: string;
}

interface IGame {
  reference?: string;
  points?: number;
  id?: number;
}

interface ConfigurationContextData {

  configuration: IConfiguration;
  setConfiguration(data: IConfiguration): void;

}

const ConfigurationContext = createContext<ConfigurationContextData>({} as ConfigurationContextData);

const ConfigurationProvider: React.FC = ({ children }) => {
  const { socket } = useSocket();
  const { user } = useAuth();
  const [configuration, setConfiguration] = useState<Record<string, any>>({});





  return (
    <ConfigurationContext.Provider
      value={{
        configuration,
        setConfiguration

      }}
    >
      {children}
    </ConfigurationContext.Provider>
  );
};

function useConfiguration(): ConfigurationContextData {
  const context = useContext(ConfigurationContext);

  if (!context) {
    throw new Error('useConfiguration must be used within an ConfigurationProvider');
  }

  return context;
}

export { ConfigurationProvider, useConfiguration };
