import React, { useCallback, useRef, useEffect, useState } from 'react';
import {
  FiLogIn,
  FiMail,
  FiLock,
  FiKey,
  FiMessageCircle,
} from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';
import { rgb } from 'polished';
import {
  FaConnectdevelop,
  FaLock,
  FaHandPointUp,
  FaCertificate,
  FaTicketAlt,
  FaVideo,
  FaAward,
  FaSlideshare,
  FaKey,
} from 'react-icons/fa';
import { MdColorLens, MdMessage } from 'react-icons/md';
import { AiFillSafetyCertificate } from 'react-icons/ai';
import { IconType } from 'react-icons/lib';
import { dadosOrganizacao, logo } from '../../config';
import { ContactIcons, WhatsappIcon } from '../../components/Menu';
import Whatsapp from '../../components/Whatsapp';

import { Container, ContainerWhite } from './styles';

import ShowDiv from '../../components/ShowOnScroll';
import VideoBackground from '../../components/VideoBackground';
import TextPopper from '../../components/TextPopper';
import Scroller from '../../components/Scroller';

import Vimeo from '../../components/Vimeo';

interface SignInData {
  email: string;
  password: string;
}

interface IPublicData {
  icon: IconType;
  title: string;
  text: string;
}

const Termos: React.FC = () => {
  useEffect(() => {

    window.location.href = 'https://dasa.com.br/portal-de-privacidade/';
  }, [])
  return (
    <>
      <ContainerWhite id="termos">
        <div className="p50 p600" style={{ textAlign: 'justify' }}>
          <h2> Redirecionando...</h2>
        </div>
      </ContainerWhite>
    </>
  );
};
export default Termos;
