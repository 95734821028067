import React, { useCallback, useRef, useEffect, useState } from 'react';

import { Form } from '@unform/web';
import { Container } from './styles';
import { hour, dateLanguage } from '../../../../utils/date';
import api from '../../../../services/api';
import Vimeo from '../../../../components/Vimeo';

import PosterZoomComponent from '../../../../components/CientificLibraryRoom';
import { Button } from '../../../../components/Button/styles';
import { rgb, rgba } from 'polished';
import Favorite from '../../../../components/ClassRoomContainer/modules/Favorite';
import { FaBook, FaSearch, FaStar } from 'react-icons/fa';
import { useLanguage } from '../../../../hooks/Language';
import { string } from 'yup';
import themeDefault from '../../../../styles/ThemeComponents';
import { urlWebsite } from '../../../../config';
import { useAuth } from '../../../../hooks/Auth';
import { useParams, useHistory, Link } from 'react-router-dom';
import { endOfDay, getDay, startOfDay } from 'date-fns';
import SliderComponent from '../../../../components/Slider';

interface IPoster {
  _id: string;
  keywords: Array<string>;
  star?: number;
  title?: string;
  nested_authors?: string;
  author?: string;
  image?: string;
  video_type?: number;
  video_id: string;
  co_authors?: string;
  category?: string;
  have_video?: number;
  eixo?: string;
  pdf?: string;
  pdf_image?: Array<Record<string, any>>;
  time_to_read?: string;
}

interface IPosters {
  next: Array<IPoster>;
  nextLives: Array<IPoster>;
  prev: Array<IPoster>;
  highlight: Array<IPoster>;
}

interface IOption {
  page: number;
  order: Record<string, string>;
  where: Record<string, any>;
  eixo?: string;
}

interface IStars {
  stars: number;
}



export const track = ({ id = '', title = '', reference = '', track = '', client_id = '', client_name }) => {

  /* ID do trabalho
  titulo do trabalho
  reference é a ação
  track o que é
  
  */

  // api.post('/add-track', { id, title, reference, track , client_id : client_id, client_name, project_id: 'cientific-library' });


}

export const ShowStars: React.FC<IStars> = ({ stars = 5 }) => {

  return <div className='starCase'>

    <FaStar size={15} style={{ color: stars >= 1 ? '#f15a23' : '#fff' }} />
    <FaStar size={15} style={{ color: stars >= 2 ? '#f15a23' : '#fff' }} />
    <FaStar size={15} style={{ color: stars >= 3 ? '#f15a23' : '#fff' }} />
    <FaStar size={15} style={{ color: stars >= 4 ? '#f15a23' : '#fff' }} />
    <FaStar size={15} style={{ color: stars >= 5 ? '#f15a23' : '#fff' }} />
  </div>

}

interface IProps {
  allowed?: Array<string>;
  showLinks?: boolean;
}

interface IPropsDisplay {
  posters?: IPosters;
  type?: string;
  title?: string;
  showLinks?: boolean;
}


const DisplayEvents: React.FC<IPropsDisplay> = ({ showLinks = false, posters, type = 'next', title = 'Próximos eventos' }) => {



  const item = posters?.[type] ? posters?.[type] : [];



  const [items, setItems] = useState<Array<JSX.Element>>([]);

  useEffect(() => {

    const newItems: Array<JSX.Element> = [];

    item.map(poster => {
      newItems.push(<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative', maxWidth: 'auto' }}>
        <Link to={`/podcasts/acessar/${poster.url}`} key={`posterDiv${poster._id}`} style={{ position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}
          className={`posterDisplay  `}
        >
          <div className='posterImageDiv'>
            {poster.image ?
              <div> <img key={`img-${poster._id}`}
                className="posterImage"
                src={poster.image ? `${urlWebsite}/${poster.image}` : '/apoio/podcasts.png'}
              /></div> : <div className='eventTitle'><h2>

              </h2></div>}
          </div>

          <strong style={{ fontSize: '12px', maxWidth: '200px', padding: '10px' }}>{poster.title}</strong>
        </Link>

      </div>)
    });

    setItems(newItems);

  }, [item])
  if (item.length < 1) {
    return <></>
  }

  return <> <div className='posterHeader' >

    {title && showLinks ? <Link to="/podcasts"><h1>{title}</h1></Link> : <h1>{title}</h1>}

  </div>

    <div className="mainPoster">
      <SliderComponent list={items} arrows={true} />

    </div>
  </>



}

const PosterListDisplay: React.FC<IProps> = ({ showLinks = false, allowed = ['next'] }) => {
  const [posters, setPosters] = useState<IPosters>({ next: [], nextLives: [], prev: [], highlight: [] });
  const [selectedPoster, setSelectedPoster] = useState<string>('');

  const { posterid, eixo } = useParams<Record<string, any>>();

  const [search, setSearch] = useState('');

  const [options, setOptions] = useState<IOption>({
    page: 1,
    order: { orderBy: 'createdAt', direction: 'desc' },
    where: {},
    eixo: ''
  });
  const { translate } = useLanguage();

  useEffect(() => {

    if (posterid) {
      setSelectedPoster(posterid);

    }

  }, [posterid])






  const loadSearch = async ({ join = false, page = 0, text = '', listOptions }) => {

    const newOptions = listOptions?.page ? { ...listOptions } : { ...options };

    const string = `search=${encodeURIComponent(
      text || search || '',
    )}&order=${encodeURIComponent(JSON.stringify({ 'orderBy': 'episode', 'orderDirection': 'desc' }))}&page=${page > 0 ? page : newOptions.page
      }&where=${encodeURIComponent(JSON.stringify(newOptions.where))}&eixo=${encodeURIComponent(newOptions.eixo || '')}&limitItems=4`;



    const response = await api.get(`/list-podcast-library?${string}`);

    const date = new Date();

    if (response && response.data && response.data.rows) {
      const responseData = response.data.rows.reduce((prev, elem) => {




        let state = 'next';



        prev?.[state].push(elem);


        return prev;
      }, { next: [], nextLives: [], highlight: [], prev: [] });


      setPosters({ ...responseData });




      //  readEixos(response.data.rows);

    } else {
      setPosters({ next: [], nextLives: [], prev: [], highlight: [] });

    }

  };





  const newSearch = () => {
    const newOptions = { ...options };
    newOptions.page = 1;
    setOptions({ ...newOptions });
    loadSearch({ listOptions: {} });
    setSearch('');
  };

  const changeOrder = (value, direction) => {

    const newOptions = { ...options };

    newOptions.order = { orderBy: value, direction };

    setOptions(newOptions);
    loadSearch({ listOptions: newOptions });

  }

  const loadEixos = async () => {
    /* const response = await api.get(`/poster-list-eixo`);


    if(response){
      const eixo : Array<string> = [];
      response.data.map( (r ) => {
        if(r.eixo){
          eixo.push(r.eixo);
        }
      })

      setEixos(eixo);
    } */

  }


  const load = async () => {

    const newOptions = { ...options };
    if (eixo) {


      newOptions.eixo = eixo;

      setOptions(newOptions);
    }


    loadSearch({ listOptions: newOptions });
  };



  useEffect(() => {


    load();

  }, []);















  const list = {
    highlight: { title: 'Clique para acessar', type: 'highlight' },
    next: { title: 'Podcasts', type: 'next' },
    nextLives: { title: '', type: 'nextLives' },
    prev: { title: 'On Demand', type: 'prev' }

  }

  return (
    <Container>

      {allowed.map(allow => {
        return list?.[allow] ? <DisplayEvents showLinks={showLinks} posters={posters} type={list?.[allow].type} title={list?.[allow].title} /> : <></>


      })}




    </Container>
  );
};
export default PosterListDisplay;
