import React, { useCallback, useRef, useEffect, useState } from 'react';

import { Form } from '@unform/web';
import { Container } from './styles';
import { hour, dateLanguage } from '../../../../utils/date';
import api from '../../../../services/api';
import Vimeo from '../../../../components/Vimeo';

import PosterZoomComponent from '../../../../components/CientificLibraryRoom';
import { Button } from '../../../../components/Button/styles';
import { rgb, rgba } from 'polished';
import Favorite from '../../../../components/ClassRoomContainer/modules/Favorite';
import { FaBook, FaSearch, FaStar } from 'react-icons/fa';
import { useLanguage } from '../../../../hooks/Language';
import { string } from 'yup';
import themeDefault from '../../../../styles/ThemeComponents';
import { hash, urlWebsite } from '../../../../config';
import { useAuth } from '../../../../hooks/Auth';
import { useParams, useHistory, Link } from 'react-router-dom';
import { addDays, endOfDay, getDay, startOfDay } from 'date-fns';
import SliderComponent from '../../../../components/Slider';
import { loadFromJson } from '../../../../utils/loadFromJson';


interface IPoster {
  _id: string;
  keywords: Array<string>;
  star?: number;
  title?: string;
  nested_authors?: string;
  author?: string;
  image?: string;
  video_type?: number;
  video_id: string;
  co_authors?: string;
  category?: string;
  have_video?: number;
  eixo?: string;
  pdf?: string;
  pdf_image?: Array<Record<string, any>>;
  time_to_read?: string;
}

interface IPosters {
  next: Array<IPoster>;
  nextLives: Array<IPoster>;
  prev: Array<IPoster>;
  highlight: Array<IPoster>;
}

interface IOption {
  page: number;
  order: Record<string, string>;
  where: Record<string, any>;
  eixo?: string;
}

interface IStars {
  stars: number;
}



export const track = ({ id = '', title = '', reference = '', track = '', client_id = '', client_name }) => {

  /* ID do trabalho
  titulo do trabalho
  reference é a ação
  track o que é
  
  */

  // api.post('/add-track', { id, title, reference, track , client_id : client_id, client_name, project_id: 'cientific-library' });


}


interface IProps {
  allowed?: Array<string>;
  showLinks?: boolean;
}

interface IPropsDisplay {
  posters?: IPosters;
  type?: string;
  title?: string;
  showLinks?: boolean;
}


const DisplayEvents: React.FC<IPropsDisplay> = ({ showLinks = false, posters, type = 'next', title = 'Próximos eventos' }) => {

  const getColor = categoryName => {
    if (categoryName === 'Apresentação Oral') {
      return 'borderBlue';
    }
    if (categoryName === 'Pôster Comentado') {
      return 'borderPurple';
    }
    if (categoryName === 'Pôster Simples') {
      return 'borderGreen';
    }
  };

  const item = posters?.[type] ? posters?.[type] : [];



  const [items, setItems] = useState<Array<JSX.Element>>([]);

  useEffect(() => {

    const newItems: Array<JSX.Element> = [];

    item.map(poster => {
      newItems.push(<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative', maxWidth: 'auto' }}>
        <Link to={`/app/${poster.project_id}`} key={`posterDiv${poster._id}`} style={{ position: 'relative' }}
          className={`posterDisplay ${getColor(poster.category)}`}

        >
          <div className='posterImageDiv'>
            {poster.thumb ?
              <img key={`img-${poster._id}`}
                className="posterImage"
                src={poster.thumb ? `${urlWebsite}/${poster.thumb}` : '/apoio/biblioteca/capa.jpeg'}
              /> : <div className='eventTitle'><h2>
                {poster.title}
              </h2></div>}
          </div>


        </Link>

      </div>)
    });

    setItems(newItems);

  }, [item])
  if (item.length < 1) {
    return <></>
  }

  return <> <div className='posterHeader' >

    {title && showLinks ? <Link to="/eventos"><h1>{title}</h1></Link> : <h1>{title}</h1>}

  </div>

    <div className="mainPoster">
      <SliderComponent list={items} />

    </div>
  </>



}

const EventsListDisplay: React.FC<IProps> = ({ showLinks = false, allowed = ['next', 'nextLives', 'prev', 'highlight'] }) => {
  const [posters, setPosters] = useState<IPosters>({ next: [], nextLives: [], prev: [], highlight: [] });
  const [selectedPoster, setSelectedPoster] = useState<string>('');

  const { posterid, eixo } = useParams<Record<string, any>>();

  const [openPoster, setOpenPoster] = useState(false);
  const [loading, setLoading] = useState(true);
  const [counter, setCounter] = useState(1);
  const [search, setSearch] = useState('');
  const [haveSelector, setHaveSelector] = useState(false);
  const [category, setCategory] = useState('');
  const [eixos, setEixos] = useState<Array<string>>([]);
  const { user } = useAuth();
  const [options, setOptions] = useState<IOption>({
    page: 1,
    order: { orderBy: 'createdAt', direction: 'desc' },
    where: {},
    eixo: ''
  });
  const { translate } = useLanguage();

  useEffect(() => {

    if (posterid) {
      setSelectedPoster(posterid);
      setOpenPoster(true);
    }

  }, [posterid])


  const { push } = useHistory();


  const changeEixo = (value) => {

    const newOptions = { ...options };

    newOptions.eixo = value;

    setOptions(newOptions);
    if (value) {
      push(`/biblioteca-cientifica/categoria/${value}`);
    }
    else {
      push(`/biblioteca-cientifica`);
    }

    loadSearch({ listOptions: newOptions });

  }


  const readEixos = (list: Array<Record<string, any>>) => {


    if (eixos.length < 1 && list?.length > 0) {

      let newEixos: Array<string> = [];

      list.map((item) => {

        if (item?.keywords?.length > 0) {
          item.keywords.map((key: string) => {
            const index = newEixos.findIndex(listedKey => listedKey === key);

            if (index < 0) {
              newEixos.push(key);
            }
          })
        }


      })

      newEixos.sort((a, b) => a > b ? 1 : a < b ? -1 : 0);
      setEixos(newEixos);



    }


  }

  const loadSearch = async ({ join = false, page = 0, text = '', listOptions }) => {

    const newOptions = listOptions?.page ? { ...listOptions } : { ...options };

    const string = `search=${encodeURIComponent(
      text || search || '',
    )}&order=${encodeURIComponent(JSON.stringify(newOptions.order))}&page=${page > 0 ? page : newOptions.page
      }&where=${encodeURIComponent(JSON.stringify(newOptions.where))}&eixo=${encodeURIComponent(newOptions.eixo || '')}`;

    let data: Array<Record<string, any>> = [];

    const events = await loadFromJson({ hash: hash, project_id: 'global', table: 'Event' });

    if (events) {
      data = events;
      data.sort((a, b) => {
        const dateA = new Date(a.start_at);
        const dateB = new Date(b.start_at);
        return dateA > dateB ? 1 : dateA < dateB ? -1 : 0;
      })
    }
    else {

      const response = await api.get(`/events-list?${string}`);
      data = response?.data?.rows;
    }

    const date = addDays(new Date(), -1);

    if (data) {
      const responseData = { next: [], nextLives: [], highlight: [], prev: [] };


      data.map((elem) => {
        elem.formatted_date = dateLanguage(elem.date);
        elem.formatted_start_hour = hour(elem.start_at);
        elem.formatted_end_hour = hour(elem.end_hour);
        if (!elem.keywords) {
          elem.keywords = [];
        }

        const eventDate = new Date(elem.start_at);

        // let state = date > startOfDay(eventDate) && date < endOfDay(eventDate) ? 'highlight' :  date < eventDate ? 'next' : 'prev';
        let state = date < eventDate ? 'next' : 'prev';


        if (state === 'next' && elem.project_id === 'lives') {
          state = 'nextLives';
        }



        responseData?.[state].push(elem);

      });


      if (responseData?.prev?.length > 0) {
        responseData?.prev.reverse();
        responseData.prev = responseData?.prev.slice(0, 8)
      }


      if (join) {
        const newPosters = { ...posters };
        newPosters.next = [...newPosters.next, ...responseData.next];
        newPosters.nextLives = [...newPosters.nextLives, ...responseData.nextLives];
        newPosters.prev = [...newPosters.prev, ...responseData.prev];
        newPosters.highlight = [...newPosters.highlight, ...responseData.highlight];

        setPosters({ ...newPosters });
      } else {
        setPosters({ ...responseData });
      }

      setCounter(data.length / 20 < 1 ? 1 : data.length / 20);


      //  readEixos(response.data.rows);

    } else {
      setPosters({ next: [], nextLives: [], prev: [], highlight: [] });
      setCounter(1);
    }
    setLoading(false);
  };




  const newSearch = () => {
    const newOptions = { ...options };
    newOptions.page = 1;
    setOptions({ ...newOptions });
    loadSearch({ listOptions: {} });
    setSearch('');
  };

  const changeOrder = (value, direction) => {

    const newOptions = { ...options };

    newOptions.order = { orderBy: value, direction };

    setOptions(newOptions);
    loadSearch({ listOptions: newOptions });

  }

  const loadEixos = async () => {
    /* const response = await api.get(`/poster-list-eixo`);


    if(response){
      const eixo : Array<string> = [];
      response.data.map( (r ) => {
        if(r.eixo){
          eixo.push(r.eixo);
        }
      })

      setEixos(eixo);
    } */

  }


  const load = async () => {

    const newOptions = { ...options };
    if (eixo) {


      newOptions.eixo = eixo;

      setOptions(newOptions);
    }


    loadSearch({ listOptions: newOptions });
  };



  useEffect(() => {


    load();

    //   track({ id: '', title: 'Biblioteca', reference: 'Visita', track: 'Acesso', client_name: user?.name || '', client_id : user?.id || ''})
  }, []);



  const changeCategory = (column, categoryName) => {
    const newOptions = { ...options };
    if (categoryName === '') {
      newOptions.where = {};
      setOptions({ ...newOptions });

      loadSearch({ listOptions: newOptions });
      if (categoryName === 'have_video') {
        setCategory(categoryName);
      }
      return;

    }


    const newCategory =
      newOptions.where && newOptions.where[column] === categoryName
        ? ''
        : categoryName;
    newOptions.page = 1;
    if (newCategory === '' && newOptions.where[column]) {
      delete newOptions.where[column];
    } else {
      newOptions.where[column] = newCategory;
    }

    setOptions({ ...newOptions });

    loadSearch({ listOptions: newOptions });
    setCategory(newCategory);
  };





  const makeSearch = (value) => {

    setSearch(value);
    loadSearch({ listOptions: {}, text: value });

  }

  const list = {
    highlight: { title: 'Clique para acessar', type: 'highlight' },
    next: { title: 'Próximos eventos', type: 'next' },
    nextLives: { title: '', type: 'nextLives' },
    prev: { title: 'Eventos anteriores', type: 'prev' }

  }

  return (
    <Container>

      {allowed.map(allow => {
        return list?.[allow] ? <DisplayEvents showLinks={showLinks} posters={posters} type={list?.[allow].type} title={list?.[allow].title} /> : <></>


      })}




    </Container>
  );
};
export default EventsListDisplay;
