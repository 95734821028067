import React, { useCallback, useRef, useState } from 'react';
import { FiArrowLeft } from 'react-icons/fi';
import { Link, useHistory } from 'react-router-dom';
import { FormHandles } from '@unform/core';

import * as Yup from 'yup';

import { Container, Content, Background, AnimatedDiv } from './styles';

import getValidationErrors from '../../utils/getValidationErrors';
import api from '../../services/api';
import { useToast } from '../../hooks/Toast';

import FormContainer from './FormContainer';
import { useLanguage } from '../../hooks/Language';


interface SignUpFormData {
  name: string;
  email: string;
  password: string;
}

const LeadForm: React.FC = () => {
  const history = useHistory();
  const { addToast } = useToast();
  const [withPassword, setWithPassword] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const {translate} = useLanguage();
  const handleSubmit = useCallback(
    async (data: SignUpFormData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          email: Yup.string()
            .required('Email obrigatório')
            .email('Preencha um email válido'),
        });

        await schema.validate(data, { abortEarly: false });

        await api.post('/users', {
          name: data.name,
          email: data.email,
          password: data.password,
        });

        addToast({
          title: 'Cadastro realizado com sucesso',
          description: 'Você já pode fazer logon',
          type: 'success',
        });
        history.push('/');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: 'Ocorreu um erro ao realizar o cadastro',
        });
      }
    },
    [addToast, history],
  );

  return (
    <Container>
      <Content>
        <AnimatedDiv>
    
 
          <FormContainer />
        </AnimatedDiv>
      </Content>
    </Container>
  );
};

export default LeadForm;
