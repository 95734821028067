import { addMonths, lastDayOfMonth, lastDayOfWeek } from 'date-fns';
import { addDays } from 'date-fns/esm';
import React, { useEffect, useState } from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import api from '../../../../services/api';
import { day, monthNumber as monthT, year as yearT, monthFull as monthFunction } from '../../../../utils/date';
import ReactTooltip from 'react-tooltip';
import themeDefault from '../../../../styles/ThemeComponents';
import { loadFromJson } from '../../../../utils/loadFromJson';
import { hash } from '../../../../config';
interface IParam {
    setCallback?: Function,
    events?: Array<Record<string, any>>

}

const Calendar: React.FC<IParam> = ({
    setCallback = (value: Record<string, any>) => { },

}) => {
    const [dateList, setDateList] = useState<Array<Record<string, any>>>([]);
    const [year, setYear] = useState(new Date().getFullYear());
    const [month, setMonth] = useState(new Date().getMonth());
    const [monthName, setMonthName] = useState(monthFunction(new Date()));
    const days = 42;

    const [events, setEvents] = useState<Array<Record<string, any>>>([]);

    const load = async () => {
        let data: Array<Record<string, any>> = [];

        const events = await loadFromJson({ hash: hash, project_id: 'global', table: 'Event' });

        if (events) {
            data = events;
            data.sort((a, b) => {
                const dateA = new Date(a.start_at);
                const dateB = new Date(b.start_at);
                return dateA < dateB ? 1 : dateA > dateB ? -1 : 0;
            })
        }
        else {

            const response = await api.get(`/events-list?limitItems=1000`);
            data = response?.data?.rows;
        }


        const calendar: Array<Record<string, any>> = [];


        data.map((ev, index) => {

            const newData = { ...data[index] };

            newData.url = `/app/${newData.project_id}`;
            newData.month = parseInt(monthT(newData.start_at), 10);
            newData.day = parseInt(day(newData.start_at), 10);
            newData.year = parseInt(yearT(newData.start_at), 10);

            calendar.push(newData);

            if (newData?.event_date && newData?.event_date?.length > 0) {
                newData?.event_date.map(date => {

                    if (date !== newData.start_at) {
                        const extraDate = { ...newData };
                        extraDate.month = parseInt(monthT(date), 10);
                        extraDate.day = parseInt(day(date), 10);
                        extraDate.year = parseInt(yearT(date), 10);

                        calendar.push(extraDate);
                    }

                })
            }



        })

        setEvents(calendar);


    }

    useEffect(() => {

        load();


    }, [])


    const changeMonth = (action) => {


        if (action === 'next') {

            if (month === 12) { setMonth(1); setYear(year + 1) }
            else {
                setMonth(month + 1);
            }

        }
        else if (action === 'prev') {
            if (month === 1) { setMonth(12); setYear(year - 1) }
            else {
                setMonth(month - 1);
            }

        }


    }

    useEffect(() => {

        const monthDate = new Date(year, month, 1);

        const monthNameItem = monthFunction(monthDate);


        const currentMonthDate = monthDate.getMonth();
        const prevMonthDate = addMonths(monthDate, -1).getMonth();

        const nextMonthDate = addMonths(monthDate, +1).getMonth();

        const dayWeek = monthDate.getDay();

        const lastDay = addDays(monthDate, -1).getUTCDate();

        const lastDayMonth = lastDayOfMonth(monthDate).getUTCDate();

        const countDays = dayWeek === 0 ? 7 : dayWeek;

        const dates: Array<Record<string, any>> = [];

        let k = 0;

        let y = 0;
        for (let x = countDays; x > 0; x = x - 1) {
            dates.unshift({ month: prevMonthDate, day: lastDay - y });
            y = y + 1;
            k = k + 1;
        }

        const newCouter = days - dayWeek;

        let z = 1;
        let nextMonth = false;
        for (let x = 1; x <= newCouter; x = x + 1) {

            const eventsItens = events.filter(ev => ev.month === (month + 1) && ev.year === year && ev.day === z);


            if (!nextMonth || (nextMonth && k % 7 !== 0)) {
                dates.push({ month: nextMonth ? nextMonthDate : currentMonthDate, day: z, active: eventsItens?.length >= 1 ? true : false, data: eventsItens.length < 1 ? [{ title: 'Nenhum evento agendado' }] : eventsItens, url: eventsItens?.length !== 1 ? '/eventos/next' : `/app/${eventsItens?.[0].project_id}` });


                if (z === lastDayMonth) {
                    z = 1;
                    nextMonth = true;
                }
                else {
                    z = z + 1;
                }

                k = k + 1;
            }




        }

        setMonthName(monthNameItem);
        setDateList(dates);

        setCallback({ year, month });

    }, [year, month, events])

    const list = () => {

        const listDays = [
            'D', 'S', 'T', 'Q', 'Q', 'S', 'S'
        ];

        const items: Array<JSX.Element> = [];

        listDays.map(list => {
            items.push(<div style={{
                width: '40px', fontWeight: 'bold', margin: '2px', height: '40px', display: 'flex', color: '#777', alignItems: 'center', justifyContent: 'center',
                border: '0px solid #ccc', borderRadius: '5px', boxShadow: '2px 2px 5px rgba(0,0,0,0.2)',



            }}>{list}</div>);

        })

        dateList.map(date => {

            const content: Array<JSX.Element> = [];

            date?.data?.map((item, index) => {
                content.push(<p style={{ marginTop: index > 0 ? '10px' : '0px', maxWidth: '300px' }}><strong >{item.title}</strong><br /> {item.date_text}</p>)
            })

            items.push(<><a href={date.url} data-tip='' data-for={`calendar-${date.day}-${date.month}`} style={{
                width: '40px', background: date.active ? themeDefault.alternativeButton.background : 'none', margin: '2px', height: '40px', display: 'flex', color: date.active ? '#fff' : month === date?.month ? '#333' : '#aaa', alignItems: 'center', justifyContent: 'center',
                border: '0px solid #ccc', borderRadius: '5px', boxShadow: '2px 2px 5px rgba(0,0,0,0.2)',



            }}>{date.day}
                <ReactTooltip className='tooltip' id={`calendar-${date.day}-${date.month}`} getContent={() => { return <> {content}</> }} />

            </a>
            </>);

        })



        return items;
    }


    return <>
        <div style={{ width: '100%', maxWidth: '335px', marginBottom: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', background: '#fff', boxShadow: '0px 5px 10px #333 50%', padding: '10px', border: '2px solid #fff', borderRadius: '10px' }}>
            <FiChevronLeft style={{ cursor: 'pointer' }} onClick={() => changeMonth('prev')} color={'#aaa'} size={20} /> <h2 style={{ color: 'rgb(241, 90, 35)' }}>{`${monthName.toUpperCase()} - ${year}`}</h2> <FiChevronRight style={{ cursor: 'pointer' }} onClick={() => changeMonth('next')} color={'#aaa'} size={20} />
        </div>
        <div style={{ width: '335px', background: '#fff', boxShadow: '0px 5px 10px #333 50%', height: '335px', padding: '10px', display: 'flex', alignItems: 'stretch', justifyContent: 'center', flexWrap: 'wrap', border: '2px solid #fff', borderRadius: '10px' }}>


            {list()}

        </div>

    </>


}

export default Calendar;