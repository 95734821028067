import React, { useCallback, useEffect, useState } from 'react';
import { Wrapper } from './styles';
import { Menu } from '../../../components/Menu';
import { usePages } from '../../../hooks/Pages';
import FooterLayout from '../../Footer';
import { useAuth } from '../../../hooks/Auth';
import PopupComponent from '../../../components/PopupContainer';
import Helmet from 'react-helmet';
import Particles from 'react-particles-js';
import ImageSliderBackground from '../../../components/ImageBackgroundSlider';
import { useLocation, useParams } from 'react-router-dom';
import { useConfiguration } from '../../../hooks/Configuration';
import api from '../../../services/api';
import Loader from '../../DashboardPages/core/components/Loader';
import { hash, urlWebsite } from '../../../config';
import { useSocket } from '../../../hooks/Socket';
import { useVideo } from '../../../hooks/Video';
import FormContainerUpdate from '../../SignUpUpdate/FormContainer';
import { useModal } from '../../../hooks/Modal';
import { addDays } from 'date-fns/esm';
import { useToast } from '../../../hooks/Toast';
import { loadFromJson } from '../../../utils/loadFromJson';
import SignIn from '../../SignIn';
import { FaCheckCircle } from 'react-icons/fa';
import { FiCheckCircle, FiX, FiXCircle } from 'react-icons/fi';

interface IParams {
  projectHash: string;
  eventHash: string;
}


const DefaultLayout: React.FC = ({ children }) => {
  const page = window.location.pathname !== '/';
  const { menu, setMenu, prepareMenu } = usePages();

  const { token, user, handleApiErrors, signOut, signIn, setTrackingIntegration } = useAuth();
  const { configuration, setConfiguration } = useConfiguration();
  const { search } = useLocation();
  const [loading, setLoading] = useState(false);
  const [ready, setReady] = useState(false);

  const { addModal } = useModal();

  const { addToast } = useToast();


  const { projectHash, eventHash } = useParams<IParams>();
  const params = new URLSearchParams(search);

  const load = async () => {
    // setLoading(true);

    try {


      let data: Record<string, any> = {};

      const project = await loadFromJson({ hash: hash, project_id: projectHash, table: 'Project' });

      if (project?.[0]) {
        data = project[0];

        const event = await loadFromJson({ hash: hash, project_id: data.url, table: 'Event' });
        if (event?.[0]) {


          data.current_event_id_response = { ...event[0] };
        }
        else {

          const response = await api.get(eventHash ? `/get-project-content/${projectHash}/${eventHash}` : `/get-project-content/${projectHash}`);
          data = response?.data;
        }
      }
      else {

        const response = await api.get(eventHash ? `/get-project-content/${projectHash}/${eventHash}` : `/get-project-content/${projectHash}`);

        data = response?.data;
      }



      setConfiguration(data);



      if (data?.active_pages?.length > 0) {

        prepareMenu({ eventHash, projectHash, active_pages: data?.active_pages });
      }



      setLoading(false);
      setReady(true);

    }
    catch (err) {

      handleApiErrors(err);
      setLoading(false);
    }
  }


  useEffect(() => {

    if (user && !user.last_login) {
      signOut();
      window.location.reload();
    }

    if (user && user?.last_login) {

      const checkDate = addDays(new Date(), -5);
      const userDate = new Date(user.last_login);
      if (checkDate > userDate) {
        addToast({ title: 'Seu acesso expirou, faça seu login novamente', type: 'info' });

        setTimeout(() => {
          signOut();
          window.location.reload();
        }, 2000);
        return;
      }


    }

    if (user && user?.profile === 2 && user?.updated_information !== 'yes' && user?.updated_information !== 'no') {
      signOut();
      window.location.reload();
      return;
    }



    if (user && user?.updated_information !== 'yes' && user?.profile === 2) {
      addModal({
        title: '',
        content: <FormContainerUpdate />,
        theme: 'whiteModal2',
        key: 'update-signup-modal',
        closeButton: 'no'
      })
    }

  }, [user]);

  const loadIntegration = async () => {
    const email = params.get("email");
    const tracking = params.get("tracking");

    if (tracking === 'navpro' && email && !user) {
      setTrackingIntegration(tracking);
      try {
        const userInfo = await signIn({ email: email, name: "" });

        addModal({ theme: 'whiteModal2', content: <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}><FiCheckCircle style={{ width: '150px', height: '150px', color: 'green', margin: '25px auto', marginBottom: '50px' }} /><h2 style={{ color: '#333', marginBottom: '50px' }}>Olá {userInfo?.name}. <br />Você está conectado!</h2></div>, title: '' })
        await api.post('/add-platform-integration-track', {
          url: tracking,
          client_id: userInfo?._id?.toString(),
          client_name: userInfo?.name,
          client_email: userInfo?.email,
          reference: 'integration',
          track: 'Access'
        })
      }
      catch (err) {
        addModal({ theme: 'whiteModal2', content: <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}><FiXCircle style={{ width: '150px', height: '150px', color: 'rgb(150,0,0)', margin: '25px auto', marginBottom: '50px' }} /><h2 style={{ color: '#333', marginBottom: '50px' }}>Usuário não identificado</h2></div>, title: '' })

        await api.post('/add-platform-integration-track', {
          url: tracking,
          client_email: email,
          reference: 'integration',
          track: 'Access'
        })

      }
    }
  }

  useEffect(() => {

    loadIntegration();


  }, [window?.location?.pathname])

  useEffect(() => {
    if (projectHash) {
      load();



      window.scrollTo(0, 0);
    }
    else {
      setLoading(false);
      setReady(true);
      setConfiguration({});
      setMenu([
        {
          title: 'Home',
          target: '/',
          targetID: '/',
          fluid: 1,
          external: 1,
          status: 2,
        }
      ]);
    }
  }, [projectHash, eventHash])

  const indexOf = window?.location?.pathname.indexOf('dashboard');

  return (
    <>
      {loading && <Loader message='Carregando' />}
      <Wrapper background_color={indexOf >= 0 ? '' : configuration.background_color} background={`${urlWebsite}/${configuration.background}`}>

        <Menu menuList={menu} fixedMenu={false} />
        {ready && <>
          {children}

          <PopupComponent />


        </>}
      </Wrapper>
      <FooterLayout />
      {configuration?.fixed_image_header && <img src={`${urlWebsite}/${configuration.fixed_image_header}`} className="fixedBottom" />}

      <Helmet>
        {configuration?.title && projectHash && <title>{`${configuration.title} DASA EDUCA`}</title>}
        {!projectHash && <title>{`DASA EDUCA`}</title>}
      </Helmet>

    </>
  );
};

export default DefaultLayout;
